import {
    Action, Module, Mutation, VuexModule,
} from 'vuex-module-decorators';
import fromEnum from '../../../scripts/io-ts/fromEnum';
import { userModule } from '../store-accessor';

export enum Section {
    ROOMS = 'rooms',
    RECORDINGS = 'recent-recordings',
    SCHEDULES = 'upcoming-schedules',
    REPORTS = 'reports',
    ANNOUNCEMENTS = 'announcements',
}

export const SectionC = fromEnum('section', Section);

export const sectionNames: Record<Section, string> = {
    [Section.ROOMS]: 'Rooms',
    [Section.RECORDINGS]: 'Recordings',
    [Section.SCHEDULES]: 'Schedules',
    [Section.REPORTS]: 'Reports',
    [Section.ANNOUNCEMENTS]: 'Announcements',
};

const sectionPermissions: Record<Section, string|null> = {
    [Section.ROOMS]: 'monitoring',
    [Section.RECORDINGS]: 'review',
    [Section.SCHEDULES]: 'schedule',
    [Section.REPORTS]: 'reports',
    [Section.ANNOUNCEMENTS]: null,
};

@Module({ name: 'HomeSectionsModule' })
export default class HomeSectionsModule extends VuexModule {
    private _sections: Section[] = [];

    public get hasPermissionTo(): (section: Section) => boolean {
        return (section: Section) => {
            const permission = sectionPermissions[section];
            if (permission === null) {
                return true;
            }

            return userModule.hasPermission(permission);
        };
    }

    @Mutation
    public setSections(sections: Section[]): void {
        this._sections = [...new Set(sections)]; // unique
    }

    @Mutation
    private addSection(section: Section): void {
        if (this._sections.includes(section)) {
            return;
        }

        this._sections.push(section);
    }

    @Mutation
    private removeSection(section: Section): void {
        const indexOf = this._sections.indexOf(section);
        if (indexOf === -1) {
            return;
        }

        this._sections.splice(indexOf, 1);
    }

    @Action
    public async toggleSection(section: Section, value: boolean|null = null): Promise<void> {
        if (!this.hasPermissionTo(section)) {
            this.context.commit('removeSection', section);
            return;
        }

        const nextState = value !== null ? value : !this.hasSection(section);
        const mutationName = nextState ? 'addSection' : 'removeSection';
        this.context.commit(mutationName, section);
    }

    @Action
    public async enableSection(section: Section): Promise<void> {
        this.context.commit('addSection', section);
    }

    @Action
    public async disableSection(section: Section): Promise<void> {
        this.context.commit('removeSection', section);
    }

    public get sections(): Readonly<Section[]> {
        return this._sections;
    }

    public get hasSection(): (section: Section) => boolean {
        return (section: Section) => this._sections.includes(section);
    }
}
