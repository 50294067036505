import * as t from 'io-ts';
import { Any, UndefinedC, UnionC } from 'io-ts';

/**
 * @see https://github.com/gcanti/io-ts/pull/266#issuecomment-474935329
 * This function may change in the future because new ts differentiate between undefined and missing
 * @see https://github.com/microsoft/TypeScript/issues/13195#issuecomment-832132734
 * */

export default <T extends Any>(type: T)
    : UnionC<[T, UndefinedC]> => t.union([type, t.undefined]);
