<template>
    <div
        class="notification-card"
        @click.stop="readNotification"
    >
        <div class="notification-icon">
            <slot name="notification-icon" />
            <div
                v-if="!viewed"
                class="notification-icon__dot"
                data-cy="user-info-notification-dot"
            />
        </div>
        <div class="notification-body">
            <div class="notification-header">
                <slot name="notification-header" />
            </div>
            <div class="notification-text">
                <slot name="notification-text" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { RouteLocationRaw } from 'vue-router';
import ReplyIcon from '../../../images/reply.svg?component';
import Button from '../Elements/Button.vue';
import WarningDot from '../Elements/WarningDot/WarningDot.vue';
import { NotificationItem, NotificationItemC } from '../../../scripts/types/Notification';
import { readNotification } from '../../../scripts/api/notification/NotificationApi';

@Options({
    components: {
        WarningDot,
        Button,
        ReplyIcon,
    },
})
export default class NotificationsCard extends Vue {
    @Prop({ type: String })
    public readonly id!: string;

    @Prop({ type: String, default: null })
    public readonly cypressId!: string | null;

    @Prop({ type: Object, validator: (value) => NotificationItemC.is(value) })
    private readonly notification!: NotificationItem;

    @Prop({
        type: Object,
        required: true,
    })
    public readonly location!: RouteLocationRaw;

    @Prop(Boolean)
    public readonly viewed!: boolean;

    public async readNotification(): Promise<void> {
        await readNotification(this.notification.id);
        await this.$emit('reloadNotifications');

        await this.$router.push(this.location);
    }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/vendors/breakpoints';
@import '../../../styles/abstracts/mixins';
@import '../../../styles/abstracts/font-sizes';
@import '../../../styles/abstracts/spacings';
@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/z-indexes';
@import '../../../styles/abstracts/colors_old';

.notification-card {
    display: flex;

    gap: $spacing-xs;
}

.notification-body {
    overflow: hidden;
}

.notification-text {
    display: block;

    width: 100%;
}

.notification-header {
    font-size: $font-size-sm;

    color: var(--theme-color-text-secondary);
}

.notification-icon {
    position: relative;

    display: flex;

    align-items: center;
    justify-content: center;

    min-width: 2rem;
    height: 2rem;

    background-color: var(--theme-color-surface-secondary-default);

    border-radius: $border-radius;

    svg {
        width: 1.5rem;
        height: 1.5rem;

        fill: var(--theme-color-icon-primary);
    }

    &__dot {
        @include notification-dot($spacing-xs, var(--theme-color-surface-primary-default));
    }
}

:deep .message-item {
    display: flex;

    svg {
        width: 1.25rem;
        height: 1.25rem;

        fill: var(--theme-color-icon-primary);
    }
}
</style>
