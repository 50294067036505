import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';
import { RawFieldCommonPropsC } from '../types/RawField';

export const RawDateFieldC = t.intersection([
    RawFieldCommonPropsC,
    t.type({
        type: t.literal('string'),
        renderer: t.literal(FIELD_RENDER_TYPES.CALENDAR),
    }),
    t.partial({
        default: t.string,
        errors: t.array(t.string),
    }),
]);

export const DateFieldPartialC = t.type({
    renderAs: t.literal(FIELD_RENDER_TYPES.CALENDAR),
    value: t.union([t.null, t.string]),
    errors: t.array(t.string),
});

export const DateFieldC = t.intersection([DateFieldPartialC, FieldCommonPropsC]);

export type RawDateField = TypeOf<typeof RawDateFieldC>
export type DateFieldPartial = TypeOf<typeof DateFieldPartialC>
export type DateField = TypeOf<typeof DateFieldC>

export default (field: RawDateField): DateFieldPartial => ({
    value: field.default ?? null,
    renderAs: FIELD_RENDER_TYPES.CALENDAR,
    errors: field.errors ?? [],
});
