import Converter from '../Converter';
import toUserFilterFormat, {
    UserFilterFieldPartial,
    RawUserFilterField,
    RawUserFilterFieldC,
} from '../functions/toUserFilterFormat';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';

export default class UserFilterConverter extends Converter<RawUserFilterField> {
    public canConvert(field: RawField): field is RawUserFilterField {
        return isType(field, RawUserFilterFieldC);
    }

    protected convertPartially(field: RawUserFilterField): UserFilterFieldPartial {
        return toUserFilterFormat(field);
    }
}
