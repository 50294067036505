import Converter from '../Converter';
import toRadioCheckboxTree, {
    RawRadioCheckboxTreeField,
    RawRadioCheckboxTreeFieldC,
    RadioCheckboxTreeFieldPartial,
} from '../functions/toRadioCheckboxTree';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';

export default class RadioCheckboxTreeFieldConverter extends Converter<RawRadioCheckboxTreeField> {
    public canConvert(field: RawField): field is RawRadioCheckboxTreeField {
        return isType(field, RawRadioCheckboxTreeFieldC);
    }

    protected convertPartially(field: RawRadioCheckboxTreeField): RadioCheckboxTreeFieldPartial {
        return toRadioCheckboxTree(field);
    }
}
