import axios from 'axios';
import { RoutingSymfony } from '../player/mobile/helpers/routing';

const isUserSessionExpired = async (): Promise<boolean> => {
    try {
        const { data: { shouldLogout } } = await axios.get(RoutingSymfony.generate('check_user_session'));
        return shouldLogout;
    } catch (e: unknown) {
        if (axios.isAxiosError(e) && e.response) {
            return e.response.status !== 403;
        }

        throw e;
    }
};

export default isUserSessionExpired;
