import Converter from '../Converter';
import toArrayFormat, {
    RawArrayField,
    RawArrayFieldC,
    ArrayFieldPartial,
    ArrayWithPrototypeFieldPartial,
} from '../functions/toArrayFormat';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';

export default class ArrayFieldConverter extends Converter<RawArrayField> {
    public canConvert(field: RawField): field is RawArrayField {
        return isType(field, RawArrayFieldC);
    }

    protected convertPartially(field: RawArrayField):
        ArrayFieldPartial | ArrayWithPrototypeFieldPartial {
        return toArrayFormat(field);
    }
}
