import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import toMultiSelectFormat, {
    MultiSelectFieldPartialWithoutRenderAsC,
    RawMultiSelectFieldC,
} from './toMultiSelectFormat';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';

export const RawRadioCheckboxTreeFieldC = t.intersection([
    RawMultiSelectFieldC,
    t.type({
        radio_choices: t.array(t.string),
    }),
]);

export const RadioCheckboxTreeFieldPartialC = t.intersection([
    MultiSelectFieldPartialWithoutRenderAsC,
    t.type({
        renderAs: t.literal(FIELD_RENDER_TYPES.RADIO_CHECKBOX_TREE),
        radioChoices: t.array(t.string),
    }),
]);

export const RadioCheckboxTreeFieldC = t.intersection(
    [RadioCheckboxTreeFieldPartialC, FieldCommonPropsC],
);

export type RawRadioCheckboxTreeField = TypeOf<typeof RawRadioCheckboxTreeFieldC>
export type RadioCheckboxTreeFieldPartial = TypeOf<typeof RadioCheckboxTreeFieldPartialC>
export type RadioCheckboxTreeField = TypeOf<typeof RadioCheckboxTreeFieldC>

export default (field: RawRadioCheckboxTreeField): RadioCheckboxTreeFieldPartial => ({
    ...toMultiSelectFormat(field),
    renderAs: FIELD_RENDER_TYPES.RADIO_CHECKBOX_TREE,
    radioChoices: field.radio_choices,
});
