import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { CaptionTrack } from '../../../scripts/player/Interfaces/CaptionTrack';

@Module({ name: 'CaptionsModule' })
export default class CaptionsModule extends VuexModule {
    private _captionTrack: CaptionTrack | null = null;

    @Mutation
    setCaptionTrack(track: CaptionTrack | null): void {
        this._captionTrack = track;
    }

    get captionTrack(): CaptionTrack | null {
        return this._captionTrack;
    }
}
