import {
    Module, Mutation, VuexModule,
} from 'vuex-module-decorators';

@Module({ name: 'AlertModule' })
export default class AlertModule extends VuexModule {
    private _unreadAlertsCount = 0;

    @Mutation
    public setUnreadAlertsCount(count: number): void {
        this._unreadAlertsCount = count;
    }

    public get unreadAlertsCount(): Readonly<number> {
        return this._unreadAlertsCount;
    }
}
