import * as t from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import { RawFieldC } from '../types/RawField';

export const RawColorPickerFieldC = t.intersection([
    RawFieldC,
    t.type({
        renderer: t.literal(FIELD_RENDER_TYPES.COLOR_PICKER),
    }),
]);

const ColorPickerFieldPartialC = t.type({
    value: t.string,
    renderAs: t.literal(FIELD_RENDER_TYPES.COLOR_PICKER),
});

export type ColorPickerFieldPartial = t.TypeOf<typeof ColorPickerFieldPartialC>;
export type RawColorPickerField = t.TypeOf<typeof RawColorPickerFieldC>;

export default function toColorPicker(field: RawColorPickerField): ColorPickerFieldPartial {
    const value = field.default as string;

    return {
        value,
        renderAs: FIELD_RENDER_TYPES.COLOR_PICKER,
    };
}
