import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import {
    FormFieldPartialWithoutRenderAsC,
    RawFormFieldC,
} from './FormField';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';

export const RawAvatarFieldC = t.intersection([
    RawFormFieldC,
    t.type({
        renderer: t.literal(FIELD_RENDER_TYPES.AVATAR),
    }),
]);

export const AvatarFieldPartialC = t.intersection([
    FormFieldPartialWithoutRenderAsC,
    t.type({
        renderAs: t.literal(FIELD_RENDER_TYPES.AVATAR),
    }),
]);

export const AvatarFieldC = t.intersection([AvatarFieldPartialC, FieldCommonPropsC]);

export type RawAvatarField = TypeOf<typeof RawAvatarFieldC>;

export type AvatarFieldPartial = t.TypeOf<typeof AvatarFieldPartialC>;
