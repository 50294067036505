import Converter from '../Converter';
import toStaticImage, { RawStaticImageField, RawStaticImageFieldC, StaticImageFieldPartial } from '../functions/toStaticImage';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';

export default class StaticImageFieldConverter extends Converter<RawStaticImageField> {
    public canConvert(field: RawField): field is RawStaticImageField {
        return isType(field, RawStaticImageFieldC);
    }

    protected convertPartially(field: RawStaticImageField): StaticImageFieldPartial {
        return toStaticImage(field);
    }
}
