import convertForm from './convertForm';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import { assertIsNonNullable } from '../../../../typeAssertions/undefined';
import { RawDateRange, DateRangePartial } from '../types/DateRange';

export default (field: RawDateRange): DateRangePartial => {
    const convertedForm = convertForm(field);
    assertIsNonNullable(convertedForm);

    return {
        ...convertedForm,
        renderAs: FIELD_RENDER_TYPES.DATE_RANGE,
    };
};
