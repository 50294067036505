export function isDefined<T>(val: T): val is Exclude<T, undefined> {
    return typeof val !== 'undefined';
}

export function isNonNullable<T>(val: T): val is NonNullable<T> {
    return (typeof val !== 'undefined') && val !== null;
}

export function assertIsDefined<T>(val: T, name = 'Value')
    : asserts val is Exclude<T, undefined> {
    if (!isDefined(val)) {
        throw new TypeError(`${name} must be defined`);
    }
}

export function assertIsNonNullable<T>(val: T, name = 'Value')
    : asserts val is NonNullable<T> {
    if (!isNonNullable(val)) {
        throw new TypeError(`${name} must be defined and not null`);
    }
}
