import { RouterListener } from './RouterListener';
import AnonymousRouteListener from './AnonymousRouteListener';
import SidebarClosingListener from './SidebarClosingListener';
import PasswordExpiredListener from './PasswordExpiredListener';
import HomeRouteListener from './HomeRouteListener';

const listeners: RouterListener[] = [
    new AnonymousRouteListener(),
    new SidebarClosingListener(),
    new PasswordExpiredListener(),
    new HomeRouteListener(),
];

export default listeners;
