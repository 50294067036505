enum ButtonSize {
    XXXS = 'xxxs',
    XXS = 'xxs',
    EXTRA_SMALL = 'xs',
    SMALL = 'sm',
    MEDIUM = 'md',
    LARGE = 'lg',
    FULL = 'full'
}

const sizes: string[] = Object.values(ButtonSize);
const validator = (value: string) => sizes.includes(value);

export { ButtonSize, validator };
