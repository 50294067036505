import Converter from '../Converter';
import toColorPicker, { ColorPickerFieldPartial, RawColorPickerField, RawColorPickerFieldC } from '../functions/toColorPicker';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';

export default class ColorPickerConverter extends Converter<RawColorPickerField> {
    public canConvert(field: RawField): field is RawColorPickerField {
        return isType(field, RawColorPickerFieldC);
    }

    protected convertPartially(field: RawColorPickerField): ColorPickerFieldPartial {
        return toColorPicker(field);
    }
}
