import ContainerIcon from '../../../images/container.svg?component';
import ROUTE_NAMES from '../routeNames';
import SECTION_NAMES from '../sectionNames';
import { permissionsModule as pM } from '../../store';

const meta = {
    title: SECTION_NAMES.CONTAINERS,
    icon: ContainerIcon,
    permissions: (): string[] => [pM.allPermissions.adminContainers],
};

const AdminContainerView = () => import('../../views/admin/AdminContainer/AdminContainerView.vue');

export default [
    {
        path: '',
        name: ROUTE_NAMES.ADMIN_CONTAINER_LIST,
        component: () => import('../../views/admin/AdminContainer/AdminContainerList.vue'),
        meta,
    },
    {
        path: 'new',
        name: ROUTE_NAMES.ADMIN_CONTAINER_NEW,
        component: AdminContainerView,
        meta,
    },
    {
        path: 'edit/:id',
        name: ROUTE_NAMES.ADMIN_CONTAINER_VIEW,
        component: AdminContainerView,
        meta,
    },
];
