import Converter from '../Converter';
import toMultiFilesFormat, {
    MultiFilesFieldPartial,
    RawMultiFilesField,
    RawMultiFilesFieldC,
} from '../functions/toMultiFilesFormat';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';

export default class MultiFilesFieldConverter extends Converter<RawMultiFilesField> {
    public canConvert(field: RawField): field is RawMultiFilesField {
        return isType(field, RawMultiFilesFieldC);
    }

    protected convertPartially(field: RawMultiFilesField): MultiFilesFieldPartial {
        return toMultiFilesFormat(field);
    }
}
