import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import { DateFromUnixTime } from 'io-ts-types/DateFromUnixTime';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import { emptyArray } from '../../../../io-ts/emptyArray';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';
import { RawFieldC } from './RawField';

export const RawFormFieldC = t.intersection([
    t.type({
        title: t.union([t.string, t.null]),
        name: t.string,
        properties: t.union([t.record(t.string, RawFieldC), emptyArray]),
        type: t.union([t.literal('object'), t.literal('array')]),
    }),
    t.partial({
        required: t.array(t.string),
        label_attr: t.record(t.string, t.unknown),
        attr: t.intersection([
            t.record(t.string, t.unknown),
            t.partial({ retentionRuleOutDate: t.union([t.null, DateFromUnixTime]) }),
        ]),
    }),
]);

export const FormFieldPartialWithoutRenderAsC = t.type({
    title: t.union([t.string, t.null]),
    name: t.string,
    fields: t.record(t.string, FieldCommonPropsC),
    labelAttr: t.union([t.record(t.string, t.unknown), t.null]),
});

export const FormFieldPartialC = t.intersection([
    FormFieldPartialWithoutRenderAsC,
    t.type({
        renderAs: t.literal(FIELD_RENDER_TYPES.FORM),
    })]);

export const FormFieldC = t.intersection([FormFieldPartialC, FieldCommonPropsC]);
export const FormFieldWithoutRenderAsC = t.intersection(
    [FormFieldPartialWithoutRenderAsC, FieldCommonPropsC],
);

export type RawFormField = TypeOf<typeof RawFormFieldC>;
export type FormFieldPartial = TypeOf<typeof FormFieldPartialC>;
export type FormField = TypeOf<typeof FormFieldC>;
