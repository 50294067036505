import HomeIcon from '../../../../images/home.svg?component';
import ObserveIcon from '../../../../images/camera.svg?component';
import ReportsIcon from '../../../../images/report.svg?component';
import ReviewIcon from '../../../../images/video.svg?component';
import ScheduleIcon from '../../../../images/schedule.svg?component';
import SettingsIcon from '../../../../images/gear.svg?component';

export const MENU_ICONS = {
    HomeIcon,
    ObserveIcon,
    ReportsIcon,
    ReviewIcon,
    ScheduleIcon,
    SettingsIcon,
};
