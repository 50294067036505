import Converter from '../Converter';
import toFileUpload, { FileFieldPartial, RawFileField, RawFileFieldC } from '../functions/toFileUpload';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';

export default class FileUploadConverter extends Converter<RawFileField> {
    public canConvert(field: RawField): field is RawFileField {
        return isType(field, RawFileFieldC);
    }

    protected convertPartially(field: RawFileField): FileFieldPartial {
        return toFileUpload(field);
    }
}
