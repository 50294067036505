import Converter from '../Converter';
import convertForm from '../functions/convertForm';
import { RawFormField, RawFormFieldC, FormFieldPartial } from '../types/FormField';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';

export default class FormFieldConverter extends Converter<RawFormField> {
    public canConvert(field: RawField): field is RawFormField {
        return isType(field, RawFormFieldC);
    }

    protected convertPartially(field: RawFormField): FormFieldPartial | null {
        return convertForm(field);
    }
}
