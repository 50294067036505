// see templates/base.html.twig

const { dataset } = document.body;

type TwigBoolean = '' | '1';
type NumericString = string;

type BaseSettings = {
    /* json string */
    notifications: string,
    checkSession: TwigBoolean,
    logoutAfterMs: NumericString,
    nowUnix: NumericString,
    utcOffset: string,
    momentDateFormat: string,
    datePickerFormat: string,
    informationEditAllowed: TwigBoolean,
    nodeHost: string,
    markerOverlayRight: TwigBoolean,
    showNotificationsRight: TwigBoolean,
    addTranscriptRight: TwigBoolean,

    // TODO move optional settings to separate files or set all settings in one place

    // see src/BasicBundle/Resources/views/Default/index.html.twig
    // see src/CamBundle/Resources/views/Record/index.html.twig
    hasRightReviewDownloadRecordings?: TwigBoolean

    // see src/CamBundle/Resources/views/Record/show.html.twig
    reviewGenerateLink?: TwigBoolean,
    logId?: NumericString | '',
    isSharing?: TwigBoolean,
}

export default dataset as Readonly<BaseSettings>;
