import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import optional from '../../../../io-ts/optional';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';
import instanceOf from '../../../../io-ts/instanceOf';
import { emptyArray } from '../../../../io-ts/emptyArray';
import { RawFieldCommonPropsC } from '../types/RawField';

export const FileItemC = t.type({
    id: t.number,
    name: t.string,
    size: t.number,
});

export type FileItem = TypeOf<typeof FileItemC>

export type FileWithId = File & { id: number }
export type FileOrFileWithId = File | FileWithId

export const RawMultiFilesFieldC = t.intersection([
    RawFieldCommonPropsC,
    t.type({
        default: t.union([
            t.record(
                t.string,
                t.type({
                    name: t.string,
                    size: t.number,
                }),
            ),
            emptyArray,
        ]),
        accept: optional(t.string),
        attr: optional(
            t.partial({
                accept: t.string,
            }),
        ),
        maxFileSize: t.number,
        type: t.literal('multi_files'),
    }),
]);

export const MultiFilesFieldPartialC = t.type({
    renderAs: t.literal(FIELD_RENDER_TYPES.MULTI_FILES),
    accept: optional(t.string),
    uploadFiles: t.array(instanceOf(File)),
    removedFiles: t.array(t.number),
    files: t.array(FileItemC),
    maxFileSize: t.number,
});

export const MultiFilesFieldC = t.intersection([MultiFilesFieldPartialC, FieldCommonPropsC]);

export type RawMultiFilesField = TypeOf<typeof RawMultiFilesFieldC>
export type MultiFilesFieldPartial = TypeOf<typeof MultiFilesFieldPartialC>
export type MultiFilesField = TypeOf<typeof MultiFilesFieldC>

export default (field: RawMultiFilesField): MultiFilesFieldPartial => {
    const files = field.default
        ? Object.entries(field.default)
            .map(([id, { name, size }]): FileItem => ({
                id: +id,
                name,
                size,
            }))
        : [];

    return {
        accept: field.accept || field.attr?.accept,
        uploadFiles: [],
        removedFiles: [],
        renderAs: FIELD_RENDER_TYPES.MULTI_FILES,
        files,
        maxFileSize: field.maxFileSize,
    };
};
