import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';

/** @deprecated update to use JSON schema or don't use SchemaConverter  */
export const RawTranscriptsCardsFieldC = t.type({
    name: t.string,
    title: t.union([t.string, t.null]),
    cards: t.UnknownArray,
    recordId: t.string,
    type: t.literal('transcripts_cards'),
});

/** @deprecated update to use JSON schema or don't use SchemaConverter  */
export const TranscriptsCardsFieldPartialC = t.type({
    renderAs: t.literal(FIELD_RENDER_TYPES.TRANSCRIPTS_CARDS),
    // TODO no unknown
    cards: t.array(t.unknown),
    recordId: t.string,
});

/** @deprecated update to use JSON schema or don't use SchemaConverter  */
export type RawTranscriptsCardsField = TypeOf<typeof RawTranscriptsCardsFieldC>
/** @deprecated update to use JSON schema or don't use SchemaConverter  */
export type TranscriptsCardsFieldPartial = TypeOf<typeof TranscriptsCardsFieldPartialC>

/** @deprecated update to use JSON schema or don't use SchemaConverter  */
export default (field: RawTranscriptsCardsField): TranscriptsCardsFieldPartial => ({
    renderAs: FIELD_RENDER_TYPES.TRANSCRIPTS_CARDS,
    cards: field.cards,
    recordId: field.recordId,
});
