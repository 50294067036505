import isType from '../../../../io-ts/isType';
import Converter from '../Converter';
import { RawField } from '../types/RawField';
import {
    RawUsersGroupsFilter,
    RawUsersGroupsFilterC, toUsersGroupsFilter,
    UsersGroupsFilterPartial,
} from '../functions/toUsersGroupsChoice';

export default class UsersGroupsFilterConverter extends Converter<RawUsersGroupsFilter> {
    public canConvert(field: RawField): field is RawUsersGroupsFilter {
        return isType(field, RawUsersGroupsFilterC);
    }

    protected convertPartially(field: RawUsersGroupsFilter): UsersGroupsFilterPartial | null {
        return toUsersGroupsFilter(field);
    }
}
