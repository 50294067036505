/**
 * @see src/CamBundle/Message/Websocket/Record/RecordCut.php
 */

import * as t from 'io-ts';
import { UUID } from 'io-ts-types/UUID';
import { Type } from './Type';

export enum CutStatus {
    SUCCESS = 'success',
    ERROR = 'error',
}

const BaseRecordCutC = t.type({
    type: t.literal(Type.CUT),
    id: UUID,
});

const SuccessRecordCutDetailsC = t.type({
    status: t.literal(CutStatus.SUCCESS),
    cut: t.type({
        id: UUID,
        name: t.string,
    }),
});

const ErrorRecordCutDetailsC = t.type({
    status: t.literal(CutStatus.ERROR),
    cut: t.null,
});

export const RecordCutC = t.intersection([
    BaseRecordCutC,
    t.union([SuccessRecordCutDetailsC, ErrorRecordCutDetailsC]),
]);

export type RecordCut = t.TypeOf<typeof RecordCutC>;
