import { FormFieldPartial } from '../types/FormField';
import { GridSizeFieldFields, GridSizeField, RawGridSizeField } from '../types/GridSizeField';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import convertForm from './convertForm';

export default (field: RawGridSizeField): GridSizeField => {
    const convertedForm = convertForm(field) as FormFieldPartial & {
        fields: GridSizeFieldFields
    };

    return {
        ...convertedForm,
        renderAs: FIELD_RENDER_TYPES.GRID_SIZE,
        minSize: field.minSize,
        type: field.type,
        errors: [],
        tooltip: null,
        disabled: false,
        updateOnChange: false,
        updateDebounce: false,
    };
};
