import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';
import { FormFieldPartialC, RawFormFieldC } from './FormField';
import { RawSectionalFormC, SectionalFormPartialC } from './SectionalForm';

export const RawFormC = t.union([RawFormFieldC, RawSectionalFormC]);
export const FormPartialC = t.union([FormFieldPartialC, SectionalFormPartialC]);
export const FormC = t.intersection([FormPartialC, FieldCommonPropsC]);

export type RawForm = TypeOf<typeof RawFormC>;
export type FormPartial = TypeOf<typeof FormPartialC>;
export type Form = TypeOf<typeof FormC>;
