import isType from '../../../../io-ts/isType';
import Converter from '../Converter';
import { RawField } from '../types/RawField';
import toRadioChoice, { RadioChoicePartial, RawRadioChoice, RawRadioChoiceC } from '../functions/toRadioChoice';

export default class RadioChoiceConverter extends Converter<RawRadioChoice> {
    public canConvert(field: RawField): field is RawRadioChoice {
        return isType(field, RawRadioChoiceC);
    }

    protected convertPartially(field: RawRadioChoice): RadioChoicePartial | null {
        return toRadioChoice(field);
    }
}
