<template>
    <Sidebar
        :id="id"
        :title="title"
        :is-shown="isShown"
        class="sidebar"
        :style="styles"
        @hide="hide"
        @hidden="onHidden"
    >
        <form
            ref="formRef"
            class="h-100 form-sidebar__content"
        >
            <StandardField
                v-if="form"
                :field="form"
                :field-id="form.name"
                novalidate
                :label-size="labelSize"
                @section-change="onSectionChange"
                @update-form="update"
            />
        </form>

        <template #footer="footerProps">
            <div
                class="form-sidebar__footer"
                :class="footerProps.className"
            >
                <Button
                    variant="light"
                    :block="false"
                    @click="hide"
                >
                    Cancel
                </Button>
                <Button
                    v-if="canAct"
                    :id="ACTION_BUTTON_ID"
                    :variant="actionButton.variant"
                    :block="false"
                    :cypress-id="actionButton.title"
                    :disabled="!validated"
                    @click="onSave"
                >
                    {{ actionButton.title }}
                </Button>
            </div>
        </template>
        <div
            class="sidebar__handle"
            @mousedown.stop.prevent="onHandleMovingStart"
        />
    </Sidebar>
</template>

<script lang="ts" setup>
import {
    computed, onMounted, onUnmounted, ref,
} from 'vue';
import isType from '../../../scripts/io-ts/isType';
import Sidebar from './Sidebar.vue';
import StandardField from '../Form/StandardField.vue';
import Button from '../Elements/Button.vue';
import { Variant } from '../Elements/Button/Variant';
import { validate } from '../../../scripts/helpers/schemaConverter/validators/required';
import { Form, RawFormC } from '../../../scripts/helpers/schemaConverter/converters/types/Form';
import RequestBuilder, {
    Significance,
} from '../../../scripts/helpers/Request/Builder/RequestBuilder';
import { convertJsonForm, serializeFormFields } from '../../../scripts/mixins/jsonSchemaConverter';
import {
    ActionButton, MessageResponseC, Response, ResponseC,
} from '../../store/modules/sidebar';
import { roomMenuModule } from '../../store';
import { useToaster } from '../../composables/toaster';
import { LABEL_SIZE_SM, LabelSize } from '../../../scripts/types/LabelSize';
import { SectionType } from '../../../scripts/types/sections';
import FIELD_RENDER_TYPES from '../../../scripts/helpers/fieldRenderTypes';

const { makeErrorToast } = useToaster();

const ACTION_BUTTON_ID = 'action-button';

const MIN_SIDEBAR_SIZE = 21;
const MAX_SIDEBAR_SIZE = 42;

type Props = {
    id: string;
    title?: string;
    validatable?: boolean;
    openObserveOnClose?: boolean;
    actionButton?: ActionButton;
    action?: string|null;
    preAction?: string|null;
    updateAction?: string|null;
    isShown: boolean;
    form?: Form|null;
    labelSize: LabelSize | null;
}

type Emits = {
    (e: 'update:form', value: Form|null): void,
    (e: 'hide'): void,
    (e: 'save', data: { response: Response }): void,
    (e: 'hidden'): void,
}

const emit = defineEmits<Emits>();

const props = withDefaults(defineProps<Props>(), {
    title: 'Sidebar',
    validatable: false,
    openObserveOnClose: false,
    actionButton: () => ({ title: 'Send', variant: Variant.PRIMARY }),
    action: null,
    preAction: null,
    updateAction: null,
    isShown: false,
    form: null,
    labelSize: LABEL_SIZE_SM,
});

const sidebarWidth = ref<number>(MIN_SIDEBAR_SIZE);
const sidebarWidthWasSetManually = ref(false);

const handleIsMoving = ref<boolean>(false);
const formRef = ref<HTMLFormElement|null>(null);

const validated = computed<boolean>(() => {
    if (!props.validatable) {
        return true;
    }

    if (props.form === null) {
        return false;
    }

    return validate(props.form);
});

const canAct = computed(() => {
    if (!props.form || !props.action) {
        return false;
    }

    return !props.form.disabled;
});

const hide = (): void => {
    emit('hide');

    if (props.openObserveOnClose) {
        roomMenuModule.showRoomMenu();
    }
};

const getForm = (): Form => {
    const { form } = props;
    if (!form) {
        throw new Error('Unable to send payload without a form');
    }

    if (form.disabled) {
        throw new Error('You can not send disabled form');
    }

    return form;
};

/**
 * Returns false when the action should be aborted.
 */
const preAct = async (): Promise<boolean> => {
    if (!props.preAction) {
        return true;
    }

    const ignoredFields = [
        FIELD_RENDER_TYPES.MULTI_FILES,
        FIELD_RENDER_TYPES.FILE,
        FIELD_RENDER_TYPES.FILE_UPLOAD,
        FIELD_RENDER_TYPES.AUDIO,
        FIELD_RENDER_TYPES.VIDEO_UPLOAD,
        FIELD_RENDER_TYPES.AVATAR,
    ];

    const form = getForm();
    const payload = serializeFormFields(
        form.fields,
        form.name,
        new FormData(),
        ignoredFields,
    );

    const request = new RequestBuilder(ResponseC)
        .setUrl(props.preAction)
        .setMethod('POST')
        .setFormData(payload)
        .setSignificance(Significance.Main)
        .allow400()
        .build();

    const response = await request.send();
    const responseStatusCode = request.getResponseStatusCode();

    if (responseStatusCode !== 400) {
        return true;
    }

    if (MessageResponseC.is(response)) {
        makeErrorToast({
            title: response.title,
            message: response.message,
        });

        return false;
    }

    throw new Error('Pre-action failed.');
};

const act = async (): Promise<void> => {
    if (!await preAct()) {
        return;
    }

    if (!props.action) {
        throw new Error('No form URL provided.');
    }

    const form = getForm();
    const payload = serializeFormFields(
        form.fields,
        form.name,
        new FormData(),
    );

    const request = new RequestBuilder(ResponseC)
        .setUrl(props.action)
        .setSignificance(Significance.Main)
        .setFormData(payload)
        .allow400()
        .build();

    const response = await request.send();
    const responseStatusCode = request.getResponseStatusCode();

    if (responseStatusCode === 400 && isType(response, RawFormC)) {
        const content = convertJsonForm(response);
        if (content === null) {
            throw new Error('Unable to convert form');
        }

        emit('update:form', content);

        return;
    }

    if (responseStatusCode === 400 && MessageResponseC.is(response)) {
        makeErrorToast({
            title: response.title,
            message: response.message,
        });

        return;
    }

    emit('save', { response });
    emit('hide');
};

const onSectionChange = (section: SectionType) => {
    if (sidebarWidthWasSetManually.value) {
        return;
    }

    if (section === SectionType.EVALUATION) {
        sidebarWidth.value = MAX_SIDEBAR_SIZE;
        return;
    }

    sidebarWidth.value = MIN_SIDEBAR_SIZE;
};

const onHidden = () => {
    emit('hidden');
    sidebarWidthWasSetManually.value = false;
};

const update = async (preserveUpdatedData = true): Promise<void> => {
    if (!props.form) {
        throw new Error('Unable to send payload without a form');
    }

    if (!props.updateAction) {
        throw new Error('This form can not be updated');
    }

    const payload = serializeFormFields(
        props.form.fields,
        props.form.name,
        new FormData(),
    );

    payload.append('preserveUpdatedData', preserveUpdatedData ? 'true' : 'false');

    const request = new RequestBuilder(RawFormC)
        .setUrl(props.updateAction)
        .setSignificance(Significance.Main)
        .setFormData(payload)
        .build();

    const response = await request.send();
    const content = convertJsonForm(response);

    if (content === null) {
        throw new Error('Unable to convert form');
    }

    emit('update:form', content);
};

const onSave = (): void => {
    const form = formRef.value;

    if (!form) {
        return;
    }

    if (!form.checkValidity()) {
        form.reportValidity();
        return;
    }

    act();
};
type Styles = {
    width: string,
    transitionProperty: string,
};
const styles = computed<Styles|null>(() => {
    if (sidebarWidth.value === MIN_SIDEBAR_SIZE) {
        return null;
    }

    return {
        width: `${sidebarWidth.value}rem`,
        transitionProperty: handleIsMoving.value ? 'none' : 'width',
    };
});
const onHandleMoving = (event: MouseEvent) => {
    if (handleIsMoving.value) {
        const remSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
        if (formRef.value?.getBoundingClientRect()) {
            const newSize = (event.clientX - 60) / remSize;
            sidebarWidth.value = newSize > MIN_SIDEBAR_SIZE ? newSize : MIN_SIDEBAR_SIZE;
        }
    }
};

let onHandleMovingEnd: ((e: MouseEvent) => void);

const removeMouseEventListeners = (): void => {
    document.removeEventListener('mousemove', onHandleMoving);
    document.removeEventListener('mouseup', onHandleMovingEnd);
};

onHandleMovingEnd = () => {
    handleIsMoving.value = false;

    removeMouseEventListeners();
};

const onHandleMovingStart = () => {
    sidebarWidthWasSetManually.value = true;
    handleIsMoving.value = true;
    document.addEventListener('mousemove', onHandleMoving);
    document.addEventListener('mouseup', onHandleMovingEnd);
};

const onEnterKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
        const button = document.getElementById(ACTION_BUTTON_ID);
        button?.click();
    }
};

onMounted(() => {
    document.addEventListener('keyup', onEnterKeyUp);
});

onUnmounted(() => {
    removeMouseEventListeners();
    document.removeEventListener('keyup', onEnterKeyUp);
});

</script>

<style lang="scss">
@import '../../../styles/abstracts/spacings';

.sidebar {
    position: relative;

    &__handle {
        position: absolute;
        top: 0;
        right: 0;

        z-index: 15;

        width: 0.25rem;
        height: 100%;

        cursor: col-resize;

        transition: background-color 0.3s;

        &:hover,
        &:active {
            background-color: var(--theme-color-surface-accent-primary);
        }
    }
}

.form-sidebar__content {
    padding: $spacing-xs $spacing-xl;

    .scrollable {
        height: calc(100% - 8.5rem);
    }
}

.form-sidebar__footer {
    display: grid;

    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    grid-gap: $spacing-s;
}
</style>
