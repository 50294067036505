import Converter from '../Converter';
import toEvaluationStaticText, {
    EvaluationStaticTextFieldPartial,
    RawEvaluationStaticTextField,
    RawEvaluationStaticTextFieldC,
} from '../functions/toEvaluationStaticText';
import { RawField } from '../types/RawField';

export default class EvaluationStaticTextFieldConverter
    extends Converter<RawEvaluationStaticTextField> {
    public canConvert(field: RawField): field is RawEvaluationStaticTextField {
        return RawEvaluationStaticTextFieldC.is(field);
    }

    protected convertPartially(field: RawEvaluationStaticTextField)
        : EvaluationStaticTextFieldPartial {
        return toEvaluationStaticText(field);
    }
}
