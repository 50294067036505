import { Any, TypeOf } from 'io-ts';
import { isRight } from 'fp-ts/Either';
import reporter from 'io-ts-reporters';

export function decodeAsTypeC<T extends Any>(val: unknown, type: T, name = 'Value'): TypeOf<T> {
    const decoded = type.decode(val);

    if (!isRight(decoded)) {
        const validationErrors = reporter.report(decoded);
        throw new TypeError(`${name} has invalid type. ${validationErrors.join('\n')}}`);
    }

    return decoded.right;
}

export default function assertIsTypeC<T extends Any>(val: unknown, type: T, name = 'Value')
    : asserts val is TypeOf<T> {
    decodeAsTypeC(val, type, name);
}
