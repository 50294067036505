import Converter from '../Converter';
import toTranscriptsCards, {
    RawTranscriptsCardsField,
    RawTranscriptsCardsFieldC,
    TranscriptsCardsFieldPartial,
} from '../functions/toTranscriptsCards';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';

export default class TranscriptsCardsFieldConverter extends Converter<RawTranscriptsCardsField> {
    public canConvert(field: RawField): field is RawTranscriptsCardsField {
        return isType(field, RawTranscriptsCardsFieldC);
    }

    protected convertPartially(field: RawTranscriptsCardsField): TranscriptsCardsFieldPartial {
        return toTranscriptsCards(field);
    }
}
