import BellIcon from '../../images/bell.svg?component';
import ROUTE_NAMES from './routeNames';

const meta = {
    title: 'Notifications',
    icon: BellIcon,
    permissions: (): string[] => [],
};

const Notifications = () => import('../views/Notification/Notifications.vue');
export default [
    {
        path: 'show/all',
        name: ROUTE_NAMES.NOTIFICATIONS_ALL,
        component: Notifications,
        meta: {
            ...meta,
            menuTitle: 'All',
        },
    },
    {
        path: 'show/reply',
        name: ROUTE_NAMES.NOTIFICATIONS_REPLY,
        component: Notifications,
        meta: {
            ...meta,
            menuTitle: 'Reply',
        },
    },
    {
        path: 'show/tag',
        name: ROUTE_NAMES.NOTIFICATIONS_TAG,
        component: Notifications,
        meta: {
            ...meta,
            menuTitle: 'Tag',
        },
    },
    {
        path: 'show/sharing',
        name: ROUTE_NAMES.NOTIFICATIONS_SHARING,
        component: Notifications,
        meta: {
            ...meta,
            menuTitle: 'Sharing',
        },
    },
];
