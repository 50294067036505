<template>
    <div
        class="warning-dot"
        :class="`warning-dot--${size}`"
        data-cy="warning-icon"
    >
        <CrossIcon class="warning-icon" />
    </div>
</template>

<script lang="ts">
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import CrossIcon from '../../../../images/x.svg?component';

type IconSize = 'sm' | 'md';

@Options({
    components: {
        CrossIcon,
    },
})
export default class WarningDot extends Vue {
    @Prop({ type: String, default: 'md' })
    private readonly size!: IconSize;
}
</script>

<style lang="scss" scoped>
@import '../../../../styles/abstracts/spacings';

.warning-dot {
    position: absolute;
    top: -$spacing-xxsm;

    background-color: var(--theme-color-surface-accent-primary);
    border-style: none;
    border-radius: 100%;

    &--md {
        $side-size: 1.15rem;

        right: -$spacing-xxsm;

        width: $side-size;
        height: $side-size;
    }

    &--sm {
        $side-size: 1rem;

        right: -$spacing-xs;

        width: $side-size;
        height: $side-size;
    }

    &--xs {
        $side-size: 0.875rem;

        top: -20%;
        left: 80%;

        width: $side-size;
        height: $side-size;
    }

    > .warning-icon {
        position: absolute;
        top: 0;
        left: 0;

        width: inherit;
        height: inherit;

        fill: var(--theme-color-surface-primary-default);
    }
}

</style>
