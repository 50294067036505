import Converter from '../Converter';
import toExpiration, {
    RawExpirationField,
    RawExpirationFieldC,
    ExpirationFieldPartial,
} from '../functions/toExpiration';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';

export default class ExpirationFieldConverter extends Converter<RawExpirationField> {
    public canConvert(field: RawField): field is RawExpirationField {
        return isType(field, RawExpirationFieldC);
    }

    protected convertPartially(field: RawExpirationField): ExpirationFieldPartial {
        return toExpiration(field);
    }
}
