import ServerIcon from '../../../../images/server.svg?component';
import ROUTE_NAMES from '../../routeNames';
import SECTION_NAMES from '../../sectionNames';
import { permissionsModule } from '../../../store';

const meta = {
    title: SECTION_NAMES.SERVERS_AND_SERVICES,
    icon: ServerIcon,
    permissions: (): string[] => [permissionsModule.allPermissions.adminServersSsoConfigs],
};

export default [
    {
        path: '',
        name: ROUTE_NAMES.ADMIN_SSO_CONFIG_LIST,
        component: ():Promise<unknown> => import('../../../views/admin/Servers/SSO/AdminSsoConfigTable.vue'),
        meta,
    },
    {
        path: 'edit/:id',
        name: ROUTE_NAMES.ADMIN_SSO_CONFIG_VIEW,
        component: ():Promise<unknown> => import('../../../views/admin/Servers/SSO/AdminSsoConfigForm.vue'),
        meta,
    },
    {
        path: 'new',
        name: ROUTE_NAMES.ADMIN_SSO_CONFIG_NEW,
        component: ():Promise<unknown> => import('../../../views/admin/Servers/SSO/AdminSsoConfigForm.vue'),
        meta,
    },
];
