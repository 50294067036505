import isType from '../../../../io-ts/isType';
import Converter from '../Converter';
import { RawField } from '../types/RawField';
import toRightsChoice, { RawRightsChoice, RawRightsChoiceC, RightsChoicePartial } from '../functions/toRightsChoice';

export default class RightsChoiceConverter extends Converter<RawRightsChoice> {
    public canConvert(field: RawField): field is RawRightsChoice {
        return isType(field, RawRightsChoiceC);
    }

    protected convertPartially(field: RawRightsChoice): RightsChoicePartial | null {
        return toRightsChoice(field);
    }
}
