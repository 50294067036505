import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';

import convertForm from './convertForm';
import { assertIsNonNullable } from '../../../../typeAssertions/undefined';
import { AvatarFieldPartial, RawAvatarField } from '../types/Avatar';
import assertIsString from '../../../../typeAssertions/string';

export default function toAvatar(field: RawAvatarField): AvatarFieldPartial {
    const convertedForm = convertForm(field);
    assertIsNonNullable(convertedForm);

    return {
        ...convertedForm,
        renderAs: FIELD_RENDER_TYPES.AVATAR,
    };
}
