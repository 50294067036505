import { permissionsModule as pM, userModule } from '../../../../vue/store';
import ROUTE_NAMES from '../../../../vue/routes/routeNames';

export const getPermittedHomeRoute = (): string|null => {
    const homeRoutes = [
        {
            permission: pM.allPermissions.generalHome,
            name: ROUTE_NAMES.HOME,
        },
        {
            permission: pM.allPermissions.monitoring,
            name: ROUTE_NAMES.OBSERVE_MAIN,
        },
        {
            permission: pM.allPermissions.review,
            name: ROUTE_NAMES.REVIEW_LIST,
        },
        {
            permission: pM.allPermissions.schedule,
            name: ROUTE_NAMES.SCHEDULE_MAIN,
        },
        {
            permission: pM.allPermissions.reports,
            name: ROUTE_NAMES.REPORT_LIST,
        },
        {
            permission: pM.allPermissions.admin,
            name: ROUTE_NAMES.ADMIN_MAIN,
        },
    ];

    const permittedRoutes = homeRoutes
        .filter((route) => userModule.hasPermission(route.permission))
        .map((route) => route.name);

    return permittedRoutes.shift() ?? null;
};
