import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import optional from '../../../../io-ts/optional';
import { Choice, ChoiceC } from '../types/Choice';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';

export const RawSingleChoiceWithoutRendererC = t.type({
    title: t.union([t.string, t.null]),
    name: t.string,
    type: t.literal('string'),
    enum: t.array(t.string),
    enum_titles: t.array(t.string),
    enum_disabled: optional(t.array(t.string)),
    default: t.union([t.string, t.null]),
});

export const RawSingleChoiceC = t.intersection([
    RawSingleChoiceWithoutRendererC,
    t.type({
        renderer: t.literal(FIELD_RENDER_TYPES.SINGLE_CHOICE),
    }),
]);

export const SingleChoicePartialWithoutRendererC = t.type({
    choices: t.array(ChoiceC),
});

export const SingleChoicePartialC = t.intersection([
    SingleChoicePartialWithoutRendererC,
    t.type({
        renderAs: t.literal(FIELD_RENDER_TYPES.SINGLE_CHOICE),
    }),
]);

export const SingleChoiceC = t.intersection([SingleChoicePartialC, FieldCommonPropsC]);

export type RawSingleChoiceWithoutRenderer = TypeOf<typeof RawSingleChoiceWithoutRendererC>;
export type RawSingleChoice = TypeOf<typeof RawSingleChoiceC>;
export type SingleChoicePartial = TypeOf<typeof SingleChoicePartialC>;
export type SingleChoice = TypeOf<typeof SingleChoiceC>;

const transformItemsToChoices = (
    field: RawSingleChoiceWithoutRenderer,
): Choice[] => field.enum.map(
    (value, i): Choice => {
        const title = field.enum_titles[i];
        const selected = field.default === value;
        const disabled = (field.enum_disabled ?? []).includes(value);

        return ({
            value,
            title,
            selected,
            disabled,
        });
    },
);

export default (field: RawSingleChoiceWithoutRenderer): SingleChoicePartial => {
    const choices = transformItemsToChoices(field);

    return {
        renderAs: FIELD_RENDER_TYPES.SINGLE_CHOICE,
        choices,
    };
};
