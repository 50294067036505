import isType from '../../../../io-ts/isType';
import Converter from '../Converter';
import { RawField } from '../types/RawField';
import toUsersGroupsChoice, {
    RawUsersGroupsChoice,
    RawUsersGroupsChoiceC,
    UsersGroupsChoicePartial,
} from '../functions/toUsersGroupsChoice';

export default class UsersGroupsChoiceConverter extends Converter<RawUsersGroupsChoice> {
    public canConvert(field: RawField): field is RawUsersGroupsChoice {
        return isType(field, RawUsersGroupsChoiceC);
    }

    protected convertPartially(field: RawUsersGroupsChoice): UsersGroupsChoicePartial | null {
        return toUsersGroupsChoice(field);
    }
}
