import isType from '../../../io-ts/isType';
import { Form, FormC } from '../converters/types/Form';
import { FieldCommonProps } from '../types/FieldCommonProps';
import { SelectFieldC } from '../converters/functions/toSelectFormat';
import { FileFieldC } from '../converters/functions/toFileUpload';
import { UsersGroupsChoiceC } from '../converters/functions/toUsersGroupsChoice';
import { RadioChoiceC } from '../converters/functions/toRadioChoice';
import { RetentionRuleFieldC } from '../converters/functions/toRetentionRule';
import { InputFieldC } from '../converters/functions/toInputFormat';
import { AudioFieldC } from '../converters/functions/toAudioFormat';
import { MultiFilesFieldC } from '../converters/functions/toMultiFilesFormat';
import { MultiSelectFieldC } from '../converters/functions/toMultiSelectFormat';
import { StaticTextFieldC } from '../converters/functions/toStaticText';
import { StaticImageFieldC } from '../converters/functions/toStaticImage';

const flatMapForm = (form: Form): FieldCommonProps[] => Object.values(form.fields)
    .flatMap((field) => (isType(field, FormC) ? flatMapForm(field) : field));

const getRequiredFields = (form: Form) => flatMapForm(form)
    .filter((field: FieldCommonProps) => field.isRequired ?? false);

const valueFieldTypes = [
    InputFieldC,
    AudioFieldC,
];

const groupFieldTypes = [
    UsersGroupsChoiceC,
    RadioChoiceC,
    RetentionRuleFieldC,
];

const isStaticField = (field: FieldCommonProps) => [
    StaticTextFieldC,
    StaticImageFieldC,
].some((f) => f.is(field));

const validateField = (field: FieldCommonProps): boolean => {
    const isValueField = valueFieldTypes.some((type) => isType(field, type));
    if (isValueField) {
        if (field.type === 'string') {
            return Boolean((field.value as string).trim());
        }
        return Boolean(field.value);
    }

    if (isType(field, SelectFieldC)) {
        return true;
    }

    if (isType(field, MultiSelectFieldC)) {
        return field.selectedChoices.length > 0;
    }

    if (isType(field, FileFieldC)) {
        return field.multiple
            ? field.files.length > 0
            : field.file !== null;
    }

    if (isType(field, MultiFilesFieldC)) {
        return field.files.length > 0 || field.uploadFiles.length > 0;
    }

    if (isStaticField(field)) {
        return true;
    }

    const isGroupField = groupFieldTypes.some((type) => isType(field, type));
    if (isGroupField) {
        return true;
    }

    throw new Error(`No required validator for field ${field.renderAs}.`);
};

export const validate = (form: Form): boolean => getRequiredFields(form).every(validateField);
