import Converter from '../Converter';
import toCommentReview, {
    RawCommentReviewField,
    RawCommentReviewFieldC,
    CommentReviewFieldPartial,
} from '../functions/toCommentReview';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';

export default class CommentReviewFieldConverter extends Converter<RawCommentReviewField> {
    public canConvert(field: RawField): field is RawCommentReviewField {
        return isType(field, RawCommentReviewFieldC);
    }

    protected convertPartially(field: RawCommentReviewField): CommentReviewFieldPartial {
        return toCommentReview(field);
    }
}
