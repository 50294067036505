import Vuex, { Store } from 'vuex';
import Vue from 'vue';
import { initializeStores, modules } from './store-accessor';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
Vue.use(Vuex);

// See https://github.com/championswimmer/vuex-module-decorators/issues/83#issuecomment-546929583

// Initialize the modules using a Vuex plugin that runs when the root store is
// first initialized. This is vital to using static modules because the
// modules don't know the root store when they are loaded. Initializing them
// when the root store is created allows them to be hooked up properly.
const initializer = (store: Store<never>): void => initializeStores(store);
export const plugins = [initializer];
export * from './store-accessor'; // re-export the modules

// Export the root store. You can add mutations & actions here as well.
// Note that this is a standard Vuex store, not a vuex-module-decorator one.
// (Perhaps could be, but I put everything in modules)
export default new Store({
    plugins: plugins as any, // important!
    modules, // important!
});
