import CameraIcon from '../../../../images/camera.svg?component';
import ROUTE_NAMES from '../../routeNames';
import SECTION_NAMES from '../../sectionNames';
import { permissionsModule as pM } from '../../../store';

const meta = {
    title: SECTION_NAMES.ROOMS_AND_CAMERAS,
    permissions: (): string[] => [pM.allPermissions.adminRooms],
    icon: CameraIcon,
};

const AdminCameraView = () => import('../../../views/admin/AdminCamera/AdminCameraView.vue');

export default [
    {
        path: 'camera/new',
        name: ROUTE_NAMES.ADMIN_CAMERA_NEW,
        component: AdminCameraView,
        meta: { new: true, ...meta },
    },
    {
        path: 'camera/edit/:id',
        name: ROUTE_NAMES.ADMIN_CAMERA_VIEW,
        component: AdminCameraView,
        meta: { new: false, ...meta },
    },
];
