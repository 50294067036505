<template>
    <Teleport to="body">
        <Transition name="popover">
            <div
                v-if="showSync"
                :id="id"
                ref="root"
                class="popover"
                data-cy="popover"
                :class="customClass"
                :style="combinedStyles"
            >
                <slot />
            </div>
        </Transition>
    </Teleport>
</template>

<script lang="ts" setup>
import {
    computed, onMounted, onUnmounted, ref, watch,
} from 'vue';
import { useElementBounding } from '@vueuse/core';
import clamp from 'lodash/clamp';
import { useWindow } from '../../composables/window';
import { useClickOutsideListener } from '../../composables/clickOutsideListener';
import { Position } from '../../store/modules/globalSettings';
import { HIDE_DROPDOWNS_EVENT } from '../../../scripts/constants/events';
import useEventBus from '../../composables/useEventBus';

const root = ref<HTMLDivElement | null>(null);

const outOfBoundsMargin = 20;

type Styles = {
    top: string;
    left: string | undefined;
};

type Props = {
    id: string;
    show?: boolean;
    target: string;
    position?: Position;
    customClass?: string;
    offset?: number;
    boundaryPadding?: number;
    autoFit?: boolean;
    styles?: Styles
};

const props = withDefaults(defineProps<Props>(), {
    show: false,
    position: Position.BOTTOM_RIGHT,
    offset: 5,
    boundaryPadding: 50,
    customClass: undefined,
    autoFit: false,
    styles: undefined,
});

type Emits = {
    (event: 'shown', value: Event): void;
    (event: 'hidden', value: Event): void;
    (event: 'update:show', value: Props['show']): void;
};

const emit = defineEmits<Emits>();

const showSync = computed<Props['show']>({
    get: () => props.show,
    set: (value: Props['show']) => emit('update:show', value),
});

const windowScreen = useWindow();

const targetElement = ref<HTMLElement | null>(null);

const targetPosition = useElementBounding(targetElement);

const handleScrollOutside = () => {
    if (showSync.value) {
        showSync.value = false;
    }
};

useClickOutsideListener([props.id], () => {
    handleScrollOutside();
});

const handleScroll = (event: Event) => {
    const target = event.target as HTMLElement;
    if (target && typeof target.closest === 'function') {
        if (!target.closest('.popover') && !target.closest('.sidebar')) {
            handleScrollOutside();
        }
    }
};

const onMouseLeave = () => {
    if (showSync.value) {
        showSync.value = false;
    }
};

onMounted(() => {
    targetElement.value = document.getElementById(props.target);
    window.addEventListener('scroll', handleScroll, true);

    useEventBus.on(HIDE_DROPDOWNS_EVENT, (id) => {
        if (showSync.value && id !== props.target) {
            showSync.value = false;
        }
    });
});

onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll, true);
    useEventBus.off(HIDE_DROPDOWNS_EVENT);
});

const calculateRealPosition = () => {
    if (!props.autoFit) {
        return props.position;
    }

    if (root.value === null) {
        return props.position;
    }

    const isBottomOutBounds = targetPosition.y.value + root.value.offsetHeight
        > windowScreen.innerHeight.value - outOfBoundsMargin;

    const isTopOutBounds = targetPosition.y.value - root.value.offsetHeight < outOfBoundsMargin;

    if (
        [Position.BOTTOM, Position.BOTTOM_LEFT, Position.BOTTOM_RIGHT].includes(props.position)
        && isBottomOutBounds
    ) {
        switch (props.position) {
        case Position.BOTTOM:
            return Position.TOP;
        case Position.BOTTOM_LEFT:
            return Position.TOP_LEFT;
        case Position.BOTTOM_RIGHT:
            return Position.TOP_RIGHT;
        default:
            return props.position;
        }
    }

    if (
        [Position.TOP, Position.TOP_LEFT, Position.TOP_RIGHT].includes(props.position)
        && isTopOutBounds
    ) {
        switch (props.position) {
        case Position.TOP:
            return Position.BOTTOM;
        case Position.TOP_LEFT:
            return Position.BOTTOM_LEFT;
        case Position.TOP_RIGHT:
            return Position.BOTTOM_RIGHT;
        default:
            return props.position;
        }
    }

    return props.position;
};

const topOffset = computed<number | null>(() => {
    const realPosition: Position = calculateRealPosition();

    if (targetElement.value === null) {
        return null;
    }

    if (root.value === null) {
        return null;
    }

    targetPosition.update();

    if ([Position.BOTTOM, Position.BOTTOM_LEFT, Position.BOTTOM_RIGHT].includes(realPosition)) {
        return targetPosition.top.value + targetPosition.height.value + props.offset;
    }

    if ([Position.TOP, Position.TOP_LEFT, Position.TOP_RIGHT].includes(realPosition)) {
        return targetPosition.top.value - root.value.offsetHeight + props.offset;
    }

    return null;
});

const leftOffset = computed<number | null>(() => {
    const realPosition: Position = calculateRealPosition();

    if (root.value === null) {
        return null;
    }

    if (targetElement.value === null) {
        return null;
    }

    targetPosition.update();

    const left = targetPosition.left.value;
    const width = targetPosition.width.value;
    const { offsetWidth } = root.value;

    let offsetWithoutLimits = left + width / 2 - offsetWidth / 2;

    if (realPosition === Position.BOTTOM_LEFT) {
        offsetWithoutLimits = left;
    }

    if (realPosition === Position.BOTTOM_RIGHT) {
        offsetWithoutLimits = left + width - offsetWidth;
    }

    if (realPosition === Position.TOP_LEFT) {
        offsetWithoutLimits = left;
    }

    if (realPosition === Position.TOP_RIGHT) {
        offsetWithoutLimits = left + width - offsetWidth;
    }

    const minOffset = props.boundaryPadding;
    const maxOffset = windowScreen.innerWidth.value - offsetWidth - props.boundaryPadding;

    return clamp(offsetWithoutLimits, minOffset, maxOffset);
});

const combinedStyles = computed<Styles>(() => {
    let top = '';
    let left = '';

    if (props.styles?.top) {
        top = props.styles.top;
    } else if (topOffset.value) {
        top = `${topOffset.value}px`;
    }

    if (props.styles?.left) {
        left = props.styles.left;
    } else if (leftOffset.value) {
        left = `${leftOffset.value}px`;
    }

    return { top, left };
});

watch(showSync, (value) => {
    const $event = new Event('popover-toggle', { bubbles: true, cancelable: true });
    targetElement.value?.dispatchEvent($event);
    if (value) {
        calculateRealPosition();
        emit('shown', $event);
    } else {
        emit('hidden', $event);
    }
});
</script>

<style lang="scss" scoped>
@import '../../../styles/abstracts/spacings';
@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/font-sizes';
@import '../../../styles/abstracts/z-indexes';

.popover {
    z-index: $user-info-popup-z-index;

    max-width: 28.75rem;
    padding: 0;

    font-size: $font-size-base;

    background-color: var(--theme-color-surface-primary-default);

    border: 0;
    border-radius: $border-radius;
    box-shadow: var(--shadow-4);

    transition: opacity 0.2s ease;
}

.popover-enter-from,
.popover-leave-to {
    opacity: 0;
}
</style>
