import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ name: 'LoadingModule' })
export default class LoadingModule extends VuexModule {
    private itemsToLoad = 0; // whole components data, show big spinner

    private ajaxRequests = 0; // secondary requests, dim screen

    @Mutation
    public incrementItemsToLoad(): void {
        this.itemsToLoad += 1;
    }

    @Mutation
    public decrementItemsToLoad(): void {
        if (this.itemsToLoad > 0) {
            this.itemsToLoad -= 1;
        }
    }

    @Mutation
    public incrementAjaxRequests(): void {
        this.ajaxRequests += 1;
    }

    @Mutation
    public decrementAjaxRequests(): void {
        if (this.ajaxRequests > 0) {
            this.ajaxRequests -= 1;
        }
    }

    get isLoaded(): boolean {
        return this.itemsToLoad <= 0;
    }

    get hasAjaxRequests(): boolean {
        return this.ajaxRequests > 0;
    }
}
