import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { ColorOption } from '../../components/Elements/ColorPicker/types/ColorOption';

@Module({ name: 'colorsModule' })
export default class ColorsModule extends VuexModule {
    private _colors: ColorOption[] = [];

    private _defaultColor: string|null = null;

    public get colors(): ColorOption[] {
        return this._colors;
    }

    public get defaultColor(): ColorOption | null {
        if (!this._defaultColor) {
            return null;
        }

        return this.colors.find((color) => color.name === this._defaultColor) ?? null;
    }

    public get colorsDictionary(): Record<string, string> {
        return Object.fromEntries(
            this.colors.map(({ name, value }) => [name, value]),
        );
    }

    @Mutation
    public setColors(colors: ColorOption[]): void {
        this._colors = colors;
    }

    @Mutation
    public setDefaultColor(color: string): void {
        this._defaultColor = color;
    }
}
