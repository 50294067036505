import isType from '../../../../io-ts/isType';
import Converter from '../Converter';
import toPresetsControl from '../functions/toPresetsControl';
import { RawPresetsControl, RawPresetsControlC, PresetsControlPartial } from '../types/PresetsControl';
import { RawField } from '../types/RawField';

export default class PresetsControlConverter extends Converter<RawPresetsControl> {
    public canConvert(field: RawField): field is RawPresetsControl {
        return isType(field, RawPresetsControlC);
    }

    protected convertPartially(field: RawPresetsControl): PresetsControlPartial | null {
        return toPresetsControl(field);
    }
}
