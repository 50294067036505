import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import HeaderDotsItem from '../../../scripts/helpers/dataClasses/HeaderDotsItem';

@Module({ name: 'HeaderModule' })
/** @deprecated */
export default class HeaderModule extends VuexModule {
    private _additionalHeaderLinks:HeaderDotsItem[] = []

    @Mutation
    public setAdditionalHeaderLinks(items: HeaderDotsItem[]): void {
        if (items.length > 0) {
            items.unshift(new HeaderDotsItem('Export to', () => undefined));
        }
        this._additionalHeaderLinks = items;
    }

    get additionalHeaderLinks(): HeaderDotsItem[] {
        return this._additionalHeaderLinks;
    }

    get hasAdditionalHeaderLinks(): boolean {
        return this._additionalHeaderLinks.length > 0;
    }
}
