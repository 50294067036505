import Converter from '../Converter';
import toZoomAccount from '../functions/toZoomAccount';
import {
    ZoomAccountFieldPartial,
    RawZoomAccountField,
    RawZoomAccountFieldC,
} from '../types/ZoomAccount';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';

export default class ZoomAccountConverter extends Converter<RawZoomAccountField> {
    public canConvert(field: RawField): field is RawZoomAccountField {
        return isType(field, RawZoomAccountFieldC);
    }

    protected convertPartially(field: RawZoomAccountField): ZoomAccountFieldPartial {
        return toZoomAccount(field);
    }
}
