import Converter from '../Converter';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';
import toScheduleRules, {
    RawScheduleRules,
    RawScheduleRulesC,
    ScheduleRulesPartial,
} from '../functions/toScheduleRules';

export default class ScheduleRulesConverter extends Converter<RawScheduleRules> {
    public canConvert(field: RawField): field is RawScheduleRules {
        return isType(field, RawScheduleRulesC);
    }

    protected convertPartially(field: RawScheduleRules): ScheduleRulesPartial {
        return toScheduleRules(field);
    }
}
