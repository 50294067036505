import ServerIcon from '../../../../images/server.svg?component';
import ROUTE_NAMES from '../../routeNames';
import SECTION_NAMES from '../../sectionNames';
import { permissionsModule } from '../../../store';

const permissions = permissionsModule.allPermissions;

const meta = {
    title: SECTION_NAMES.SERVERS_AND_SERVICES,
    icon: ServerIcon,
    permissions: (): string[] => [permissions.adminServersLdapServers],
};

const AdminLdapServerForm = (): Promise<unknown> => import('../../../views/admin/Servers/Ldap/AdminLdapServerForm.vue');

export default [
    {
        path: '',
        name: ROUTE_NAMES.ADMIN_LDAP_SERVER_LIST,
        component: ():Promise<unknown> => import('../../../views/admin/Servers/Ldap/AdminLdapServerTable.vue'),
        meta,
    },
    {
        path: 'edit/:id',
        name: ROUTE_NAMES.ADMIN_LDAP_SERVER_VIEW,
        component: AdminLdapServerForm,
        meta,
    },
    {
        path: 'new',
        name: ROUTE_NAMES.ADMIN_LDAP_SERVER_NEW,
        component: AdminLdapServerForm,
        meta,
    },
];
