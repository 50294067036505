import Converter from '../Converter';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';
import toEvaluationCollapse, {
    RawEvaluationCollapse,
    RawEvaluationCollapseC, RawEvaluationCollapsePartial,
} from '../functions/toEvaluationCollapse';

export default class EvaluationCollapseConverter
    extends Converter<RawEvaluationCollapse> {
    public canConvert(field: RawField): field is RawEvaluationCollapse {
        return isType(field, RawEvaluationCollapseC);
    }

    protected convertPartially(field: RawEvaluationCollapse)
        : RawEvaluationCollapsePartial {
        return toEvaluationCollapse(field);
    }
}
