import ROUTE_NAMES from '../../../routes/routeNames';
import { MENU_ICONS } from './menuIcons';
import { MenuItem } from '../types/MenuItem';

export const MENU_ITEMS: MenuItem[] = [
    {
        routeName: ROUTE_NAMES.HOME,
        routeNamesToHighlight: [ROUTE_NAMES.HOME],
        inlinedSvg: MENU_ICONS.HomeIcon,
        text: 'Home',
    },
    {
        routeName: ROUTE_NAMES.OBSERVE_MAIN,
        routeNamesToHighlight: [ROUTE_NAMES.OBSERVE_MAIN],
        inlinedSvg: MENU_ICONS.ObserveIcon,
        text: 'Observe',
    },
    {
        routeName: ROUTE_NAMES.REVIEW_MAIN,
        routeNamesToHighlight: [
            ROUTE_NAMES.REVIEW_MAIN,
            ROUTE_NAMES.REVIEW_RECORD_SHOW,
            ROUTE_NAMES.REVIEW_RECORD_SHARED_SHOW,
        ],
        inlinedSvg: MENU_ICONS.ReviewIcon,
        text: 'Review',
    },
    {
        routeName: ROUTE_NAMES.SCHEDULE_MAIN,
        routeNamesToHighlight: [ROUTE_NAMES.SCHEDULE_MAIN],
        inlinedSvg: MENU_ICONS.ScheduleIcon,
        text: 'Schedule',
    },
    {
        routeName: ROUTE_NAMES.REPORT_ROOT,
        routeNamesToHighlight: [
            ROUTE_NAMES.REPORT_ROOT,
            ROUTE_NAMES.REPORT_VIEW,
        ],
        inlinedSvg: MENU_ICONS.ReportsIcon,
        text: 'Reports',
    },
    {
        routeName: ROUTE_NAMES.ADMIN_MAIN,
        routeNamesToHighlight: [ROUTE_NAMES.ADMIN_MAIN],
        inlinedSvg: MENU_ICONS.SettingsIcon,
        text: 'Settings',
    },
];
