/**
 * @see src/CamBundle/Message/Websocket/Record/RecordSynced.php
 */

import * as t from 'io-ts';
import { UUID } from 'io-ts-types/UUID';
import { Type } from './Type';

export const RecordSyncedC = t.type({
    type: t.literal(Type.SYNC),
    id: UUID,
});

export type RecordSynced = t.TypeOf<typeof RecordSyncedC>;
