import {
    Action, Module, Mutation, VuexModule,
} from 'vuex-module-decorators';
import Notification from '../../../scripts/helpers/Notification/Notification';

@Module({ name: 'NotificationModule' })
export default class NotificationModule extends VuexModule {
    private _notification: Notification = new Notification({
        text: '',
        show: false,
    });

    @Mutation
    private setNotification(notification: Notification): void {
        this._notification = notification;
    }

    @Action
    public promptUser(notification: Notification): void {
        this.context.commit('setNotification', notification);
    }

    @Action
    public notifyUser(text: string): void {
        const notification = new Notification({
            text, withCancel: true,
        });
        notification.setCancelButtonText('Ok');

        this.context.commit('setNotification', notification);
    }

    @Action
    public hideNotification(): void {
        this.context.commit('setNotification', new Notification({
            text: '',
            show: false,
        }));
    }

    public get notification(): Readonly<Notification> {
        return this._notification;
    }
}
