import * as t from 'io-ts';
import RequestBuilder, { Significance } from '../../helpers/Request/Builder/RequestBuilder';
import { RoutingSymfony } from '../../player/mobile/helpers/routing';
import { socket } from '../../socket';
import { Channel } from '../../../vue/composables/socket';
import { userModule } from '../../../vue/store';

const routes = {
    checkCriticallyLowSpace: 'wowza_check_critically_low_space',
    checkLowSpaceByRecord: 'wowza_check_low_space_record',
    checkLowSpaceBySchedule: 'wowza_check_low_space_schedule',
} as const;

const CheckSpaceResponseC = t.union([
    t.type({
        should_notify: t.literal(false),
    }),
    t.type({
        should_notify: t.literal(true),
        text: t.string,
        title: t.string,
    }),
]);

function checkIfCriticallyLowSpace(
    wowzaId: number,
    stoppedRecords: Array<string>,
    stoppedSchedules: Array<number>,
) {
    const url = RoutingSymfony.generate(routes.checkCriticallyLowSpace, {
        id: wowzaId,
    });

    const formData = new FormData();

    stoppedRecords.forEach((id) => {
        formData.set('stopped_records[]', id);
    });

    stoppedSchedules.forEach((id) => {
        formData.set('stopped_schedules[]', id.toString());
    });

    return new RequestBuilder(CheckSpaceResponseC)
        .setUrl(url)
        .setFormData(formData)
        .setSignificance(Significance.Invisible)
        .setMethod('POST')
        .build()
        .send();
}

const WowzaLowSpaceMessageC = t.type({
    id: t.number,
    stopped_records: t.array(t.string),
    stopped_schedules: t.array(t.number),
});

type Handler = (data: { text: string, title: string }) => void;

let handlers: Handler[] = [];

const onWowzaLowSpace = async (json: string) => {
    if (!userModule.isUserLoggedIn) {
        return;
    }

    const message = JSON.parse(json);

    if (!WowzaLowSpaceMessageC.is(message)) {
        return;
    }

    const response = await checkIfCriticallyLowSpace(
        message.id,
        message.stopped_records,
        message.stopped_schedules,
    );

    if (!response.should_notify) {
        return;
    }

    handlers.forEach((handler) => handler({ title: response.title, text: response.text }));
};

const subscribeToWowzaLowSpace = (): void => {
    socket.on(Channel.WOWZA_LOW_SPACE, onWowzaLowSpace);
};

const unsubscribeFromWowzaLowSpace = (): void => {
    socket.removeListener(Channel.WOWZA_LOW_SPACE, onWowzaLowSpace);
};

export const addOnWowzaLowSpaceHandler = (handler: Handler): void => {
    if (handlers.length === 0) {
        subscribeToWowzaLowSpace();
    }

    handlers.push(handler);
};

export const removeOnWowzaLowSpaceHanlder = (handler: Handler): void => {
    handlers = handlers.filter((h) => h !== handler);

    if (handlers.length === 0) {
        unsubscribeFromWowzaLowSpace();
    }
};

type MessageResponse = false|{title: string, message: string};

export const checkIfLowSpaceByRecord = async (recordId: string): Promise<MessageResponse> => {
    const url = RoutingSymfony.generate(routes.checkLowSpaceByRecord, {
        id: recordId,
    });

    const response = await new RequestBuilder(CheckSpaceResponseC)
        .setUrl(url)
        .setSignificance(Significance.Invisible)
        .setMethod('GET')
        .build()
        .send();

    if (!response.should_notify) {
        return false;
    }

    return {
        title: response.title,
        message: response.text,
    };
};

export const checkIfLowSpaceBySchedule = async (scheduleId: number): Promise<MessageResponse> => {
    const url = RoutingSymfony.generate(routes.checkLowSpaceBySchedule, {
        id: scheduleId,
    });

    const response = await new RequestBuilder(CheckSpaceResponseC)
        .setUrl(url)
        .setSignificance(Significance.Invisible)
        .setMethod('GET')
        .build()
        .send();

    if (!response.should_notify) {
        return false;
    }

    return {
        title: response.title,
        message: response.text,
    };
};
