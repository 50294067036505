import { VueElementConstructor } from 'vue';
import fromEnum from '../io-ts/fromEnum';
import InformationIcon from '../../images/information-bubble.svg?component';
import EvaluationIcon from '../../images/check-circle.svg?component';
import SharingIcon from '../../images/share-2.svg?component';
import AuthorIcon from '../../images/people.svg?component';
import RetentionIcon from '../../images/trash.svg?component';
import ControlIcon from '../../images/controls.svg?component';
import CalendarIcon from '../../images/calendar.svg?component';
import CameraIcon from '../../images/camera.svg?component';
import VideoIcon from '../../images/video.svg?component';

export enum SectionType {
    INFO = 'info',
    EVALUATION = 'evaluation',
    SHARING = 'sharing',
    AUTHOR = 'author',
    RETENTION = 'retention',
    CONTROL = 'control',
    ROOM_FILTER = 'room_filter',
    USER_FILTER = 'user_filter',
    DATE_FILTER = 'date_filter',
    ROOMS = 'rooms',
    RECORDS = 'records',
    USERS = 'users',
    DATE = 'date',
}

export const SectionTypeC = fromEnum('SectionType', SectionType);

export type Section = {
    type: SectionType;
    title: string;
    icon: VueElementConstructor;
}

const createSection = (
    type: SectionType,
    title: string,
    icon: VueElementConstructor,
): Section => ({ type, title, icon });

const InformationSection = createSection(SectionType.INFO, 'Information', InformationIcon);
const EvaluationSection = createSection(SectionType.EVALUATION, 'Evaluation', EvaluationIcon);
const SharingSection = createSection(SectionType.SHARING, 'Sharing', SharingIcon);
const AuthorSection = createSection(SectionType.AUTHOR, 'Author', AuthorIcon);
const RetentionSection = createSection(SectionType.RETENTION, 'Retention', RetentionIcon);
const ControlSection = createSection(SectionType.CONTROL, 'Control', ControlIcon);
const RoomFilterSection = createSection(SectionType.ROOM_FILTER, 'Rooms', CameraIcon);
const UserFilterSection = createSection(SectionType.USER_FILTER, 'Users', AuthorIcon);
const DateFilterSection = createSection(SectionType.DATE_FILTER, 'Date', CalendarIcon);
const RoomsSection = createSection(SectionType.ROOMS, 'Rooms', CameraIcon);
const RecordsSection = createSection(SectionType.RECORDS, 'Recordings', VideoIcon);
const UserSection = createSection(SectionType.USERS, 'Users', AuthorIcon);
const DateSection = createSection(SectionType.DATE, 'Date', CalendarIcon);

const sections: Section[] = [
    InformationSection,
    EvaluationSection,
    SharingSection,
    AuthorSection,
    RetentionSection,
    ControlSection,
    RoomFilterSection,
    UserFilterSection,
    DateFilterSection,
    RoomsSection,
    RecordsSection,
    UserSection,
    DateSection,
];

export default sections;
