import { RouteLocationNormalized } from 'vue-router';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { userModule } from '../store-accessor';

@Module({ name: 'IsAnonymousModule' })
export default class IsAnonymousModule extends VuexModule {
    private _isAnonymousRoute = false;

    @Mutation
    public updateRouteState(route: RouteLocationNormalized): void {
        this._isAnonymousRoute = !!route.meta?.anonymous;
    }

    public get isAnonymous(): boolean {
        return !userModule.isUserLoggedIn && this._isAnonymousRoute;
    }
}
