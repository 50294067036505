import {
    Module, Mutation, VuexModule,
} from 'vuex-module-decorators';
import { sidebarModule } from '../store-accessor';

@Module({ name: 'RoomMenuModule' })
export default class MenuModule extends VuexModule {
    private _isRoomMenuShown = true;

    private _isObserveOpen = false;

    private _isMultiChoicesEnabled = false;

    @Mutation
    public setMultiChoicesEnabled(): void {
        this._isMultiChoicesEnabled = true;
    }

    @Mutation
    public setMultiChoicesDisabled(): void {
        this._isMultiChoicesEnabled = false;
    }

    @Mutation
    public showRoomMenu(): void {
        this._isRoomMenuShown = true;
    }

    @Mutation
    public setOpenStatusForObserve(): void {
        this._isObserveOpen = true;
    }

    @Mutation
    public setCloseStatusForObserve(): void {
        this._isObserveOpen = false;
    }

    @Mutation
    public hideRoomMenu(): void {
        this._isRoomMenuShown = false;
    }

    @Mutation
    public toggleShowRoomMenu(): void {
        this._isRoomMenuShown = !this._isRoomMenuShown;
    }

    public get isMultiChoicesEnabled(): boolean {
        return this._isMultiChoicesEnabled;
    }

    public get isRoomMenuShown(): boolean {
        return this._isRoomMenuShown && !sidebarModule.hasSidebar;
    }

    public get isObserveOpen(): boolean {
        return this._isObserveOpen;
    }
}
