import Converter from '../Converter';
import {
    DurationFieldPartial,
    RawDurationField,
    RawDurationFieldC,
} from '../types/DurationField';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';
import convertForm from '../functions/convertForm';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import { assertIsNonNullable } from '../../../../typeAssertions/undefined';
import { SelectField } from '../functions/toSelectFormat';

export default class DurationConverter extends Converter<RawDurationField> {
    public canConvert(field: RawField): field is RawDurationField {
        return isType(field, RawDurationFieldC);
    }

    protected convertPartially(field: RawDurationField): DurationFieldPartial {
        const convertedForm = convertForm(field);
        assertIsNonNullable(convertedForm);

        const hours = convertedForm.fields.hours as SelectField;
        const minutes = convertedForm.fields.minutes as SelectField;
        assertIsNonNullable(hours);
        assertIsNonNullable(minutes);

        return ({
            ...convertedForm,
            fields: { hours, minutes },
            renderAs: FIELD_RENDER_TYPES.DURATION,
            maxDuration: field.maxDuration,
            readonly: field.attr?.readonly ?? false,
        });
    }
}
