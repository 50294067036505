import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import convertForm from './convertForm';
import { FormFieldPartialWithoutRenderAsC, RawFormFieldC } from '../types/FormField';
import getEvaluationProps from '../parts/getEvaluationProps';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import { assertIsNonNullable } from '../../../../typeAssertions/undefined';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';
import optional from '../../../../io-ts/optional';

export const RawEvaluationDescriptionFieldC = t.intersection([
    RawFormFieldC,
    t.type({
        attr: t.type({
            default_data: t.string,
            'data-total-value': t.union([t.string, t.number]),
        }),
    }),
]);

export const EvaluationDescriptionFieldPartialC = t.intersection([
    FormFieldPartialWithoutRenderAsC,
    t.type({
        fields: t.type({
            value: FieldCommonPropsC,
            description: FieldCommonPropsC,
        }),
        maxPoints: optional(t.number),
        renderAs: t.literal(FIELD_RENDER_TYPES.EVALUATION_DESCRIPTION),
    }),
]);

export const EvaluationDescriptionFieldC = t.intersection(
    [EvaluationDescriptionFieldPartialC, FieldCommonPropsC],
);

export type RawEvaluationDescriptionField = TypeOf<typeof RawEvaluationDescriptionFieldC>
export type EvaluationDescriptionFieldPartial = TypeOf<typeof EvaluationDescriptionFieldPartialC>
export type EvaluationDescriptionField = TypeOf<typeof EvaluationDescriptionFieldC>

export default (field: RawEvaluationDescriptionField): EvaluationDescriptionFieldPartial => {
    const converted = convertForm(field);
    assertIsNonNullable(converted);
    assertIsNonNullable(converted.fields.description);
    assertIsNonNullable(converted.fields.value);

    const description = {
        ...converted.fields.description,
        value: converted.fields.description.value || field.attr.default_data,
        title: '',
    };

    const value = {
        ...converted.fields.value,
        title: 'Enter value:',
        min: 0,
        max: parseInt(field.attr['data-total-value'].toString(), 10),
    };

    return {
        ...converted,
        fields: {
            ...converted.fields,
            description,
            value,
        },
        ...getEvaluationProps(field),
        renderAs: FIELD_RENDER_TYPES.EVALUATION_DESCRIPTION,
    };
};
