import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import toArrayFormat, {
    ArrayFieldCommonPropsC,
    RawArrayField,
    RawArrayFieldC,
} from './toArrayFormat';
import { assertIsNonNullable } from '../../../../typeAssertions/undefined';
import assertIsString from '../../../../typeAssertions/string';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';
import { FormFieldC } from '../types/FormField';

export const RawFilterInfoItemsContainerC = t.intersection([
    RawArrayFieldC,
    t.type({
        attr: t.type({
            'data-render-as-filter-info-items-container': t.literal(true),
        }),
    }),
]);

export const FilterInfoItemsContainerPartialC = t.intersection([
    ArrayFieldCommonPropsC,
    t.type({
        renderAs: t.literal(FIELD_RENDER_TYPES.FILTER_INFO_ITEMS_CONTAINER),
        itemPrototype: FormFieldC,
    }),
]);

export const FilterInfoItemsContainerC = t.intersection(
    [FilterInfoItemsContainerPartialC, FieldCommonPropsC],
);

export type RawFilterInfoItemsContainer = TypeOf<typeof RawFilterInfoItemsContainerC>;
export type FilterInfoItemsContainerPartial =
    TypeOf<typeof FilterInfoItemsContainerPartialC>;
export type FilterInfoItemsContainer = TypeOf<typeof FilterInfoItemsContainerC>;

export default (field: RawArrayField): FilterInfoItemsContainerPartial => {
    const converted = toArrayFormat(field);
    if (converted.renderAs !== FIELD_RENDER_TYPES.ARRAY_WITH_PROTOTYPE) {
        throw new Error('Incorrect field provided');
    }

    const filterText = converted.itemPrototype?.fields?.filterText;
    assertIsNonNullable(filterText);

    if (!('value' in filterText)) {
        throw new Error('Incorrect field provided');
    }
    assertIsString(filterText.value);
    filterText.value = '';

    return {
        ...converted,
        renderAs: FIELD_RENDER_TYPES.FILTER_INFO_ITEMS_CONTAINER,
    };
};
