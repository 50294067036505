import Converter from '../Converter';
import toEvaluationImage, {
    EvaluationImageFieldPartial,
    RawEvaluationImageField,
    RawEvaluationImageFieldC,
} from '../functions/toEvaluationImage';
import { RawField } from '../types/RawField';

export default class EvaluationImageFieldConverter
    extends Converter<RawEvaluationImageField> {
    public canConvert(field: RawField): field is RawEvaluationImageField {
        return RawEvaluationImageFieldC.is(field);
    }

    protected convertPartially(field: RawEvaluationImageField)
        : EvaluationImageFieldPartial {
        return toEvaluationImage(field);
    }
}
