enum DropdownVariant {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    LIGHT = 'light',
    WHITE = 'white',
    DARK = 'dark',
    MATE_GRAY = 'mate-gray'
}

export default DropdownVariant;
