// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import serversChildren from '../../../../vue/routes/admin/servers';
import { userModule } from '../../../../vue/store';
import { AdminRouteItem } from '../../../types/Admin/AdminRouteItem';

export default (): AdminRouteItem[] => serversChildren
    .filter((route) => route.meta.permissions()
        .some((permission: string) => userModule.hasPermission(permission))) as AdminRouteItem[];
