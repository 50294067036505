import * as t from 'io-ts';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

export const TimeSettingsC = t.type({
    timestamp: t.number,
    timezone: t.string,
    timezone_offset: t.string,
});
type TimeSettings = t.TypeOf<typeof TimeSettingsC>;

const getOffset = (firstTimezone: string, secondTimezone: string): number => {
    const date = new Date();
    const firstDate = new Date(date.toLocaleString('en-US', { timeZone: firstTimezone }));
    const secondDate = new Date(date.toLocaleString('en-US', { timeZone: secondTimezone }));

    return (firstDate.getTime() - secondDate.getTime()) / 1000;
};

@Module({ name: 'TimezoneModule' })
export default class TimezoneModule extends VuexModule {
    private _serverTimezone: string|null = null;

    public get serverTimezone(): string {
        if (!this._serverTimezone) {
            return this.userTimezone;
        }

        return this._serverTimezone;
    }

    public get userTimezone(): string {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    public get timezoneOffset(): number {
        return getOffset(this.serverTimezone, this.userTimezone);
    }

    @Mutation
    public configureTime({ timezone }: TimeSettings): void {
        this._serverTimezone = timezone;
    }

    @Mutation
    public setServerTimezone(timezone: string): void {
        this._serverTimezone = timezone;
    }

    @Mutation
    public resetTime(): void {
        this._serverTimezone = null;
    }
}
