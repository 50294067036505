import Converter from '../Converter';
import toStaticText, { RawStaticTextField, RawStaticTextFieldC, StaticTextFieldPartial } from '../functions/toStaticText';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';

export default class StaticTextFieldConverter extends Converter<RawStaticTextField> {
    public canConvert(field: RawField): field is RawStaticTextField {
        return isType(field, RawStaticTextFieldC);
    }

    protected convertPartially(field: RawStaticTextField): StaticTextFieldPartial {
        return toStaticText(field);
    }
}
