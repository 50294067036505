import { Theme } from '../constants/themes';

const THEMES_DIR_PATH = '/themes';

export async function setTheme(theme: Theme): Promise<void> {
    const themePath = `${THEMES_DIR_PATH}/_${theme}.css`;

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = themePath;

    document.head.querySelector(`link[href^="${THEMES_DIR_PATH}_"]`)?.remove();
    document.head.appendChild(link);
}
