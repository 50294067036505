import ROUTE_NAMES from './routeNames';

export default [
    {
        path: 'rooms/:roomId',
        name: ROUTE_NAMES.OBSERVE_ROOM,
        component: () => import('../views/Observe/Observe.vue'),
        meta: {
            title: 'Observe',
        },
        children: [
            {
                path: 'comments',
                name: ROUTE_NAMES.OBSERVE_ROOM_COMMENTS,
                component: () => import('../views/Observe/Observe.vue'),
                meta: {
                    title: 'Observe',
                },
            },
            {
                path: 'cameras/:cameraId',
                name: ROUTE_NAMES.OBSERVE_CAMERA,
                component: () => import('../views/Observe/Observe.vue'),
                meta: {
                    title: 'Observe',
                },
            },
            {
                path: 'share/:sequence',
                name: ROUTE_NAMES.OBSERVE_SHARED_ROOM,
                component: () => import('../views/Observe/Observe.vue'),
                meta: {
                    title: 'Observe',
                    anonymous: true,
                    isPtzVisible: false,
                },
                children: [
                    {
                        path: 'cameras/:cameraId',
                        name: ROUTE_NAMES.OBSERVE_SHARED_CAMERA,
                        component: () => import('../views/Observe/Observe.vue'),
                        meta: {
                            title: 'Observe',
                            anonymous: true,
                            isPtzVisible: false,
                        },
                    },
                ],
            },
        ],
    },
];
