import { BvToast } from 'bootstrap-vue';

export const VARIANT_DANGER = 'danger';
export const VARIANT_SUCCESS = 'success';

type ToastVariant = typeof VARIANT_SUCCESS | typeof VARIANT_DANGER;

/**
 * @deprecated see assets/scripts/composables/toaster.ts
 */
function makeToast(
    this: BvToast,
    title: string, variant: ToastVariant,
    message: string,
): void {
    this.toast(message, {
        title,
        solid: true,
        variant,
    });
}

/**
 * @deprecated see assets/scripts/composables/toaster.ts
 */
export function makeErrorToast(this: BvToast, message: string, title = 'Error'): void {
    makeToast.call(this, title, VARIANT_DANGER, message);
}

/**
 * @deprecated see assets/scripts/composables/toaster.ts
 */
export function makeSuccessToast(this: BvToast, message: string, title = 'Success'): void {
    makeToast.call(this, title, VARIANT_SUCCESS, message);
}

export default makeToast;
