import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import { FormFieldPartialWithoutRenderAsC, RawFormFieldC } from './FormField';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';

export const RawZoomAccountFieldC = t.intersection([
    RawFormFieldC,
    t.type({
        connectUrl: t.string,
        disconnectUrl: t.string,
        active: t.boolean,
        renderer: t.literal(FIELD_RENDER_TYPES.ZOOM_ACCOUNT),
    }),
]);

export const ZoomAccountFieldPartialC = t.intersection([
    FormFieldPartialWithoutRenderAsC,
    t.type({
        // TODO add Email field to type
        connectUrl: t.string,
        disconnectUrl: t.string,
        active: t.boolean,
        renderAs: t.literal(FIELD_RENDER_TYPES.ZOOM_ACCOUNT),
    }),
]);

export const ZoomAccountFieldC = t.intersection([ZoomAccountFieldPartialC, FieldCommonPropsC]);

export type RawZoomAccountField = TypeOf<typeof RawZoomAccountFieldC>;
export type ZoomAccountFieldPartial = TypeOf<typeof ZoomAccountFieldPartialC>;
export type ZoomAccountField = TypeOf<typeof ZoomAccountFieldC> &
    { fields: { email: { value: string } } };
