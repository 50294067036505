import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import Converter from '../Converter';
import { RawField } from '../types/RawField';
import isType from '../../../../io-ts/isType';

export const RawHiddenFieldC = t.type({
    title: t.union([t.string, t.null]),
    name: t.string,
    type: t.literal('hidden'),
});

export type RawHiddenField = TypeOf<typeof RawHiddenFieldC>

export default class HiddenFieldConverter extends Converter<RawHiddenField> {
    public canConvert(field: RawField): field is RawHiddenField {
        return isType(field, RawHiddenFieldC);
    }

    protected convertPartially(): null {
        return null;
    }
}
