import isType from '../../../../io-ts/isType';
import Converter from '../Converter';
import { RawField } from '../types/RawField';
import toMultipleChoice, {
    MultipleChoicePartial,
    RawMultipleChoice,
    RawMultipleChoiceC,
} from '../functions/toMultipleChoice';

export default class MultipleChoiceConverter extends Converter<RawMultipleChoice> {
    public canConvert(field: RawField): field is RawMultipleChoice {
        return isType(field, RawMultipleChoiceC);
    }

    protected convertPartially(field: RawMultipleChoice): MultipleChoicePartial | null {
        return toMultipleChoice(field);
    }
}
