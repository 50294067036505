import * as t from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';
import { FormFieldPartialWithoutRenderAsC, RawFormFieldC } from './FormField';

export const RawDirectDateRangeC = t.intersection([
    RawFormFieldC,
    t.type({
        renderer: t.literal(FIELD_RENDER_TYPES.DIRECT_DATE_RANGE),
    }),
]);
export type RawDirectDateRange = t.TypeOf<typeof RawDirectDateRangeC>;

export const DirectDateRangePartialC = t.intersection([
    FormFieldPartialWithoutRenderAsC,
    t.type({
        renderAs: t.literal(FIELD_RENDER_TYPES.DIRECT_DATE_RANGE),
    }),
]);
export type DirectDateRangePartial = t.TypeOf<typeof DirectDateRangePartialC>;

export const DirectDateRangeC = t.intersection([
    DirectDateRangePartialC,
    FieldCommonPropsC,
]);
export type DirectDateRange = t.TypeOf<typeof DirectDateRangeC>;
