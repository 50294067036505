import QuestionBubbleIcon from '../../../images/question-bubble.svg?component';
import ROUTE_NAMES from '../routeNames';
import SECTION_NAMES from '../sectionNames';
import { permissionsModule as pM } from '../../store';

const meta = {
    title: SECTION_NAMES.HELP,
    icon: QuestionBubbleIcon,
    permissions: (): string[] => [pM.allPermissions.adminHelp],
};

export default [
    {
        path: '',
        name: ROUTE_NAMES.ADMIN_HELP_LIST,
        component: (): Promise<unknown> => import('../../views/admin/AdminHelp/AdminHelpList.vue'),
        meta,
    },
    {
        path: 'edit/:id',
        name: ROUTE_NAMES.ADMIN_HELP_VIEW,
        component: (): Promise<unknown> => import('../../views/admin/AdminHelp/AdminHelpForm.vue'),
        meta,
    },
    {
        path: 'new',
        name: ROUTE_NAMES.ADMIN_HELP_NEW,
        component: (): Promise<unknown> => import('../../views/admin/AdminHelp/AdminHelpForm.vue'),
        meta,
    },
];
