import * as t from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import {
    FormFieldPartialWithoutRenderAsC,
    RawFormFieldC,
} from './FormField';
import { SelectFieldC } from '../functions/toSelectFormat';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';

export const RawDurationFieldC = t.intersection([
    RawFormFieldC,
    t.type({
        renderer: t.literal(FIELD_RENDER_TYPES.DURATION),
        maxDuration: t.union([t.number, t.null]),
    }),
    t.partial({
        attr: t.partial({
            readonly: t.boolean,
        }),
    }),
]);

export const DurationFieldsC = t.type({
    hours: SelectFieldC,
    minutes: SelectFieldC,
});

export const DurationFieldPartialC = t.intersection([
    FormFieldPartialWithoutRenderAsC,
    t.type({
        renderAs: t.literal(FIELD_RENDER_TYPES.DURATION),
        fields: DurationFieldsC,
        maxDuration: t.union([t.number, t.null]),
        readonly: t.boolean,
    }),
]);

export const DurationFieldC = t.intersection([DurationFieldPartialC, FieldCommonPropsC]);

export type RawDurationField = t.TypeOf<typeof RawDurationFieldC>;
export type DurationFieldPartial = t.TypeOf<typeof DurationFieldPartialC>;
export type DurationField = t.TypeOf<typeof DurationFieldC>;
