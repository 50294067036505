import { isRight } from 'fp-ts/Either';
import { Any, TypeOf } from 'io-ts';

/**
 * Don't use TypeC.is because it requires properties that has type undefined
 * @see https://github.com/gcanti/io-ts/pull/266#issuecomment-474935329
* */
export default function isType<T extends Any>(checkedValue: unknown, type: T)
    : checkedValue is TypeOf<T> {
    return isRight(type.decode(checkedValue));
}
