import Converter from '../Converter';
import toEvaluationDescription, {
    EvaluationDescriptionFieldPartial,
    RawEvaluationDescriptionField,
    RawEvaluationDescriptionFieldC,
} from '../functions/toEvaluationDescription';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';

export default class EvaluationDescriptionFieldConverter
    extends Converter<RawEvaluationDescriptionField> {
    public canConvert(field: RawField): field is RawEvaluationDescriptionField {
        return isType(field, RawEvaluationDescriptionFieldC);
    }

    protected convertPartially(field: RawEvaluationDescriptionField)
        : EvaluationDescriptionFieldPartial {
        return toEvaluationDescription(field);
    }
}
