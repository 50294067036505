/* eslint-disable no-param-reassign */
/* required for decorator factory */

// based on https://github.com/vuejs/vue-class-component/issues/56#issuecomment-272604193
import { createDecorator, VueDecorator, Vue } from 'vue-class-component';
import { ComponentOptions } from 'vue';
import store, { StoreGetterFromTypedModules } from '../index';

// Autocomplete does not work otherwise
type GetterNameAsTuple = [StoreGetterFromTypedModules];

export default function Getter(getterNameAsTuple: GetterNameAsTuple): VueDecorator {
    const factory = (options: ComponentOptions<Vue>, key: string) => {
        if (!options.computed) {
            options.computed = {};
        }
        const getterName = getterNameAsTuple[0];
        options.computed[key] = () => store.getters[getterName];
    };
    return createDecorator(factory);
}
