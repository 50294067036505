import * as t from 'io-ts';
import { Class } from 'type-fest';
/**
 * @see https://github.com/gcanti/io-ts/issues/272#issuecomment-537880142
 * */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function instanceOf<C extends Class<any>>(C: C): t.Type<InstanceType<C>> {
    return new t.Type<InstanceType<C>>(
        `instanceOf(${C.name})`,
        (v): v is InstanceType<C> => v instanceof C,
        (i, c) => (i instanceof C ? t.success<InstanceType<C>>(i) : t.failure(i, c)),
        (v) => v,
    );
}
