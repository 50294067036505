import Converter from '../Converter';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';
import { AvatarFieldPartial, RawAvatarField, RawAvatarFieldC } from '../types/Avatar';
import toAvatar from '../functions/toAvatar';

export default class AvatarConverter extends Converter<RawAvatarField> {
    public canConvert(field: RawField): field is RawAvatarField {
        return isType(field, RawAvatarFieldC);
    }

    protected convertPartially(field: RawAvatarField): AvatarFieldPartial {
        return toAvatar(field);
    }
}
