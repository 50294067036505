import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import getChoiceMultipleExpandedRenderType, {
    CHOICE_MULTIPLE_RENDER_TYPE,
    ChoiceMultipleExpandedField,
    ChoiceMultipleExpandedFieldC,
} from '../parts/getChoiceMultipleExpandedRenderType';
import getEvaluationProps, { RawEvaluationFieldC } from '../parts/getEvaluationProps';
import isType from '../../../../io-ts/isType';
import assertAllElementsAreDefined from '../../../../typeAssertions/undefinedInArray';
import OneOf from '../../../../io-ts/OneOf';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';
import { ChoiceValueC } from './toSelectFormat';

const RENDER_TYPE = [
    ...CHOICE_MULTIPLE_RENDER_TYPE,
    FIELD_RENDER_TYPES.MULTI_SELECT,
    FIELD_RENDER_TYPES.MULTI_SELECT_WITH_LABELS_BELOW,
    FIELD_RENDER_TYPES.MULTI_SELECT_CHECKBOXES,
] as const;

const itemsC = t.intersection([
    t.type({
        enum: t.array(t.string),
        enum_titles: t.array(t.string),
    }),
    t.partial({
        enum_additional_info: t.array(t.number),
        enum_colors: t.array(t.string),
        enum_tooltips: t.array(t.string),
        maxItems: t.Int,
        minItems: t.Int,
    }),
]);

export const RawMultiSelectFieldC = t.intersection([
    RawEvaluationFieldC,
    t.type({
        title: t.union([t.string, t.null]),
        name: t.string,
        items: itemsC,
        default: t.array(t.string),
        type: t.literal('array'),
    }),
    t.partial({
        attr: t.partial({
            placeholder: t.string,
            'data-render-with-labels-below': t.literal(true),
            'data-render-as-tree': t.literal(true),
            'view-type': t.literal('checkbox'),
        }),
    }),
]);

export const MultiSelectChoiceC = t.intersection([
    t.type({
        value: ChoiceValueC,
        title: t.string,
    }),
    t.partial({
        selected: t.boolean,
        evaluationPoints: t.union([t.number, t.null]),
        isNullChoice: t.boolean,
        color: t.string,
        tooltip: t.string,
    }),
]);

export const MultiSelectFieldPartialWithoutRenderAsC = t.intersection([
    t.type({
        choices: t.array(MultiSelectChoiceC),
        selectedChoices: t.array(MultiSelectChoiceC),
    }),
    t.partial({
        placeholder: t.string,
    }),
]);

export const MultiSelectFieldPartialC = t.intersection([
    MultiSelectFieldPartialWithoutRenderAsC,
    t.type({
        renderAs: OneOf(RENDER_TYPE),
    }),
]);

export const MultiSelectFieldC = t.intersection([MultiSelectFieldPartialC, FieldCommonPropsC]);

export type RawMultiSelectField = TypeOf<typeof RawMultiSelectFieldC>
export type MultiSelectChoice = TypeOf<typeof MultiSelectChoiceC>
export type MultiSelectFieldPartial = TypeOf<typeof MultiSelectFieldPartialC>

/**
 * @deprecated MultipleChoice
 */
export type MultiSelectField = TypeOf<typeof MultiSelectFieldC>

/**
 * @deprecated toMultipleChoice instead
 */
function convert(field: RawMultiSelectField): MultiSelectFieldPartial {
    let renderAs = FIELD_RENDER_TYPES.MULTI_SELECT;

    if (isType(field, ChoiceMultipleExpandedFieldC)) {
        renderAs = getChoiceMultipleExpandedRenderType(field as ChoiceMultipleExpandedField);
    }

    const withEnumAfterInput = field.attr?.['data-render-with-labels-below'] || false;
    if (withEnumAfterInput) {
        renderAs = FIELD_RENDER_TYPES.MULTI_SELECT_WITH_LABELS_BELOW;
    } else if (field.attr?.['view-type'] === 'checkbox') {
        renderAs = FIELD_RENDER_TYPES.MULTI_SELECT_CHECKBOXES;
    }

    const placeholder = field.attr?.placeholder;
    const choices = field.items.enum.map((val, i) => ({
        value: val,
        title: field.items.enum_titles[i],
        selected: field.default ? field.default.includes(val) : false,
        evaluationPoints: field.items.enum_additional_info?.[i] || null,
        disabled: false,
        color: field.items.enum_colors?.[i],
        tooltip: field.items.enum_tooltips?.[i],
    }));

    const selectedChoices = field.default
        .map((defaultValue) => choices.find((choice) => choice.value === defaultValue));
    assertAllElementsAreDefined(selectedChoices);

    return {
        renderAs,
        choices,
        selectedChoices,
        placeholder,
        ...getEvaluationProps(field),
    };
}

export default convert;
