// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { isString } from 'lodash';
import VueModule from 'vue';
import { AxiosError } from 'axios';
import { notificationModule } from '../../vue/store';
import Notification from '../helpers/Notification/Notification';
import ROUTE_NAMES from '../../vue/routes/routeNames';
import router from '../../vue/router';

const DEFAULT_ERROR_MESSAGE = 'Something went wrong :(\nPlease reload the page and try again';
const MAX_MESSAGE_LENGTH = 500;

export type AxiosErrorWithResponse = AxiosError<{message?: string}>;

export const defaultAjaxErrorHandlerFunction = (error: AxiosErrorWithResponse): void => {
    const isCurrentRouteLogin = router.currentRoute.name === ROUTE_NAMES.LOGIN_MAIN;
    const is403 = error.response?.status === 403;
    if (is403 && isCurrentRouteLogin) {
        return;
    }
    const data = error.response?.data;
    const responseMessage = data?.message || data || '';

    const errorMessage = isString(responseMessage)
    && responseMessage.length <= MAX_MESSAGE_LENGTH ? responseMessage : DEFAULT_ERROR_MESSAGE;

    const notification = new Notification({
        text: 'An error occurred',
        warning: errorMessage,
        withCancel: false,
    });
    notificationModule.promptUser(notification);
};

export const defaultAjaxErrorHandler = VueModule.extend({
    methods: {
        defaultAjaxErrorHandler: defaultAjaxErrorHandlerFunction,
    },
});

export default defaultAjaxErrorHandler;
