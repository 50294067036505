import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';
import optional from '../../../../io-ts/optional';

export const RawAudioFieldC = t.type({
    title: t.union([t.string, t.null]),
    name: t.string,
    default: optional(t.string),
    attr: t.type({
        'data-uid': t.string,
        maxLength: t.union([t.number, t.null]),
    }),
    type: t.literal('string'),
});

export const AudioFieldPartialC = t.type({
    renderAs: t.literal(FIELD_RENDER_TYPES.AUDIO),
    value: t.string,
    uid: t.string,
    maxLength: t.union([t.number, t.null, t.undefined]),
});

export const AudioFieldC = t.intersection([AudioFieldPartialC, FieldCommonPropsC]);

export type RawAudioField = TypeOf<typeof RawAudioFieldC>
export type AudioFieldPartial = TypeOf<typeof AudioFieldPartialC>
export type AudioField = TypeOf<typeof AudioFieldC>

export default (field: RawAudioField): AudioFieldPartial => ({
    value: field.default || '',
    renderAs: FIELD_RENDER_TYPES.AUDIO,
    uid: field.attr['data-uid'],
    maxLength: field.attr?.maxLength ?? null,
});
