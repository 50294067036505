import * as t from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';
import { FormFieldPartialWithoutRenderAsC, RawFormFieldC } from './FormField';

export const RawDateRangeC = t.intersection([
    RawFormFieldC,
    t.type({
        renderer: t.literal(FIELD_RENDER_TYPES.DATE_RANGE),
    }),
]);
export type RawDateRange = t.TypeOf<typeof RawDateRangeC>;

export const DateRangePartialC = t.intersection([
    FormFieldPartialWithoutRenderAsC,
    t.type({
        renderAs: t.literal(FIELD_RENDER_TYPES.DATE_RANGE),
    }),
]);
export type DateRangePartial = t.TypeOf<typeof DateRangePartialC>;

export const DateRangeC = t.intersection([
    DateRangePartialC,
    FieldCommonPropsC,
]);
export type DateRange = t.TypeOf<typeof DateRangeC>;
