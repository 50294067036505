/**
 * @see src/CamBundle/Message/Websocket/Record/RecordConverting.php
 */

import * as t from 'io-ts';
import { UUID } from 'io-ts-types/UUID';
import { Type } from './Type';

export const RecordConvertingC = t.type({
    type: t.literal(Type.CONVERTING),
    id: UUID,
});

export type RecordConverting = t.TypeOf<typeof RecordConvertingC>;
