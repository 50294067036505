import { onMounted, onUnmounted } from 'vue';
import ClickOutsideListener, { Target } from '../../scripts/helpers/listeners/ClickOutsideListener';
import { Listener } from '../../scripts/helpers/listeners/ListenerProvider';

export const useClickOutsideListener = (targets: Target[], handler: Listener): void => {
    const listener = new ClickOutsideListener(targets, handler);

    onMounted(() => {
        listener.registerListener();
    });

    onUnmounted(() => {
        listener.unregisterListener();
    });
};
