import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class Element extends Vue {
    @Prop({ type: String })
    public readonly id!: string;

    @Prop({ type: String, default: null })
    public readonly cypressId!: string | null;
}
