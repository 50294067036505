<template>
    <NotificationCard
        :location="location"
        :notification="notification"
        :viewed="notification.isViewed"
        @reload-notifications="$emit('reloadNotifications')"
    >
        <template #notification-icon>
            <ShareIcon class="icon" />
        </template>
        <template #notification-header>
            {{ notification.senderName }} has shared recording:
        </template>
        <template #notification-text>
            <div class="message-item">
                <span class="text-truncate">
                    {{ notification.message }}
                </span>
            </div>
        </template>
    </NotificationCard>
</template>

<script lang="ts" setup>
import { RouteLocationRaw } from 'vue-router';
import { computed } from 'vue';
import NotificationCard from '../NotificationCard.vue';
import {
    NotificationItem,
} from '../../../../scripts/types/Notification';
import ShareIcon from '../../../../images/share-2.svg?component';
import ROUTE_NAMES from '../../../routes/routeNames';

type Props = {
    id: string,
    cypressId: string | null,
    notification: NotificationItem,
}

type Emits = {
    (e: 'reloadNotifications'): void,
}

const props = withDefaults(defineProps<Props>(), {
    cypressId: null,
});

defineEmits<Emits>();

const location = computed<RouteLocationRaw>(() => ({
    name: ROUTE_NAMES.REVIEW_RECORD_SHOW,
    params: { recordId: props.notification.recordingId ?? '' },
}));
</script>

<style scoped>

.icon {
    fill: currentColor;
}

</style>
