import Converter from '../Converter';
import toDateType, { DateFieldPartial, RawDateField, RawDateFieldC } from '../functions/toDateType';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';

export default class DateFieldConverter extends Converter<RawDateField> {
    public canConvert(field: RawField): field is RawDateField {
        return isType(field, RawDateFieldC);
    }

    protected convertPartially(field: RawDateField): DateFieldPartial {
        return toDateType(field);
    }
}
