import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import convertForm from './convertForm';
import { assertIsNonNullable } from '../../../../typeAssertions/undefined';
import { PresetsControlPartial, RawPresetsControl } from '../types/PresetsControl';

export default (field: RawPresetsControl): PresetsControlPartial => {
    const convertedForm = convertForm(field);
    assertIsNonNullable(convertedForm);

    return ({
        ...convertedForm,
        renderAs: FIELD_RENDER_TYPES.PRESETS_CONTROL,
    });
};
