import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import convertForm from './convertForm';
import { FormFieldPartialWithoutRenderAsC, RawFormFieldC } from '../types/FormField';
import getEvaluationProps from '../parts/getEvaluationProps';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import { assertIsNonNullable } from '../../../../typeAssertions/undefined';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';
import optional from '../../../../io-ts/optional';

export const RawEvaluationImageFieldC = t.intersection([
    RawFormFieldC,
    t.type({
        attr: t.type({
            // default_data: t.string,
            'data-total-value': t.union([t.string, t.number]),
        }),
    }),
]);

export const EvaluationImageFieldPartialC = t.intersection([
    FormFieldPartialWithoutRenderAsC,
    t.type({
        fields: t.type({
            value: FieldCommonPropsC,
            image: FieldCommonPropsC,
        }),
        maxPoints: optional(t.number),
        renderAs: t.literal(FIELD_RENDER_TYPES.EVALUATION_IMAGE),
    }),
]);

export const EvaluationImageFieldC = t.intersection(
    [EvaluationImageFieldPartialC, FieldCommonPropsC],
);

export type RawEvaluationImageField = TypeOf<typeof RawEvaluationImageFieldC>
export type EvaluationImageFieldPartial = TypeOf<typeof EvaluationImageFieldPartialC>
export type EvaluationImageField = TypeOf<typeof EvaluationImageFieldC>

export default (field: RawEvaluationImageField): EvaluationImageFieldPartial => {
    const converted = convertForm(field);
    assertIsNonNullable(converted);
    assertIsNonNullable(converted.fields.image);
    assertIsNonNullable(converted.fields.value);

    const image = {
        ...converted.fields.image,
        title: '',
    };

    const value = {
        ...converted.fields.value,
        title: 'Enter value:',
        min: 0,
        max: parseInt(field.attr['data-total-value'].toString(), 10),
    };

    return {
        ...converted,
        fields: {
            ...converted.fields,
            image,
            value,
        },
        ...getEvaluationProps(field),
        renderAs: FIELD_RENDER_TYPES.EVALUATION_IMAGE,
    };
};
