import Converter from '../Converter';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';
import toDateFilter, { RawDateFilter, RawDateFilterC, RawDateFilterPartial } from '../functions/toDateFilter';

export default class DateFilterConverter extends Converter<RawDateFilter> {
    public canConvert(field: RawField): field is RawDateFilter {
        return isType(field, RawDateFilterC);
    }

    protected convertPartially(field: RawDateFilter): RawDateFilterPartial {
        return toDateFilter(field);
    }
}
