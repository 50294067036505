<template>
    <NotificationCard
        :location="location"
        :notification="notification"
        :viewed="notification.isViewed"
        @reload-notifications="$emit('reloadNotifications')"
    >
        <template #notification-icon>
            <ReplyIcon class="icon" />
        </template>
        <template #notification-header>
            {{ notificationHeader }}
        </template>
        <template #notification-text>
            <div class="message-item">
                <Component
                    :is="notificationMessageItem.icon"
                    v-if="notificationMessageItem.icon"
                    class="icon"
                />
                <span class="text-truncate">
                    {{ formatCommentMessage(notificationMessageItem.text) }}
                </span>
            </div>
        </template>
    </NotificationCard>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import NotificationCard from '../NotificationCard.vue';
import type { NotificationItem } from '../../../../scripts/types/Notification';
import ReplyIcon from '../../../../images/reply.svg?component';
import MicrophoneIcon from '../../../../images/microphone.svg?component';
import AttachIcon from '../../../../images/attach.svg?component';
import ROUTE_NAMES from '../../../routes/routeNames';
import type { NotificationMessageItem } from '../types/NotificationMessageItem';
import {
    FILE_NOTIFICATION_MESSAGE,
    AUDIO_NOTIFICATION_MESSAGE,
    TEMPLATE_NOTIFICATION_MESSAGE,
} from '../../../../scripts/constants/notification';
import { formatCommentMessage } from '../../Comments/models/formatCommentMessage';

type Emits = {
    (e: 'reloadNotifications'): void,
};

type Props = {
    id: string,
    cypressId: string | null,
    notification: NotificationItem,
};

defineEmits<Emits>();

const props = withDefaults(defineProps<Props>(), {
    cypressId: null,
});

const notificationMessageItem = computed<NotificationMessageItem>(() => {
    const { message } = props.notification;
    if (message === AUDIO_NOTIFICATION_MESSAGE) {
        return { text: 'Audio message', icon: MicrophoneIcon } as NotificationMessageItem;
    }
    if (message && message.indexOf(FILE_NOTIFICATION_MESSAGE) > -1) {
        return { text: message.replace(FILE_NOTIFICATION_MESSAGE, ''), icon: AttachIcon } as NotificationMessageItem;
    }
    if (message?.startsWith(TEMPLATE_NOTIFICATION_MESSAGE)) {
        return { text: message.replaceAll(TEMPLATE_NOTIFICATION_MESSAGE, '') } as NotificationMessageItem;
    }

    return { text: message } as NotificationMessageItem;
});

const notificationHeader = computed(() => {
    const { message } = props.notification;
    let suffix = '';

    if (message === AUDIO_NOTIFICATION_MESSAGE) {
        suffix = ' with audio';
    }
    if (message && message?.indexOf(FILE_NOTIFICATION_MESSAGE) > -1) {
        suffix = ' with attachment';
    }
    if (message?.startsWith(TEMPLATE_NOTIFICATION_MESSAGE)) {
        suffix = ' with template';
    }

    return `${props.notification.senderName} replied${suffix} to your comment in ${props.notification.recordingName}:`;
});

const location = computed<RouteLocationRaw>(() => {
    const commentId = props.notification.commentId?.toString() ?? '';

    return {
        name: ROUTE_NAMES.REVIEW_RECORD_SHOW_COMMENT,
        params: { recordId: props.notification.recordingId ?? '', commentId },
    };
});
</script>

<style scoped>

.icon {
    fill: currentColor;
}

</style>
