import CameraIcon from '../../../../images/camera.svg?component';
import ROUTE_NAMES from '../../routeNames';
import SECTION_NAMES from '../../sectionNames';
import { permissionsModule as pM } from '../../../store';

const meta = {
    title: SECTION_NAMES.ROOMS_AND_CAMERAS,
    permissions: (): string[] => [pM.allPermissions.adminRooms],
    icon: CameraIcon,
};

const AdminDeviceView = (): unknown => import('../../../views/admin/AdminCamera/AdminDeviceView.vue');

export default [
    {
        path: 'camera/new/device',
        name: ROUTE_NAMES.ADMIN_DEVICE_NEW,
        component: AdminDeviceView,
        meta: { new: true, ...meta },
    },
    {
        path: 'camera/edit/device/:id',
        name: ROUTE_NAMES.ADMIN_DEVICE_VIEW,
        component: AdminDeviceView,
        meta: { new: false, ...meta },
    },
];
