<template>
    <Header
        :title="title"
        :cypress-id="cypressId"
        :level="1"
        :bordered="bordered"
        :left-slot-class="leftSlotClass"
        :with-tooltip="withTooltip"
        variant="primary"
    >
        <template
            v-if="hasBeforeTitleContentSlot"
            #before-title-content
        >
            <slot name="before-title-content" />
        </template>
        <template
            v-if="hasTitleIconSlot"
            #left-slot
        >
            <template v-if="isLgOrMore">
                <span
                    class="title-icon"
                >
                    <slot name="title-icon" />
                </span>
            </template>
            <Hamburger v-else />
        </template>
        <template #after-title-content>
            <slot name="after-title-content" />
        </template>

        <template
            v-if="hasNavigationSlot"
            #navigation
        >
            <slot name="navigation" />
        </template>

        <template #right-slot>
            <slot
                v-if="isLgOrMore"
                name="right-slot-desktop"
            />
            <slot
                v-else
                name="right-slot-mobile"
            />
        </template>
    </Header>
</template>

<script lang="ts" setup>
import { computed, useSlots } from 'vue';
import Hamburger from '../Menu/Hamburger.vue';
import Header from './Header.vue';
import { mediaQueriesModule } from '../../store';

type Props = {
    title: string,
    cypressId?: string | null,
    withTooltip?: boolean,
    bordered?: boolean,
    leftSlotClass?: string | null,

}

withDefaults(defineProps<Props>(), {
    cypressId: null,
    withTooltip: false,
    bordered: true,
    leftSlotClass: null,
});

const slots = useSlots();

const isLgOrMore = computed(() => mediaQueriesModule.isLgOrMore);
const hasTitleIconSlot = computed(() => !!slots['title-icon']);
const hasNavigationSlot = computed(() => !!slots.navigation);
const hasBeforeTitleContentSlot = computed(() => !!slots['before-title-content']);

</script>

<style lang="scss" scoped>

.title-icon:deep svg {
    width: 1.25rem;
    height: 1.25rem;

    fill: currentColor;
}

</style>
