export const HOME_ROUTE_PATH = '/';

enum ROUTE_NAMES {
    LOGIN_MAIN = 'login',
    LOGIN_FULL = 'loginFull',
    LOGIN_SSO = 'loginSso',
    HOME = 'home',
    /* observe */
    OBSERVE_MAIN = 'observe',
    OBSERVE_ROOM = 'observeRoom',
    OBSERVE_CAMERA = 'observeCamera',
    OBSERVE_SHARED_ROOM = 'observeSharedRoom',
    OBSERVE_SHARED_CAMERA = 'observeSharedCamera',
    OBSERVE_ROOM_COMMENTS = 'observeRoomComments',
    /* review */
    REVIEW_MAIN = 'review',
    REVIEW_LIST = 'reviewList',
    REVIEW_THUMBNAILS = 'reviewThumbnails',
    REVIEW_RECORD_SHOW = 'recordShow',
    REVIEW_RECORD_SHOW_COMMENT = 'recordShowComment',
    REVIEW_RECORD_SHOW_CAMERA = 'recordShowCamera',
    REVIEW_RECORD_SHOW_CAMERA_COMMENT = 'recordShowCameraComment',
    REVIEW_RECORD_SHARED_SHOW = 'recordSharedShow',
    REVIEW_RECORD_SHARED_SHOW_COMMENT = 'recordSharedShowComment',
    REVIEW_RECORD_SHARED_SHOW_CAMERA = 'recordSharedShowCamera',
    REVIEW_RECORD_SHARED_SHOW_CAMERA_COMMENT = 'recordSharedShowCameraComment',
    /* alerts */
    ALERTS = 'alerts',
    /* schedule */
    SCHEDULE_MAIN = 'schedule',
    SCHEDULE_LIST = 'scheduleList',
    SCHEDULE_CALENDAR = 'scheduleCalendar',
    /* reports */
    REPORT_ROOT = 'report',
    REPORT_LIST = 'reportList',
    REPORT_TILES = 'reportTiles',
    REPORT_VIEW = 'reportView',
    /* admin */
    ADMIN_MAIN = 'admin',
    ADMIN_SERVERS = 'adminServers',
    ADMIN_GENERAL = 'adminGeneral',
    /* adminUser */
    ADMIN_USER = 'adminUser',
    ADMIN_USER_LIST = 'adminUserList',
    ADMIN_USER_VIEW = 'adminUserView',
    ADMIN_USER_NEW = 'adminUserNew',
    ADMIN_GROUP_LIST = 'adminGroupList',
    ADMIN_GROUP_VIEW = 'adminGroupView',
    ADMIN_GROUP_NEW = 'adminGroupNew',
    ADMIN_LDAP = 'adminLdap',
    ADMIN_ADD_LDAP_SERVER = 'adminAddLdapServer',
    ADMIN_EDIT_LDAP_SERVER = 'adminEditLdapServer',
    ADMIN_ADD_LDAP_SYNC = 'adminAddLdapSync',
    ADMIN_EDIT_LDAP_SYNC = 'adminEditLdapSync',
    ADMIN_SSO = 'adminSso',
    /* adminTemplate */
    ADMIN_TEMPLATE = 'adminTemplate',
    ADMIN_TEMPLATE_LIST = 'adminTemplateList',
    ADMIN_TEMPLATE_VIEW = 'adminTemplateView',
    ADMIN_TEMPLATE_NEW = 'adminTemplateNew',
    ADMIN_EMAIL_TEMPLATE_VIEW = 'adminEmailTemplateView',
    ADMIN_EMAIL_TEMPLATE_NEW = 'adminEmailTemplateNew',
    /* adminCameraAndRoom */
    ADMIN_CAMERA = 'adminCamera',
    ADMIN_CAMERA_LIST = 'adminCameraList',
    ADMIN_CAMERA_NEW = 'adminCameraNew',
    ADMIN_CAMERA_VIEW = 'adminCameraView',
    ADMIN_ROOM_NEW = 'adminRoomNew',
    ADMIN_ROOM_VIEW = 'adminRoomView',
    ADMIN_DEVICE_NEW = 'adminDeviceNew',
    ADMIN_DEVICE_VIEW = 'adminDeviceView',
    ADMIN_STREAM_NEW = 'adminStreamNew',
    ADMIN_STREAM_VIEW = 'adminStreamView',
    ADMIN_ACAP_CAMERA_NEW = 'adminAcapCameraNew',
    ADMIN_ACAP_CAMERA_VIEW = 'adminAcapCameraView',
    /* adminContainer */
    ADMIN_CONTAINER = 'adminContainer',
    ADMIN_CONTAINER_LIST = 'adminContainerList',
    ADMIN_CONTAINER_VIEW = 'adminContainerView',
    ADMIN_CONTAINER_NEW = 'adminContainerNew',
    /* adminWowza */
    ADMIN_MEDIA_SERVER = 'adminMediaServers',
    ADMIN_MEDIA_SERVER_LIST= 'adminMediaServerList',
    ADMIN_MEDIA_SERVER_VIEW = 'adminMediaServerView',
    ADMIN_MEDIA_SERVER_NEW = 'adminMediaServerNew',
    // LDAP servers
    ADMIN_LDAP_SERVER_LIST = 'adminLdapServerList',
    ADMIN_LDAP_SERVER = 'adminLdapServer',
    ADMIN_LDAP_SERVER_VIEW = 'adminLdapServerView',
    ADMIN_LDAP_SERVER_NEW = 'adminLdapServerNew',
    /* SSO configs */
    ADMIN_SSO_CONFIG_LIST = 'adminSsoConfigList',
    ADMIN_SSO_CONFIG = 'adminSsoConfig',
    ADMIN_SSO_CONFIG_VIEW = 'adminSsoConfigView',
    ADMIN_SSO_CONFIG_NEW = 'adminSsoConfigNew',
    /* adminTranscribe */
    ADMIN_TRANSCRIPTION_SERVICE = 'adminTranscriptionService',
    ADMIN_TRANSCRIPTION_SERVICE_LIST= 'adminTranscriptionServiceList',
    ADMIN_TRANSCRIPTION_SERVICE_VIEW = 'adminTranscriptionServiceView',
    ADMIN_TRANSCRIPTION_SERVICE_NEW = 'adminTranscriptionServiceNew',
    /* adminLog */
    ADMIN_LOG_MAIN = 'adminLogMain',
    ADMIN_LOG_LOGIN = 'adminLogLogin',
    ADMIN_LOG_OBSERVE = 'adminLogObserve',
    ADMIN_LOG_RECORDING = 'adminLogRecording',
    ADMIN_LOG_REVIEW = 'adminLogReview',
    ADMIN_LOG_SCHEDULE = 'adminLogSchedule',
    ADMIN_LOG_REPORTS = 'adminLogReports',
    ADMIN_LOG_UPLOAD = 'adminLogUpload',
    ADMIN_LOG_ADMIN = 'adminLogAdmin',
    ADMIN_LOG_ALERTS = 'adminLogAlerts',
    /* adminHelp */
    ADMIN_HELP = 'adminHelp',
    ADMIN_HELP_LIST= 'adminHelpList',
    ADMIN_HELP_VIEW = 'adminHelpView',
    ADMIN_HELP_NEW = 'adminHelpNew',
    /* user settings */
    USER_SETTINGS = 'user_settings',
    NOTIFICATIONS = 'notifications',
    NOTIFICATIONS_ALL = 'notificationsAll',
    NOTIFICATIONS_REPLY = 'notificationsReply',
    NOTIFICATIONS_TAG = 'notificationsTag',
    NOTIFICATIONS_SHARING = 'notificationsSharing',
    HELP = 'help',
    LOGOUT = 'logout',
    ERROR_PAGE = 'error_page',
}

export default ROUTE_NAMES;
