import Converter from '../Converter';
import toGridSize from '../functions/toGridSize';
import {
    GridSizeField,
    RawGridSizeField,
    RawGridSizeFieldC,
} from '../types/GridSizeField';
import { RawField } from '../types/RawField';
import isType from '../../../../io-ts/isType';

export default class GridSizeConverter extends Converter<RawGridSizeField> {
    public canConvert(field: RawField): field is RawGridSizeField {
        return isType(field, RawGridSizeFieldC);
    }

    protected convertPartially(field: RawGridSizeField): GridSizeField {
        return toGridSize(field);
    }
}
