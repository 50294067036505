import isType from '../../../../io-ts/isType';
import Converter from '../Converter';
import toDirectDateRange from '../functions/toDirectDateRange';
import { RawDirectDateRange, RawDirectDateRangeC, DirectDateRangePartial } from '../types/DirectDateRange';
import { RawField } from '../types/RawField';

export default class DirectDateRangeConverter extends Converter<RawDirectDateRange> {
    public canConvert(field: RawField): field is RawDirectDateRange {
        return isType(field, RawDirectDateRangeC);
    }

    protected convertPartially(field: RawDirectDateRange): DirectDateRangePartial | null {
        return toDirectDateRange(field);
    }
}
