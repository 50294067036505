/**
 * @see src/CamBundle/Message/Websocket/Record/RecordDeleted.php
 */

import * as t from 'io-ts';
import { UUID } from 'io-ts-types/UUID';
import { Type } from './Type';

export const RecordDeletedC = t.type({
    type: t.literal(Type.DELETE),
    id: UUID,
});

export type RecordDeleted = t.TypeOf<typeof RecordDeletedC>;
