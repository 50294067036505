import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import convertForm from './convertForm';
import { FormFieldPartialWithoutRenderAsC, RawFormFieldC } from '../types/FormField';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import { assertIsNonNullable } from '../../../../typeAssertions/undefined';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';

export const RawReportDateFieldC = t.intersection([
    RawFormFieldC,
    t.type({
        attr: t.type({
            'data-report-date-modal': t.literal(true),
        }),
    }),
]);

export const ReportDateFieldPartialC = t.intersection([
    FormFieldPartialWithoutRenderAsC,
    t.type({
        renderAs: t.literal(FIELD_RENDER_TYPES.REPORT_DATE),
    }),
]);

export const ReportDateFieldC = t.intersection([ReportDateFieldPartialC, FieldCommonPropsC]);

export type RawReportDateField = TypeOf<typeof RawReportDateFieldC>
export type ReportDateFieldPartial = TypeOf<typeof ReportDateFieldPartialC>
export type ReportDateField = TypeOf<typeof ReportDateFieldC>

export default (field: RawReportDateField): ReportDateFieldPartial => {
    const convertedForm = convertForm(field);
    assertIsNonNullable(convertedForm);
    return ({
        ...convertedForm,
        renderAs: FIELD_RENDER_TYPES.REPORT_DATE,
    });
};
