import isType from '../../../../io-ts/isType';
import Converter from '../Converter';
import toSectionalForm from '../functions/toSectionalForm';
import { RawSectionalForm, RawSectionalFormC, SectionalFormPartial } from '../types/SectionalForm';
import { RawField } from '../types/RawField';

export default class SectionalFormConverter extends Converter<RawSectionalForm> {
    public canConvert(field: RawField): field is RawSectionalForm {
        return isType(field, RawSectionalFormC);
    }

    protected convertPartially(field: RawSectionalForm): SectionalFormPartial | null {
        return toSectionalForm(field);
    }
}
