import { Any, TypeOf } from 'io-ts';
import { AxiosError, AxiosRequestConfig } from 'axios';
import axios from '../../axios';
import { ErrorHandler, Significance } from './Builder/RequestBuilder';
import store, { loadingModule, userModule } from '../../../vue/store';
import {
    AxiosErrorWithResponse,
    defaultAjaxErrorHandlerFunction,
} from '../../mixins/defaultAjaxErrorHandler';
import { decodeAsTypeC } from '../../typeAssertions/isTypeC';
import ROUTE_NAMES from '../../../vue/routes/routeNames';
import router from '../../../vue/router';

const STATUS_FORBIDDEN = 403;

export default class Request<T extends Any> {
    private responseStatusCode?: number;

    constructor(
        private readonly config: AxiosRequestConfig,
        private readonly significance: Significance,
        private readonly errorHandler: ErrorHandler,
        private readonly responseType: T,
        private readonly is403Allowed: boolean,
    ) {
    }

    private async redirectIfNeeded({ response }: AxiosError) {
        const isCurrentRouteLogin = router.currentRoute.value.name === ROUTE_NAMES.LOGIN_MAIN;
        const isCurrentRouteAllowedForAnonymous = store.getters.isAnonymous;

        if (isCurrentRouteLogin || isCurrentRouteAllowedForAnonymous) {
            return;
        }

        const isUnauthorized = response?.status === 401;
        const isForbidden = response?.status === STATUS_FORBIDDEN && !this.is403Allowed;

        if (!isUnauthorized && !isForbidden) {
            return;
        }

        localStorage.clear();
        userModule.reset();

        await router.push({ name: ROUTE_NAMES.LOGIN_MAIN });
    }

    public async send(): Promise<TypeOf<T>> {
        this.incrementRequestsCount();
        let data: unknown;
        let status: number;

        try {
            ({ data, status } = await axios.request(this.config));
            this.responseStatusCode = status;
        } catch (e: unknown) {
            if (axios.isAxiosError(e)) {
                this.responseStatusCode = e.response?.status;
                this.redirectIfNeeded(e);
                this.handleError(e);
            }

            throw e;
        } finally {
            this.decrementRequestsCount();
        }

        try {
            return decodeAsTypeC(data, this.responseType, `Response from ${this.config.url}`);
        } catch (e: unknown) {
            if (e instanceof TypeError) {
                throw e;
            }

            throw new Error('Invalid response.');
        }
    }

    public getResponseStatusCode(): number {
        if (this.responseStatusCode === undefined) {
            throw new Error('You must await the send function before getting the status code');
        }

        return this.responseStatusCode;
    }

    private incrementRequestsCount(): void {
        if (this.significance === Significance.Main) {
            loadingModule.incrementItemsToLoad();
        }

        if (this.significance === Significance.Secondary) {
            loadingModule.incrementAjaxRequests();
        }
    }

    private decrementRequestsCount(): void {
        if (this.significance === Significance.Main) {
            loadingModule.decrementItemsToLoad();
        }

        if (this.significance === Significance.Secondary) {
            loadingModule.decrementAjaxRequests();
        }
    }

    private handleError(e: unknown): void {
        if (axios.isAxiosError(e) && e.response?.status === STATUS_FORBIDDEN) {
            return;
        }

        if (this.errorHandler === ErrorHandler.Default) {
            defaultAjaxErrorHandlerFunction(e as AxiosErrorWithResponse);
        }

        if (e instanceof Error && e.name === 'CanceledError') {
            this.decrementRequestsCount();
        }
    }
}
