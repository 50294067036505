import Converter from '../Converter';
import toRadioFormat, {
    RadioFieldPartial,
    RawRadioField,
    RawRadioFieldC,
} from '../functions/toRadioFormat';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';

export default class RadioFieldConverter extends Converter<RawRadioField> {
    public canConvert(field: RawField): field is RawRadioField {
        return isType(field, RawRadioFieldC);
    }

    protected convertPartially(field: RawRadioField): RadioFieldPartial {
        return toRadioFormat(field);
    }
}
