import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';

export const RawStaticImageFieldC = t.type({
    title: t.union([t.string, t.null]),
    name: t.string,
    type: t.literal('string'),
    renderer: t.literal(FIELD_RENDER_TYPES.STATIC_IMAGE),
    attr: t.type({
        publicFileUrl: t.string,
    }),
});

export const StaticImageFieldPartialC = t.type({
    publicFileUrl: t.string,
    renderAs: t.literal(FIELD_RENDER_TYPES.STATIC_IMAGE),
    name: t.string,
});

export const StaticImageFieldC = t.intersection([StaticImageFieldPartialC, FieldCommonPropsC]);

export type RawStaticImageField = TypeOf<typeof RawStaticImageFieldC>
export type StaticImageFieldPartial = TypeOf<typeof StaticImageFieldPartialC>
export type StaticImageField = TypeOf<typeof StaticImageFieldC>

export default (field: RawStaticImageField): StaticImageFieldPartial => ({
    renderAs: FIELD_RENDER_TYPES.STATIC_IMAGE,
    publicFileUrl: field.attr.publicFileUrl,
    name: field.name,
});
