import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import OneOf from '../../../../io-ts/OneOf';
import { ALLOWED_TYPES } from '../../types/FieldCommonProps';
import fromEnum from '../../../../io-ts/fromEnum';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import { emptyArray, EmptyRecordFromArray } from '../../../../io-ts/emptyArray';

const KNOWN_WIDGETS = [
    'choice-expanded',
    'quick-button',
    'choice-multiple-expanded',
    'textarea',
    'date',
    'password',
    'email',
] as const;

export const RawFieldCommonPropsC = t.type({
    type: OneOf(ALLOWED_TYPES),
    title: t.union([t.string, t.null]),
    name: t.string,
});

export type RawFieldCommonProps = t.TypeOf<typeof RawFieldCommonPropsC>;

const AttrC = t.partial({
    'data-render-as-user-filter': t.literal(true),
    'data-render-as-filter-info-items-container': t.literal(true),
    'data-render-as-evaluation': t.literal(true),
    'data-retention-rule': t.literal(true),
    'data-date-type': t.literal(true),
    'data-report-date-modal': t.literal(true),
    'data-render-as-comment-review': t.literal(true),
    'data-render-as-expiration': t.literal(true),
    'data-has-prototype': t.literal(true),
    accept: t.string,
    maxSize: t.number,
    class: t.string,
    search: t.boolean,

    // evaluation description
    default_data: t.string,
    'data-total-value': t.union([t.string, t.number]),
    'data-type': t.union([t.literal('description'), t.literal('single_select'), t.literal('multi_checkbox')]),
    'data-uid': t.string,

    // input
    role: t.literal('search'),
    'data-render-as-pin-code': t.literal(true),
    'data-mobile-placeholder': t.string,
    placeholder: t.union([t.string, t.null, t.number]),

    // common
    'data-mobile-title': t.string,
    'data-update-form-on-change': t.literal(true),
    'data-update-form-debounce': t.literal(true),

    'data-header': t.string,

    // multiselect
    'data-render-with-labels-below': t.literal(true),
    'data-render-as-tree': t.literal(true),
    'allow-comments': t.boolean,
    commentId: t.union([t.number, t.null]),

    // retention
    retentionRuleOutDate: t.union([t.null, t.number]),

    readonly: t.boolean,
    publicFileUrl: t.union([t.string, t.null]),
});

const RawFieldWithoutRecursiveFieldsC = t.intersection([
    RawFieldCommonPropsC,
    t.partial({
        disabled: t.boolean,
        renderer: fromEnum('FIELD_RENDER_TYPES', FIELD_RENDER_TYPES),
        attr: t.union([AttrC, EmptyRecordFromArray]),
        widget: t.union([OneOf(KNOWN_WIDGETS), t.string]),
        enum: t.array(t.string),
        enum_titles: t.array(t.string),
        default: t.unknown,
        // form
        required: t.array(t.string),
        /** @see RawFieldC   */
        properties: t.unknown,
        items: t.unknown,

        // radio
        radio_choices: t.array(t.string),

        propertyOrder: t.number,
        errors: t.array(t.string),
        tooltip: t.union([t.string, t.null]),
    }),
]);

export const RawFieldC = t.intersection([
    RawFieldWithoutRecursiveFieldsC,
    t.partial({
        properties: t.union([
            t.record(t.string, RawFieldWithoutRecursiveFieldsC),
            emptyArray,
        ]),
        items: t.union([
            t.array(RawFieldWithoutRecursiveFieldsC),
            t.intersection([
                t.partial({
                    enum: t.array(t.string),
                    enum_titles: t.array(t.string),
                }),
                t.partial({
                    enum_additional_info: t.array(t.number),
                }),
            ]),
        ]),
    }),
]);

export type RawField = TypeOf<typeof RawFieldC>
