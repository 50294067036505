import { Module, VuexModule } from 'vuex-module-decorators';
import { loadingModule, menuModule, notificationModule } from '../store-accessor';

@Module({ name: 'OverlayModule' })
export default class OverlayModule extends VuexModule {
    // eslint-disable-next-line class-methods-use-this
    get hasOverlay(): boolean {
        return !loadingModule.isLoaded
            || notificationModule.notification.show
            || menuModule.doesRequestOverlay
            || loadingModule.hasAjaxRequests;
    }
}
