<template>
    <div class="menu-wrapper">
        <ul
            class="menu__nav"
            role="navigation"
            :class="{preload: !doesMenuHasAnimation}"
        >
            <li class="list__element">
                <RouterLink
                    :to="homeRoute"
                    data-cy="logo-link"
                >
                    <Logo
                        variant="no-text"
                        class="logo"
                        alt="logo"
                    />
                </RouterLink>
            </li>
            <li class="list__element main-elements">
                <ul
                    v-if="!isAnonymous"
                    class="list"
                    role="navigation"
                    aria-label="Main menu"
                >
                    <!--TODO: Rewrite if vue 3 due to click.native is being deprecated-->
                    <Tooltip
                        v-for="menuItem in menuItems"
                        :id="`${menuItem.routeName}-tooltip`"
                        :key="menuItem.routeName"
                        :title="menuItem.text"
                        placement="right"
                        no-min-width
                    >
                        <li class="list-element">
                            <RouterLink
                                class="nav-link"
                                :class="{'nav-link--active' : routeIsActive(menuItem.routeNamesToHighlight) }"
                                :data-cy="`menu-item-${menuItem.text.toLowerCase()}`"
                                :aria-label="menuItem.text"
                                :to="{name: menuItem.routeName}"
                            >
                                <Component
                                    :is="menuItem.inlinedSvg"
                                    alt="Link icon"
                                />
                                <span class="nav-link__text">{{ menuItem.text }}</span>
                            </RouterLink>
                        </li>
                    </Tooltip>
                </ul>
            </li>
            <li
                v-if="!isAnonymous"
                class="user-info"
                data-cy="user-info-button"
            >
                <Tooltip
                    id="notifications-tooltip"
                    title="Notifications"
                    placement="right"
                    no-min-width
                >
                    <NotificationsButton
                        id="notifications-button"
                        :is-active="isNotificationsPopupShown"
                        :has-new-notification="newNotificationsCount > 0"
                        @notifications-toggle="toggleNotificationsPopup"
                    />
                </Tooltip>

                <Tooltip
                    id="profile-tooltip"
                    title="Profile"
                    placement="right"
                    no-min-width
                    class="user-picture-tooltip"
                >
                    <button
                        class="user-picture"
                        type="button"
                        aria-label="Profile Menu"
                        @click="switchToPopup"
                    >
                        <img
                            :src="userAvatarPath || defaultAvatar"
                            width="32"
                            height="32"
                            class="img-rounded-circle"
                            alt="Circle avatar"
                        >
                        <div
                            v-if="unreadAlertsCount > 0"
                            class="user-picture__notification-dot"
                            data-cy="user-picture-notification-dot"
                        />
                    </button>
                </Tooltip>
            </li>
        </ul>
        <NotificationsPopup
            v-if="isNotificationsPopupShown"
            :notifications="notifications"
            @scroll-reach-end="loadNextNotifications"
            @reload-notifications="reloadNotifications"
            @hide-notifications="isNotificationsPopupShown = false"
        />
        <TheUserInfoPopup
            v-if="isPopupShown"
            ref="userInfoPopup"
            :user-name="userName"
            :unread-alerts-count="unreadAlertsCount"
            @hide="hideMenu"
        />
    </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import {
    Watch, Ref,
} from 'vue-property-decorator';
import { RouteLocationRaw } from 'vue-router';
import { debounce } from 'lodash';
import ClickOutsideListener from '../../../scripts/helpers/listeners/ClickOutsideListener';
import {
    alertModule, menuModule, notificationModule, userModule,
} from '../../store';
import Getter from '../../store/classComponent/Getter';
import userIconUrl from '../../../images/user.svg?url';
import Button from '../Elements/Button.vue';
import TheUserInfoPopup from './TheUserInfoPopup.vue';
import hasPermissionForRoute from '../../../scripts/helpers/functions/permissions/hasPermissionForRoute';
import Logo from '../SvgComponents/Logo.vue';
import ROUTE_NAMES from '../../routes/routeNames';
import { MENU_ICONS } from './constants/menuIcons';
import { MENU_ITEMS } from './constants/menuItems';
import type { MenuItem } from './types/MenuItem';
import { getCountOfUnreadAlerts } from '../../../scripts/api/alert/AlertApi';
import { allPermissions } from '../../../scripts/test/mocks/allPermissions';
import NotificationsButton from '../Notifications/NotificationsButton.vue';
import NotificationsPopup from '../Notifications/NotificationsPopup.vue';
import { getPopupNotifications } from '../../../scripts/api/notification/NotificationApi';
import { NotificationItem, NotificationItemC } from '../../../scripts/types/Notification';
import assertIsTypeC, { decodeAsTypeC } from '../../../scripts/typeAssertions/isTypeC';
import { socket } from '../../../scripts/socket';
import DateHelper from '../../../scripts/helpers/DateHelper';
import Tooltip from '../Elements/Tooltip.vue';
import { RecordMessageC, Type } from '../../../scripts/types/sockets/record';

@Options({
    components: {
        NotificationsPopup,
        NotificationsButton,
        Logo,
        TheUserInfoPopup,
        Button,
        Tooltip,
        ...MENU_ICONS,
    },
})
export default class TheMenu extends Vue {
    public readonly defaultAvatar: string = userIconUrl;

    public readonly homeRoute: RouteLocationRaw = { name: ROUTE_NAMES.HOME };

    @Ref('userInfoPopup')
    public userInfoPopup!: TheUserInfoPopup;

    @Getter(['unreadAlertsCount']) unreadAlertsCount!: number;

    @Getter(['isLgOrMore']) isLgOrMore!: boolean;

    @Getter(['isMenuShown']) isMenuShown!: boolean;

    @Getter(['isPopupShown']) isPopupShown!: boolean;

    @Getter(['isObserveOpen']) isObserveOpen!: boolean;

    @Getter(['isRoomMenuShown']) isRoomMenuShown!: boolean;

    @Getter(['doesMenuHasAnimation']) doesMenuHasAnimation!: boolean;

    @Getter(['userName']) userName!: string | null;

    @Getter(['isAnonymous']) isAnonymous!: boolean;

    @Getter(['userAvatarPath']) userAvatarPath!: string | null;

    @Getter(['permissions']) permissions!: string[] | null;

    public clickOutsideListener: ClickOutsideListener | null = null;

    public notificationsPage = 1;

    public notificationsPageCount = 1;

    public notifications: NotificationItem[] = [];

    public newNotificationsCount = 0;

    public isNotificationsPopupShown = false;

    private get formFilterName(): string {
        return 'notification_filter';
    }

    private getFormFieldName(field: string, prefix = ''): string {
        return `${this.formFilterName}${prefix}[${field}]`;
    }

    @Watch('isMenuShown')
    public onChangeMenuVisibility(): void {
        if (this.isMenuShown && this.hasPermissionForAlerts()) {
            this.loadAlertsCount();
        }
    }

    @Watch('permissions', { immediate: true })
    public loadADataOnPermissions() {
        if (userModule.isUserLoggedIn) {
            this.loadNotifications();
        }

        if (!this.hasPermissionForAlerts()) {
            return;
        }
        this.loadAlertsCount();
    }

    @Watch('isLgOrMore')
    public disableAnimationUntilShownOnDecrease() {
        if (this.isLgOrMore) {
            return;
        }

        menuModule.disableAnimationUntilShown();
    }

    @Watch('isPopupShown')
    public async onChangeUserPopupVisible(): Promise<void> {
        if (!this.isPopupShown) {
            this.clickOutsideListener?.unregisterListener();
            this.clickOutsideListener = null;
            return;
        }

        await this.$nextTick();

        this.clickOutsideListener = new ClickOutsideListener(
            [
                this.userInfoPopup.$el as HTMLElement,
                this.$el as HTMLElement,
            ], this.hideMenu,
        );
        this.clickOutsideListener.registerListener();
    }

    @Watch('$route')
    public onRouteChange(): void {
        this.isNotificationsPopupShown = false;
    }

    public created(): void {
        this.reloadNotifications();
        socket.on('notification', this.onNotificationSocket);
        socket.on('comment:delete', this.reloadNotifications);
        socket.on('record', (data: string) => {
            const message = JSON.parse(data);
            assertIsTypeC(message, RecordMessageC);

            if (message.type === Type.DELETE) {
                this.reloadNotifications();
            }
        });
    }

    public async onNotificationSocket(json: string): Promise<void> {
        const parsedJson = JSON.parse(json);
        const message = decodeAsTypeC(parsedJson, NotificationItemC);

        if (message.receiverId === userModule.userId) {
            this.reloadNotifications();

            if (userModule.isUserLoggedIn && userModule.isNotificationSoundEnabled) {
                const player = new Audio('/notification_sound.mp3');
                await player.play();
            }
        }
    }

    public destroyed(): void {
        this.clickOutsideListener?.unregisterListener();
    }

    public toggleNotificationsPopup(): void {
        this.isNotificationsPopupShown = !this.isNotificationsPopupShown;
    }

    private hasChildren(permission: string): boolean {
        return this.permissions?.some((p) => p.startsWith(`${permission}--`)) ?? false;
    }

    public get menuItems(): MenuItem[] {
        return MENU_ITEMS.filter(({ routeName }) => {
            if (routeName === allPermissions.admin && !this.hasChildren(allPermissions.admin)) {
                return false;
            }
            return hasPermissionForRoute({ name: routeName });
        });
    }

    public async loadAlertsCount(): Promise<void> {
        getCountOfUnreadAlerts()
            .then(alertModule.setUnreadAlertsCount)
            .catch(() => {
                notificationModule.notifyUser('Unable to load alerts.');
            });
    }

    public routeIsActive(routeNamesToHighlight: string[]): boolean {
        return this.$route.matched.some(({ name }) => (
            routeNamesToHighlight.some((routeName) => name === routeName)
        ));
    }

    public hideMenu(): void {
        menuModule.hideMenu();
    }

    public switchToPopup(): void {
        menuModule.switchToPopup();
    }

    public hasPermissionForAlerts(): boolean {
        return userModule.hasPermission(allPermissions.generalAlert);
    }

    public buildNotificationFilter(): FormData {
        const formData = new FormData();
        formData.append('page', this.notificationsPage.toString());
        formData.append(
            this.getFormFieldName('left_date', '[startedAt]'),
            DateHelper.toDateFormFormat(new Date(0)),
        );
        formData.append(this.getFormFieldName('viewed'), 'n');

        return formData;
    }

    public async reloadNotifications(): Promise<void> {
        this.notificationsPage = 1;
        this.notifications = [];
        debounce(this.loadNotifications, 500)();
    }

    public async loadNextNotifications(): Promise<void> {
        if (this.notificationsPage >= this.notificationsPageCount) {
            return;
        }
        this.notificationsPage = Math.min(this.notificationsPageCount, this.notificationsPage + 1);
        await this.loadNotifications();
    }

    public async loadNotifications(): Promise<void> {
        try {
            const {
                paginator: { items, paginationData },
                newNotificationsCount,
            } = await getPopupNotifications(this.buildNotificationFilter());

            this.notifications = [...this.notifications, ...items];
            this.notificationsPageCount = paginationData.pageCount;
            this.newNotificationsCount = newNotificationsCount;
        } catch (e: unknown) {
            if (userModule.isUserLoggedIn) {
                notificationModule.notifyUser('Unable to load notifications.');
            } else {
                notificationModule.hideNotification();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/abstracts/svg-colors';
@import '../../../styles/abstracts/spacings';
@import '../../../styles/abstracts/colors_old';
@import '../../../styles/abstracts/font-sizes';
@import '../../../styles/abstracts/z-indexes';
@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';
@import '../../../styles/pages/menu';

$nav-width-mobile: 20.5rem;
$user-picture-side-mobile: 2.6rem;
$user-picture-side-desktop: 2.25rem;

.preload {
    transition: background-color 0s, opacity 0s, color 0s, width 0s, height 0s, padding 0s, margin 0s !important;
    animation-duration: 0s !important;
}

.menu-wrapper {
    display: flex;

    height: 100%;
}

.menu__nav {
    z-index: $the-menu-z-index;

    display: flex;

    flex-direction: column;

    justify-content: space-between;

    justify-items: center;

    min-width: $nav-width-desktop;

    height: 100%;

    padding: 0;

    background-color: var(--theme-color-surface-menu);
}

.logo {
    height: 4rem;
}

.main-elements {
    font-size: $font-size-lg;
}

.list {
    display: flex;

    flex-direction: column;

    height: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    :deep .tooltip-wrapper {
        width: 100%;
    }

    &__element {
        display: flex;

        flex-direction: column;
    }
}

.nav-link {
    display: flex;

    justify-content: center;

    padding: $spacing-smm $spacing-s;

    margin: $spacing-xxs $spacing-xxs 0 $spacing-xxs;

    color: var(--theme-color-text-primary);

    cursor: pointer;

    border-radius: $border-radius-sm;

    transition: 0.3s background-color;

    svg {
        height: $icon-size-lg;

        margin-right: 0;

        fill: var(--theme-color-icon-inversed-secondary);

        transition: fill 0.3s;
    }

    &--active,
    &:hover {
        background-color: var(--theme-color-surface-secondary-default);

        svg {
            fill: var(--theme-color-icon-always-white);
        }
    }

    &__text {
        display: none;
    }
}

.user-info {
    display: flex;

    flex-flow: column;

    align-items: center;

    justify-content: center;

    justify-self: flex-end;

    width: 100%;

    padding: 0;

    margin-bottom: $spacing-m;

    cursor: pointer;
}

.user-picture-tooltip {
    display: flex;

    justify-content: center;

    width: 100%;
}

.user-picture {
    position: relative;

    padding: 0;

    background-color: transparent;

    border: 0;

    &,
    img {
        width: $user-picture-side-desktop;
        height: $user-picture-side-desktop;
    }

    img {
        background: var(--theme-color-icon-always-white);
        object-fit: cover;
    }

    &__notification-dot {
        @include notification-dot(0.7rem, var(--theme-color-surface-menu));
    }
}

.img-rounded-circle {
    border-radius: 50%;
}

@keyframes slide-right {
    0% {
        left: -$nav-width-mobile;
    }

    100% {
        left: 0;
    }
}

@keyframes slide-left {
    0% {
        left: 0;
    }

    100% {
        left: -$nav-width-mobile;
    }
}
</style>
