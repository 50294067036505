<template>
    <div
        v-show="show"
        id="notification-container"
        ref="nofificationRef"
        class="notification-container"
    >
        <div
            class="notification"
            :class="{
                'notification--title-with-message': variant === 'title_with_message'
            }"
        >
            <CloseButton
                data-cy="close-notification-button"
                class="notification__close-button"
                @click="hide"
            />

            <Component
                :is="icon"
                v-if="icon"
                class="notification__icon"
            />

            <h3
                v-if="title"
                class="notification__title"
            >
                {{ title }}
            </h3>

            <div class="notification__text">
                {{ text }}
            </div>

            <div
                v-if="warning"
                class="notification__warning"
            >
                {{ warning }}
            </div>
            <div
                class="notification__buttons notification-buttons"
                :class="{
                    'notification-buttons--row': onlyTwoButtons
                }"
            >
                <Button
                    v-if="cancelButton"
                    class="notification__button"
                    :variant="cancelButton.variant || 'gray'"
                    @click="handleAction(cancelButton)"
                >
                    {{ cancelButton.text }}
                </Button>
                <Button
                    v-for="button in actionButtons"
                    :key="button.text"
                    :cypress-id="`${button.text}-button-in-notification`"
                    class="notification__button"
                    :variant="button.variant || 'dark'"
                    @click="handleAction(button)"
                >
                    {{ button.text }}
                </Button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Watch, Ref } from 'vue-property-decorator';
import { notificationModule } from '../store';
import Getter from '../store/classComponent/Getter';
import Notification, { NotificationIcon, NotificationVariant } from '../../scripts/helpers/Notification/Notification';
import NotificationButton from '../../scripts/helpers/Notification/NotificationButton';
import Button from './Elements/Button.vue';
import WarningIcon from '../../images/warning.svg?component';
import TrashIcon from '../../images/trash.svg?component';
import LockIcon from '../../images/lock.svg?component';
import CloseButton from './Elements/Button/CloseButton.vue';

@Options({
    components: {
        Button,
        WarningIcon,
        TrashIcon,
        LockIcon,
        CloseButton,
    },
})
export default class TheNotification extends Vue {
    @Getter(['notification'])
    private readonly notification!: Notification;

    @Ref('nofificationRef')
    public readonly nofification!: HTMLElement;

    public get show(): boolean {
        return this.notification.show;
    }

    public get variant(): NotificationVariant {
        return this.notification.variant;
    }

    public get title(): string {
        return this.notification.title;
    }

    public get text(): string {
        return this.notification.text;
    }

    public get warning(): string | null {
        return this.notification.warning;
    }

    public get actionButtons(): NotificationButton[] {
        return this.notification.actionButtons;
    }

    public get cancelButton(): NotificationButton | null {
        return this.notification.cancelButton;
    }

    public get onlyTwoButtons(): boolean {
        return this.cancelButton
            ? this.actionButtons.length === 1
            : this.actionButtons.length === 2;
    }

    public handleAction(button: NotificationButton): void {
        button.callback.call(this);
        this.hide();
    }

    public hide(): void {
        notificationModule.hideNotification();
    }

    protected onKeyDown(event: KeyboardEvent): void {
        if (!this.show) {
            return;
        }

        const isEscKey = event.key === 'Escape';

        if (isEscKey) {
            this.hide();
        }
    }

    public created() : void {
        window.addEventListener('keydown', this.onKeyDown, false);
    }

    protected beforeDestroy() : void {
        window.removeEventListener('keydown', this.onKeyDown, false);
    }

    public get icon(): NotificationIcon|null {
        return this.notification.icon;
    }

    // show notification in fullscreen mode
    @Watch('show')
    public onChangeShow(): void {
        if (!this.show) {
            return;
        }

        if (!document.fullscreenElement) {
            return;
        }

        document.fullscreenElement.appendChild(this.nofification);
    }
}
</script>

<style lang="scss" scoped>
@import '../../styles/abstracts/z-indexes';
@import '../../styles/abstracts/font-sizes';
@import '../../styles/abstracts/spacings';
@import '../../styles/abstracts/variables';

.notification-container {
    position: fixed;
    inset: 0;

    z-index: $notification-container-z-index;

    display: flex;

    place-items: center;

    background-color: var(--theme-color-overlay-strong);
}

.notification {
    position: relative;

    display: flex;

    flex-direction: column;

    align-items: center;

    max-width: 27.625rem;

    padding: $spacing-xxl-2 $spacing-xxl $spacing-xxl;

    margin: 0 auto;

    text-align: center;

    background-color: var(--theme-color-surface-primary-default);
    border-radius: $border-radius-lg;

    &__close-button {
        position: absolute;
        top: $spacing-s;
        right: $spacing-s;
    }

    &__icon {
        width: $spacing-xxl-6;
        height: $spacing-xxl-6;
        padding: $spacing-xxs;
        margin-bottom: $spacing-m;

        background-color: var(--theme-color-surface-primary-default);
        border-radius: $border-radius-lg;
        box-shadow: var(--shadow-4);

        fill: currentColor;
    }

    &__text {
        font-size: $spacing-m;
        font-weight: bold;
    }

    &__warning {
        margin-top: $spacing-xs;

        white-space: pre-wrap;
    }

    &-buttons {
        display: grid;

        grid-gap: $spacing-m;
    }

    &-buttons--row {
        grid-template-columns: 1fr 1fr;
    }

    &__buttons {
        margin-top: 1rem;
    }

    &__button {
        width: 12rem;
        margin: 0 !important;
    }
}

.notification--title-with-message {
    .notification__title {
        font-size: $font-size-lg;
        font-weight: 700;

    }

    .notification__text {
        font-size: $font-size-lg;
        font-weight: 400;
    }
}
</style>
