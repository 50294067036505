import { ref, onMounted, onUnmounted } from 'vue';

export const useWindow = () => {
    const innerWidth = ref(window.innerWidth);
    const innerHeight = ref(window.innerHeight);

    const onResize = () => {
        innerWidth.value = window.innerWidth;
        innerHeight.value = window.innerHeight;
    };

    onMounted(() => {
        window.addEventListener('resize', onResize);
    });

    onUnmounted(() => {
        window.removeEventListener('resize', onResize);
    });

    return {
        innerWidth,
        innerHeight,
    };
};
