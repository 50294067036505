import { RoutingSymfony } from '../../player/mobile/helpers/routing';
import RequestBuilder, { Significance } from '../../helpers/Request/Builder/RequestBuilder';
import { ALERTS_COUNT_ROUTE } from './constants/routes';
import { UnreadResponseC } from './type/responses';

export async function getCountOfUnreadAlerts(): Promise<number> {
    const url = RoutingSymfony.generate(ALERTS_COUNT_ROUTE);

    const { unread } = await new RequestBuilder(UnreadResponseC)
        .setUrl(url)
        .setMethod('get')
        .setSignificance(Significance.Invisible)
        .build()
        .send();

    return unread;
}
