import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import SchemaConverter from '../../SchemaConverter';
import { assertIsDefined } from '../../../../typeAssertions/undefined';
import convertForm from './convertForm';
import {
    FormField,
    FormFieldC,
    FormFieldPartial,
    FormFieldPartialC,
    RawFormField,
} from '../types/FormField';
import { RawField, RawFieldC } from '../types/RawField';
import assertIsTypeC from '../../../../typeAssertions/isTypeC';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';
import { Field } from '../types/Field';

const SimpleRendererC = t.literal(FIELD_RENDER_TYPES.ARRAY);
const PrototypedRendererC = t.union([
    t.literal(FIELD_RENDER_TYPES.ARRAY_WITH_PROTOTYPE),
    t.literal(FIELD_RENDER_TYPES.TEMPLATE_SELECTION_COLLECTION),
    t.literal(FIELD_RENDER_TYPES.EVALUATION_COLLAPSE),
]);
const RendererC = t.union([SimpleRendererC, PrototypedRendererC]);

export const RawArrayFieldC = t.intersection([
    t.type({
        name: t.string,
        title: t.union([t.string, t.null]),
        items: t.array(RawFieldC),
        type: t.literal('array'),
    }),
    t.partial({
        renderer: RendererC,
        attr: t.partial({
            'data-has-prototype': t.literal(true),
            'data-can-add': t.boolean,
        }),
    }),
]);

export const ArrayFieldCommonPropsC = t.type({
    title: t.union([t.string, t.null]),
    name: t.string,
    accept: t.union([t.string, t.literal(false)]),
    items: t.array(FieldCommonPropsC),
    canAdd: t.boolean,
});

const ArrayFieldPartialC = t.intersection([
    ArrayFieldCommonPropsC,
    t.type({
        renderAs: SimpleRendererC,
    }),
]);

export const ArrayWithPrototypeFieldPartialC = t.intersection([
    ArrayFieldCommonPropsC,
    t.type({
        renderAs: PrototypedRendererC,
        itemPrototype: FormFieldC,
    }),
]);

export const ArrayFieldC = t.intersection([ArrayFieldPartialC, FieldCommonPropsC]);
export const ArrayWithPrototypeFieldC = t.intersection(
    [ArrayWithPrototypeFieldPartialC, FieldCommonPropsC],
);

export type RawArrayField = TypeOf<typeof RawArrayFieldC>;
export type ArrayFieldPartial = TypeOf<typeof ArrayFieldPartialC>
export type ArrayWithPrototypeFieldPartial = TypeOf<typeof ArrayWithPrototypeFieldPartialC>
export type ArrayField = TypeOf<typeof ArrayFieldC>
export type ArrayWithPrototypeField = TypeOf<typeof ArrayWithPrototypeFieldC>

const getPrototypeField = (field: RawArrayField): RawFormField => {
    const prototype = field.items.find(({ title }) => title === 'prototype')
        || field.items[0];

    if (!prototype) {
        return {
            title: '',
            name: '',
            properties: [],
            type: 'object',
        };
    }

    const { required, properties } = prototype;

    return {
        title: '',
        name: '',
        required,
        properties: properties as Record<string, RawField>,
        type: 'object',
    };
};

function toPrototypeField(field: FormFieldPartial): FormField {
    return {
        ...field,
        title: '',
        type: 'object',
        errors: [],
        tooltip: null,
        disabled: false,
        updateOnChange: false,
        updateDebounce: false,
    };
}

export default (field: RawArrayField): ArrayFieldPartial | ArrayWithPrototypeFieldPartial => {
    const accept = (field.items[0]?.attr?.accept || false) as string | false;

    const items = field.items.reduce<Field[]>((acc, current) => {
        const convertedField = new SchemaConverter(current).convert();

        if (!convertedField) {
            return acc;
        }

        acc.push(convertedField);

        return acc;
    }, []);

    const hasPrototype = field.attr?.['data-has-prototype'] || false;

    const commonProps = {
        title: field.title,
        name: field.name,
        items,
        accept,
        canAdd: field.attr?.['data-can-add'] ?? true,
    };

    if (!hasPrototype) {
        return {
            ...commonProps,
            renderAs: FIELD_RENDER_TYPES.ARRAY,
        };
    }

    const rawPrototype = getPrototypeField(field);
    const prototypeFormField = convertForm(rawPrototype);

    assertIsTypeC(prototypeFormField, FormFieldPartialC);

    const itemPrototype = toPrototypeField(prototypeFormField);

    return {
        ...commonProps,
        renderAs: field.renderer ?? FIELD_RENDER_TYPES.ARRAY_WITH_PROTOTYPE,
        itemPrototype,
    };
};
