import * as t from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import {
    FormFieldWithoutRenderAsC,
    RawFormFieldC,
} from './FormField';

export const RawGridSizeFieldC = t.intersection([
    RawFormFieldC,
    t.type({
        renderer: t.literal(FIELD_RENDER_TYPES.GRID_SIZE),
        minSize: t.number,
    }),
]);

export const GridSizeFieldFieldsC = t.type({
    gridSize: t.type({
        value: t.string,
    }),
    gridExtended: t.type({
        value: t.string,
    }),
});

export const GridSizeFieldC = t.intersection([
    FormFieldWithoutRenderAsC,
    t.type({
        renderAs: t.literal(FIELD_RENDER_TYPES.GRID_SIZE),
        fields: GridSizeFieldFieldsC,
        minSize: t.number,
    }),
]);

export type RawGridSizeField = t.TypeOf<typeof RawGridSizeFieldC>;
export type GridSizeField = t.TypeOf<typeof GridSizeFieldC>;
export type GridSizeFieldFields = t.TypeOf<typeof GridSizeFieldFieldsC>;
