import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import listeners from '../scripts/helpers/listeners/router/all';
import { getBaseUrl } from '../scripts/helpers/getBaseUrl';

const router = createRouter({
    history: createWebHistory(getBaseUrl()),
    routes,
});

router.beforeEach(async (to, from, next) => {
    for (const listener of listeners) {
        // eslint-disable-next-line no-await-in-loop
        const target = await listener.onBeforeEach(to, from);

        if (target === false) {
            return;
        }

        if (target !== true) {
            next(target);
            return;
        }
    }

    next();
});

router.onError((error, to) => {
    if (error.message.includes('Failed to fetch dynamically imported module') || error.message.includes('Importing a module script failed')) {
        window.location.href = to.fullPath;
    }
});

export default router;
