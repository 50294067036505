import CalendarIcon from '../../../images/calendar.svg?component';
import ROUTE_NAMES from '../routeNames';
import SECTION_NAMES from '../sectionNames';
import { permissionsModule as pM } from '../../store';

const meta = {
    title: SECTION_NAMES.LOGS,
    icon: CalendarIcon,
    permissions: (): string[] => [pM.allPermissions.adminLogs],
};

const AdminLog = () => import('../../views/admin/AdminLog.vue');
export default [
    {
        path: 'show/login',
        name: ROUTE_NAMES.ADMIN_LOG_LOGIN,
        component: AdminLog,
        meta: {
            ...meta,
            menuTitle: 'Login',
        },
    },
    {
        path: 'show/observe',
        name: ROUTE_NAMES.ADMIN_LOG_OBSERVE,
        component: AdminLog,
        meta: {
            ...meta,
            menuTitle: 'Observe',
        },
    },
    {
        path: 'show/recording',
        name: ROUTE_NAMES.ADMIN_LOG_RECORDING,
        component: AdminLog,
        meta: {
            ...meta,
            menuTitle: 'Recording',
        },
    },
    {
        path: 'show/review',
        name: ROUTE_NAMES.ADMIN_LOG_REVIEW,
        component: AdminLog,
        meta: {
            ...meta,
            menuTitle: 'Review',
        },
    },
    {
        path: 'show/schedule',
        name: ROUTE_NAMES.ADMIN_LOG_SCHEDULE,
        component: AdminLog,
        meta: {
            ...meta,
            menuTitle: 'Schedule',
        },
    },
    {
        path: 'show/reports',
        name: ROUTE_NAMES.ADMIN_LOG_REPORTS,
        component: AdminLog,
        meta: {
            ...meta,
            menuTitle: 'Reports',
        },
    },
    {
        path: 'show/upload',
        name: ROUTE_NAMES.ADMIN_LOG_UPLOAD,
        component: AdminLog,
        meta: {
            ...meta,
            menuTitle: 'Upload',
        },
    },
    {
        path: 'show/admin',
        name: ROUTE_NAMES.ADMIN_LOG_ADMIN,
        component: AdminLog,
        meta: {
            ...meta,
            menuTitle: 'Admin',
        },
    },
    {
        path: 'show/alert',
        name: ROUTE_NAMES.ADMIN_LOG_ALERTS,
        component: AdminLog,
        meta: {
            ...meta,
            menuTitle: 'Alerts',
        },
    },
];
