import Converter from '../Converter';
import toRestrictAccess from '../functions/toRestrictAccess';
import {
    RestrictAccessFieldPartial,
    RawRestrictAccessField,
    RawRestrictAccessFieldC,
} from '../types/RestrictAccess';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';

export default class RestrictAccessConverter extends Converter<RawRestrictAccessField> {
    public canConvert(field: RawField): field is RawRestrictAccessField {
        return isType(field, RawRestrictAccessFieldC);
    }

    protected convertPartially(field: RawRestrictAccessField): RestrictAccessFieldPartial {
        return toRestrictAccess(field);
    }
}
