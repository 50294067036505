<!-- eslint-disable vue/max-len -->
<template>
    <svg
        v-if="isTextVariant"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 60 65"
        class="logo"
        :class="`logo--${variant}`"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.4172 18.3984C19.9687 15.4949 32.3676 11.43 45.5512 18.3984V23.8132C41.7844 20.9096 31.473 16.1855 20.3611 21.4589C20.6204 23.5766 21.094 26.3608 22.8828 29.6541C21.3395 30.5862 19.9652 31.7768 18.8213 33.1752C16.5035 29.0945 15.1599 24.2297 15.4172 18.3984ZM20.1518 35.2863C22.8444 39.1606 26.4303 42.2564 30.2488 44.7657L30.4842 38.6447C27.5952 36.1569 25.5542 33.815 24.1001 31.6563C22.558 32.5865 21.2138 33.8197 20.1518 35.2863ZM26.4821 24.7548C29.0717 24.0486 32.3676 24.0486 34.7218 24.7548C34.251 27.8153 32.1322 31.1112 30.7196 32.2883C29.3856 31.1897 26.9529 28.0507 26.4821 24.7548Z"
            fill="url(#paint0_linear_29646_46052)"
        />
        <path
            d="M30.249 44.7646C26.3492 42.2018 22.8756 39.2844 20.1648 35.2935C20.1648 35.2935 21.2528 33.7008 22.6215 32.6876C23.4668 32.0618 24.1005 31.6582 24.1005 31.6582C25.5065 33.7022 27.4893 36.0318 30.249 38.4082L34.0158 42.1749L30.249 44.7646Z"
            fill="url(#paint1_linear_29646_46052)"
        />
        <path
            d="M44.845 26.1658L40.6075 23.5762C39.4885 28.2386 37.7955 31.6821 35.6636 34.1701C32.4133 37.9637 27.8993 40.4776 27.1885 40.762C25.7759 41.2328 24.9961 40.5376 24.5253 40.3022C24.5253 40.3022 26.0931 41.775 28.1302 43.3516C30.1672 44.9282 30.4843 44.9995 30.4843 44.9995C43.1971 37.7015 44.845 26.1658 44.845 26.1658Z"
            fill="url(#paint2_linear_29646_46052)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_29646_46052"
                x1="15.4172"
                y1="18.3985"
                x2="45.5512"
                y2="22.8715"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#F26A2E" />
                <stop
                    offset="1"
                    stop-color="#FFC50C"
                />
            </linearGradient>
            <linearGradient
                id="paint1_linear_29646_46052"
                x1="20.8322"
                y1="32.2872"
                x2="27.9107"
                y2="38.4506"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#E52F25" />
                <stop
                    offset="1"
                    stop-color="#FFC60B"
                />
            </linearGradient>
            <linearGradient
                id="paint2_linear_29646_46052"
                x1="28.3656"
                y1="44.2933"
                x2="44.8451"
                y2="24.9887"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#E52F25" />
                <stop
                    offset="1"
                    stop-color="#FFC20E"
                />
            </linearGradient>
        </defs>
    </svg>
    <svg
        v-else
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 630.852 197.25"
        class="logo"
        :class="`logo--${variant}`"
        x="0px"
        y="0px"
        style="enable-background: new 0 0 107.7 34;"
        xml:space="preserve"
    >
        <g>
            <path
                class="cls-5"
                d="M0,43.7732h21.3708l20.8227,60.9616,20.823-60.9616h21.3708l-31.3712,82.1954h-21.6449L0,43.7732Z"
            />
            <path
                class="cls-5"
                d="M193.7072,125.9685h-21.3708l-5.0687-14.7951h-31.5083l-5.0687,14.7951h-21.3708l31.3712-82.1954h21.6449l31.3712,82.1954ZM141.1022,95.5563h20.8227l-10.4115-30.4124-10.4112,30.4124Z"
            />
            <path
                class="cls-5"
                d="M228.3661,125.9685V43.7732h19.8638v65.0713h36.714v17.1241h-56.5778Z"
            />
            <path
                class="cls-5"
                d="M319.1925,43.7732h66.1675v17.1241h-23.1519v65.0713h-19.8638V60.8973h-23.1519v-17.1241Z"
            />
        </g>
        <g>
            <path
                class="cls-5"
                d="M2.8083,153.5489h1.277l6.2739,15.1571,6.2737-15.1571h1.277l-6.9678,16.6562h-1.166l-6.9678-16.6562Z"
            />
            <path
                class="cls-5"
                d="M23.5449,170.2051h-1.2215v-16.6562h1.2215v16.6562Z"
            />
            <path
                class="cls-5"
                d="M34.9261,170.2051h-5.0524v-16.6562h5.0524c4.9414,0,8.6057,3.1092,8.6057,8.3282s-3.6643,8.328-8.6057,8.328ZM31.0952,154.6872v14.3798h3.8031c4.1087,0,7.3842-2.6927,7.3842-7.1899s-3.2755-7.1899-7.3842-7.1899h-3.8031Z"
            />
            <path
                class="cls-5"
                d="M58.6882,162.3489h-8.7723v6.718h10.1048v1.1381h-11.3263v-16.6562h11.3263v1.1382h-10.1048v6.5236h8.7723v1.1381Z"
            />
            <path
                class="cls-5"
                d="M63.9067,161.9326c0-4.9691,3.1925-8.7445,8.1059-8.7445,4.9414,0,8.1339,3.7754,8.1339,8.7445,0,4.8858-3.1925,8.6611-8.1339,8.6611-4.9134,0-8.1059-3.7754-8.1059-8.6611ZM78.8972,161.9326c0-4.1086-2.5261-7.5786-6.8846-7.5786-4.3028,0-6.8568,3.47-6.8568,7.5786,0,4.0252,2.5541,7.4952,6.8568,7.4952,4.3585,0,6.8846-3.47,6.8846-7.4952Z"
            />
            <path
                class="cls-5"
                d="M106.379,170.2051h-1.277l-1.8044-4.3584h-8.9388l-1.8044,4.3584h-1.277l6.9678-16.6562h1.166l6.9678,16.6562ZM94.8306,164.7086h7.9951l-3.9974-9.6606-3.9976,9.6606Z"
            />
            <path
                class="cls-5"
                d="M110.5425,163.3205v-9.7716h1.2215v9.7716c0,3.6366,1.7767,6.1073,5.2467,6.1073,3.5256,0,5.2467-2.4707,5.2467-6.1073v-9.7716h1.2215v9.7716c0,4.3584-2.3042,7.2454-6.4682,7.2454-4.1085,0-6.4682-2.887-6.4682-7.2454Z"
            />
            <path
                class="cls-5"
                d="M134.6381,170.2051h-5.0524v-16.6562h5.0524c4.9414,0,8.6057,3.1092,8.6057,8.3282s-3.6643,8.328-8.6057,8.328ZM130.8073,154.6872v14.3798h3.8031c4.1087,0,7.3842-2.6927,7.3842-7.1899s-3.2755-7.1899-7.3842-7.1899h-3.8031Z"
            />
            <path
                class="cls-5"
                d="M149.628,170.2051h-1.2215v-16.6562h1.2215v16.6562Z"
            />
            <path
                class="cls-5"
                d="M154.791,161.9326c0-4.9691,3.1925-8.7445,8.1059-8.7445,4.9414,0,8.1339,3.7754,8.1339,8.7445,0,4.8858-3.1925,8.6611-8.1339,8.6611-4.9134,0-8.1059-3.7754-8.1059-8.6611ZM169.7815,161.9326c0-4.1086-2.5261-7.5786-6.8846-7.5786-4.3028,0-6.8568,3.47-6.8568,7.5786,0,4.0252,2.5541,7.4952,6.8568,7.4952,4.3585,0,6.8846-3.47,6.8846-7.4952Z"
            />
            <path
                class="cls-5"
                d="M184.0768,170.2051v-16.6562h1.2215v15.5181h9.1054v1.1381h-10.3269Z"
            />
            <path
                class="cls-5"
                d="M208.8663,162.3489h-8.7723v6.718h10.1048v1.1381h-11.3263v-16.6562h11.3263v1.1382h-10.1048v6.5236h8.7723v1.1381Z"
            />
            <path
                class="cls-5"
                d="M228.4367,170.2051h-1.277l-1.8044-4.3584h-8.9388l-1.8044,4.3584h-1.277l6.9678-16.6562h1.166l6.9678,16.6562ZM216.8883,164.7086h7.9951l-3.9974-9.6606-3.9976,9.6606Z"
            />
            <path
                class="cls-5"
                d="M239.0132,153.5489c3.9419,0,6.246,1.9987,6.246,5.4688,0,2.8039-1.6656,4.6083-4.1362,5.1912v.0555l4.414,5.6077v.3331h-1.2493l-4.5805-5.8019h-5.6353v5.8019h-1.2215v-16.6562h6.1629ZM239.0132,154.6872h-4.9414v8.5779h5.2467c2.6928,0,4.6916-1.4435,4.6916-4.2196,0-2.8593-1.971-4.3583-4.9969-4.3583Z"
            />
            <path
                class="cls-5"
                d="M264.1631,170.2051h-1.0827l-11.2151-14.6575v14.6575h-1.2215v-16.6562h1.0827l11.2151,14.6297v-14.6297h1.2215v16.6562Z"
            />
            <path
                class="cls-5"
                d="M271.6855,170.2051h-1.2215v-16.6562h1.2215v16.6562Z"
            />
            <path
                class="cls-5"
                d="M291.5334,170.2051h-1.0827l-11.2151-14.6575v14.6575h-1.2215v-16.6562h1.0827l11.2151,14.6297v-14.6297h1.2215v16.6562Z"
            />
            <path
                class="cls-5"
                d="M304.358,161.3218h7.745v.9716c0,5.1357-2.7482,8.3003-7.2732,8.3003-4.9689,0-8.1614-3.7754-8.1614-8.6611,0-4.9691,3.248-8.7445,8.2169-8.7445,3.9699,0,5.8576,2.2208,5.8576,2.2208l-.7219.9161s-1.7767-1.971-5.1357-1.971c-4.4138,0-6.9678,3.47-6.9678,7.5786,0,4.0252,2.5263,7.4952,6.9123,7.4952,3.5811,0,5.9964-2.4707,6.0519-6.5236v-.4442h-6.5237v-1.1381Z"
            />
            <path
                class="cls-5"
                d="M323.0953,153.5489h12.7421v1.1382h-5.7743v15.518h-1.2213v-15.518h-5.7465v-1.1382Z"
            />
            <path
                class="cls-5"
                d="M339.1956,161.9326c0-4.9691,3.1925-8.7445,8.1059-8.7445,4.9414,0,8.1339,3.7754,8.1339,8.7445,0,4.8858-3.1925,8.6611-8.1339,8.6611-4.9134,0-8.1059-3.7754-8.1059-8.6611ZM354.1861,161.9326c0-4.1086-2.5261-7.5786-6.8846-7.5786-4.3028,0-6.8568,3.47-6.8568,7.5786,0,4.0252,2.5541,7.4952,6.8568,7.4952,4.3585,0,6.8846-3.47,6.8846-7.4952Z"
            />
            <path
                class="cls-5"
                d="M359.4322,161.9326c0-4.9691,3.1925-8.7445,8.1059-8.7445,4.9414,0,8.1339,3.7754,8.1339,8.7445,0,4.8858-3.1925,8.6611-8.1339,8.6611-4.9134,0-8.1059-3.7754-8.1059-8.6611ZM374.4226,161.9326c0-4.1086-2.5261-7.5786-6.8846-7.5786-4.3028,0-6.8568,3.47-6.8568,7.5786,0,4.0252,2.5541,7.4952,6.8568,7.4952,4.3585,0,6.8846-3.47,6.8846-7.4952Z"
            />
            <path
                class="cls-5"
                d="M380.8345,170.2051v-16.6562h1.2215v15.5181h9.1054v1.1381h-10.3269Z"
            />
        </g>
        <path
            class="cls-2"
            d="M628.4139,61.6667s-38.4167-30.3333-96.6667-30.3333c-42.75,0-66.3333,15.1667-66.3333,15.1667,0,0,1.5833,26.25,17,55-16.1667,9-25.3333,18.5-25.3333,18.5-24.3333-41.3333-24.3333-93-24.3333-93,0,0,41.75-27,99-27s99,27,99,27c0,0,.8574,18.1666-2.3333,34.6667Z"
        />
        <path
            class="cls-1"
            d="M531.7643,160.9206c-25.0613-19.691-41.3505-44.9206-41.3505-44.9206,0,0-13,5.6667-24.6667,19,13.2362,20.4592,33.269,38.2307,33.269,38.2307.2853.2341,6.5626,1.5501,11.531.5866s17.5501-9.8148,21.2171-12.8966Z"
        />
        <path
            class="cls-4"
            d="M624.7472,74.75c-12-10.25-27.25-16.25-27.25-16.25,0,0-8.5,41.25-32,69.75s-47.9167,42.9167-53,45c-5.0833,2.0833-13.75-.2488-13.75-.2488,0,0,29,24.2488,33,24.2488s33.75-17.5,59.25-51.25,33.75-71.25,33.75-71.25Z"
        />
        <path
            class="cls-3"
            d="M559.9565,66.8926c1.3581.3387,2.1824,1.7177,1.8134,3.0678-.9605,3.5153-3.4377,10.8358-9.4876,21.9061-6.5367,11.961-14.2297,21.1867-17.7899,25.1773-1.0327,1.1575-2.8498,1.1055-3.8135-.1101-3.6693-4.6282-12.0862-15.5265-17.7614-25.0661-5.3892-9.0589-8.3509-17.7688-9.5734-21.8785-.4056-1.3636.4097-2.7791,1.7901-3.1237,4.556-1.1373,14.9591-3.3353,27.3135-3.3353,12.4531,0,22.9815,2.2333,27.5088,3.3624Z"
        />
    </svg>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const NO_TEXT_VARIANT = 'no-text';
const PRIMARY_VARIANT = 'primary';
const LIGHT_VARIANT = 'light';

type LogoVariant = typeof NO_TEXT_VARIANT | typeof PRIMARY_VARIANT | typeof LIGHT_VARIANT;

type Props = {
    variant: LogoVariant,
}

const props = withDefaults(defineProps<Props>(), {
    variant: PRIMARY_VARIANT,
});

const isTextVariant = computed(() => props.variant === NO_TEXT_VARIANT);

</script>

<style lang="scss" scoped>
@import '../../../styles/abstracts/colors_old';

.logo {
    fill: var(--theme-color-text-primary);

    .cls-1,
    .cls-2,
    .cls-3,
    .cls-4,
    .cls-5,
    .cls-6 {
        stroke-width: 0;
    }

    &--primary .logo__name {
        fill: $primary-white;

        .cls-5 {
            fill: $primary-white;
        }
    }

    &--light .logo__name {
        fill: $primary-black;

        .cls-5 {
            fill: $primary-black;
        }
    }

    &--no-text .logo__name {
        display: none;
    }

    .cls-1 {
        fill: #ffc600;
    }

    .cls-2 {
        fill: #ff671d;
    }

    .cls-3 {
        fill: #f88d2a;
    }

    .cls-4 {
        fill: #e43d30;
    }
}
</style>
