import wowzaChildren from './wowza';
import transcribeChildren from './transcribe';
import ldapChildren from './ldap';
import ssoChildren from './sso';
import ServerIcon from '../../../../images/server.svg?component';
import ROUTE_NAMES from '../../routeNames';
import SECTION_NAMES from '../../sectionNames';
import { permissionsModule as pM } from '../../../store';

const meta = {
    title: SECTION_NAMES.SERVERS_AND_SERVICES,
    icon: ServerIcon,
};

const EntitiesList = (): Promise<unknown> => import('../../../components/EntitiesList.vue');

export default [
    {
        path: 'wowza',
        name: ROUTE_NAMES.ADMIN_MEDIA_SERVER,
        component: EntitiesList,
        redirect: { name: ROUTE_NAMES.ADMIN_MEDIA_SERVER_LIST },
        meta: {
            ...meta,
            permissions: (): string[] => [pM.allPermissions.adminServersWowza],
        },
        children: wowzaChildren,
    },
    {
        path: 'transcription-service',
        name: ROUTE_NAMES.ADMIN_TRANSCRIPTION_SERVICE,
        component: EntitiesList,
        redirect: { name: ROUTE_NAMES.ADMIN_TRANSCRIPTION_SERVICE_LIST },
        meta: {
            ...meta,
            permissions: (): string[] => [pM.allPermissions.adminServersTranscriptionService],
        },
        children: transcribeChildren,
    },
    {
        path: 'ldap-servers',
        name: ROUTE_NAMES.ADMIN_LDAP_SERVER,
        component: EntitiesList,
        redirect: { name: ROUTE_NAMES.ADMIN_LDAP_SERVER_LIST },
        meta: {
            ...meta,
            permissions: (): string[] => [pM.allPermissions.adminServersLdapServers],
        },
        children: ldapChildren,
    },
    {
        path: 'sso-configs',
        name: ROUTE_NAMES.ADMIN_SSO_CONFIG,
        component: EntitiesList,
        redirect: { name: ROUTE_NAMES.ADMIN_SSO_CONFIG_LIST },
        meta: {
            ...meta,
            permissions: () => [pM.allPermissions.adminServersSsoConfigs],
        },
        children: ssoChildren,
    },
];
