import Converter from '../Converter';
import toFilterInfoItemsContainer, {
    FilterInfoItemsContainerPartial,
    RawFilterInfoItemsContainer,
    RawFilterInfoItemsContainerC,
} from '../functions/toFilterInfoItemsContainer';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';

export default class FilterInfoItemsContainerConverter
    extends Converter<RawFilterInfoItemsContainer> {
    public canConvert(field: RawField): field is RawFilterInfoItemsContainer {
        return isType(field, RawFilterInfoItemsContainerC);
    }

    protected convertPartially(field: RawFilterInfoItemsContainer)
        : FilterInfoItemsContainerPartial {
        return toFilterInfoItemsContainer(field);
    }
}
