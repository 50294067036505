enum FIELD_RENDER_TYPES {
    AVATAR = 'avatar',
    SELECT = 'select',
    INPUT = 'input',
    INPUT_INTEGER = 'input-integer',
    INPUT_PASSWORD = 'input-password',
    TEXTAREA = 'textarea',
    FILE = 'file',
    FILE_UPLOAD = 'file-upload',
    VIDEO_UPLOAD = 'video-upload',
    MULTI_FILES = 'multi-files',
    CHECKBOX = 'checkbox',
    TUMBLER = 'tumbler',
    CHECKBOXES = 'checkboxes',
    MULTI_SELECT = 'multi-select',
    MULTI_SELECT_CHECKBOXES = 'multi-select-checkboxes',
    MULTI_SELECT_WITH_LABELS_BELOW = 'multi-select-with-labels-below',
    QUICK_BUTTON = 'quick-button',
    ARRAY = 'array',
    ARRAY_WITH_PROTOTYPE = 'array-with-prototype',
    FORM = 'form',
    SECTIONAL_FORM = 'sectional-form',
    AUDIO = 'audio',
    RADIO = 'radio',
    RADIO_TREE = 'radio-tree',
    RADIO_CHOICE = 'radio-choice',
    CHECKBOX_TREE = 'checkbox-tree',
    EVALUATION_DESCRIPTION = 'eval-description',
    EVALUATION_IMAGE = 'eval-image',
    EVALUATION_STATIC_TEXT = 'eval-static-text',
    RETENTION_RULE = 'retention-rule',
    GRID_SIZE = 'grid-size',
    DATE_TYPE = 'date-type',
    REPORT_DATE = 'report-date',
    CALENDAR = 'calendar',
    FILTER_INFO_ITEMS_CONTAINER = 'filter-info-items-container',
    EVALUATION_COLLAPSE = 'evaluation-collapse',
    FILTER = 'filter',
    SEARCH = 'search',
    COMMENT_REVIEW = 'comment-review',
    RADIO_CHECKBOX_TREE = 'radio-checkbox-tree',
    EXPIRATION = 'expiration',
    PIN_CODE = 'pin-code',
    COLOR_PICKER = 'color-picker',
    ASYNC_TYPEAHEAD = 'async-typeahead',
    TRANSCRIPTS_CARDS = 'transcripts-cards',
    ZOOM_ACCOUNT = 'zoom-account',
    SINGLE_CHOICE = 'single_choice',
    AUTHOR_CHOICE = 'author_choice',
    MULTIPLE_CHOICE = 'multiple_choice',
    RIGHTS_CHOICE = 'rights_choice',
    USERS_GROUPS_CHOICE = 'users_groups_choice',
    SCHEDULE_RULES = 'schedule_rules',
    DATE_FILTER = 'date_filter',
    PRESETS_CONTROL = 'presets_control',
    TEMPLATE_SELECTION_COLLECTION = 'template_selection_collection',
    DATE_RANGE = 'date-range',
    DIRECT_DATE_RANGE = 'direct-date-range',
    DURATION = 'duration',
    NONE = 'none',
    RESTRICT_ACCESS = 'restrict_access',
    STATIC_TEXT = 'static_text',
    STATIC_IMAGE = 'static_image',
}

export const uploadTypes: FIELD_RENDER_TYPES[] = [
    FIELD_RENDER_TYPES.FILE_UPLOAD,
    FIELD_RENDER_TYPES.VIDEO_UPLOAD,
];

export default FIELD_RENDER_TYPES;
