export const escapeHTML = (message: string): string => {
    const div = document.createElement('div');
    div.innerHTML = message;
    return div.textContent?.replaceAll(' ', ' ') ?? '';
};

export const commentMessageHTMLReplacer = (message: string) => {
    let value = message.replace(/~\[([^:]*):([^\]]*)\]/g, (_, type, name) => `<span data-type="${type}" class="comment-mention">@${name}</span>`);

    if (value.endsWith('</span>')) {
        value += '&nbsp;';
    }

    return value;
};

const commentMessageHTMLRestorer = (message: string) => {
    const value = message.replace(/<span data-type="([^"]*)" class="comment-mention">([^<]*)@([^<]*)<\/span>/g, (_, type, prefix, name) => `${prefix}~[${type}:${name}]`);

    const endsWithReplacement = /~\[[ug]:[^~]*\]$/.test(value);

    if (endsWithReplacement) {
        return `${value} `;
    }

    return value;
};

export const toRawValue = (text: string) => escapeHTML(commentMessageHTMLRestorer(text));

export const toContentEditable = (text: string) => commentMessageHTMLReplacer(
    text
        .replace(/&/g, '&amp;') // Escape ampersands
        .replace(/</g, '&lt;') // Escape less-than signs
        .replace(/>/g, '&gt;') // Escape greater-than signs
        .replace(/"/g, '&quot;') // Escape double quotes
        .replace(/'/g, '&#39;') // Escape single quotes
        .replace(/ /g, '&nbsp;') // Replace spaces with non-breaking spaces
        .replace(/\n/g, '<br>'), // Replace newlines with <br> for line breaks
);
