import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import convertForm from './convertForm';
import { assertIsNonNullable } from '../../../../typeAssertions/undefined';
import assertIsString from '../../../../typeAssertions/string';
import { RawZoomAccountField, ZoomAccountFieldPartial } from '../types/ZoomAccount';

export default (field: RawZoomAccountField): ZoomAccountFieldPartial => {
    const convertedForm = convertForm(field);
    assertIsNonNullable(convertedForm);
    assertIsNonNullable(convertedForm.fields.email);

    if (!('value' in convertedForm.fields.email)) {
        throw new Error('Incorrect field provided');
    }
    assertIsString(convertedForm.fields.email.value);

    return ({
        ...convertedForm,
        renderAs: FIELD_RENDER_TYPES.ZOOM_ACCOUNT,
        connectUrl: field.connectUrl,
        disconnectUrl: field.disconnectUrl,
        active: field.active,
    });
};
