import {
    Action, Module, Mutation, VuexModule,
} from 'vuex-module-decorators';
import * as t from 'io-ts';
import { DateFromUnixTime } from 'io-ts-types/DateFromUnixTime';
import { timerModule } from '../store-accessor';
import { UserGroup, UserGroupC } from '../../../scripts/types/UserGroup';
import { Theme } from '../../../scripts/constants/themes';
import fromEnum from '../../../scripts/io-ts/fromEnum';

export const PasswordComplexityC = t.union([
    t.type({
        enabled: t.literal(true),
        expiredAt: t.union([DateFromUnixTime, t.null]),
    }),
    t.type({
        enabled: t.literal(false),
    }),
]);

export type PasswordComplexity = t.TypeOf<typeof PasswordComplexityC>;

export const UserDetailsC = t.type({
    userName: t.string,
    inGlobalContainer: t.boolean,
    theme: fromEnum('Theme', Theme),
    userId: t.number,
    userGroupsIds: t.array(t.number),
    userGroup: UserGroupC,
    userAvatarFilePath: t.union([t.string, t.null]),
    passwordComplexity: PasswordComplexityC,
    autoPauseRecordWhenComment: t.boolean,
    autoPlayAudioComments: t.boolean,
    notificationSound: t.boolean,
});

export type UserDetails = t.TypeOf<typeof UserDetailsC>;

const PASSWORD_EXPIRED_TIMER = 'password-expiration';

const getDefaultTheme = () => {
    const isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

    if (isDark) {
        return Theme.DARK;
    }

    return Theme.DEFAULT;
};

@Module({ name: 'UserModule' })
export default class UserModule extends VuexModule {
    private _permissions: string[]|null = null;

    private _userName: string | null = null;

    private _userId: number | null = null;

    private _userGroupsIds: number[] = [];

    private _userGroup: UserGroup|null = null;

    private _userAvatarPath: string | null = null;

    private _passwordComplexity: PasswordComplexity | null = null;

    private _autoPauseRecordWhenComment = true;

    private _autoPlayAudioComments = true;

    private _notificationSoundEnabled = false;

    private _inGlobalContainer = false;

    private _theme: Theme = getDefaultTheme();

    public get permissions(): Readonly<string[]> {
        return this._permissions ?? [];
    }

    public get hasPermission() {
        return (name: string): boolean => this.permissions.includes(name);
    }

    public get hasSomePermission() {
        return (names: string[]): boolean => names.some(this.hasPermission);
    }

    public get hasEveryPermission() {
        return (names: string[]): boolean => names.every(this.hasPermission);
    }

    public get inGlobalContainer(): boolean {
        return this._inGlobalContainer;
    }

    public get theme(): Theme {
        return this._theme;
    }

    public get arePermissionsSet(): boolean {
        return this._permissions !== null;
    }

    public get userGroup(): UserGroup | null {
        return this._userGroup;
    }

    public get userName(): string | null {
        return this._userName;
    }

    public get userId(): number | null {
        return this._userId;
    }

    public get userGroupsIds(): Readonly<number[]> {
        return this._userGroupsIds;
    }

    public get userAvatarPath(): string | null {
        return this._userAvatarPath;
    }

    public get isUserLoggedIn(): boolean {
        return this._userId !== null;
    }

    public get passwordComplexity(): Readonly<PasswordComplexity> | null {
        return this._passwordComplexity;
    }

    public get isPasswordExpired(): boolean {
        if (
            !this.passwordComplexity
            || !this.passwordComplexity?.enabled
            || this.passwordComplexity?.expiredAt === null
        ) {
            return false;
        }

        return timerModule.now >= this.passwordComplexity.expiredAt;
    }

    public get isAutoPauseRecordWhenComment(): boolean {
        return this._autoPauseRecordWhenComment;
    }

    public get isAutoPlayAudioComments(): boolean {
        return this._autoPlayAudioComments;
    }

    public get isNotificationSoundEnabled(): boolean {
        return this._notificationSoundEnabled;
    }

    @Mutation
    public reset(): void {
        this._permissions = null;
        this._userName = null;
        this._userId = null;
        this._userAvatarPath = null;
        this._passwordComplexity = null;
        this._userGroup = null;
    }

    @Mutation
    public setPermissions(permissions: string[]): void {
        this._permissions = permissions;
    }

    @Mutation
    public setInGlobalContainer(value: boolean): void {
        this._inGlobalContainer = value;
    }

    @Mutation
    public setTheme(theme: Theme): void {
        this._theme = theme;
    }

    @Mutation
    public setUserName(userName: string): void {
        this._userName = userName;
    }

    @Mutation
    public setUserId(userId: number): void {
        this._userId = userId;
    }

    @Mutation
    public setUserGroup(value: UserGroup|null): void {
        this._userGroup = value;
    }

    @Mutation
    public setUserGroupsIds(userGroupsIds: number[]): void {
        this._userGroupsIds = userGroupsIds;
    }

    @Mutation
    public setUserAvatarPath(avatarPath: string | null): void {
        this._userAvatarPath = avatarPath;
    }

    @Mutation
    public setPasswordComplexity(passwordComplexity: PasswordComplexity | null): void {
        this._passwordComplexity = passwordComplexity;
    }

    @Mutation
    public setAutoPauseRecordWhenComment(autoPauseRecordWhenComment: boolean): void {
        this._autoPauseRecordWhenComment = autoPauseRecordWhenComment;
    }

    @Mutation
    public setAutoPlayAudioComments(value: boolean): void {
        this._autoPlayAudioComments = value;
    }

    @Mutation
    public setNotificationSoundEnabled(value: boolean): void {
        this._notificationSoundEnabled = value;
    }

    @Action
    public setUserDetails(details: UserDetails): void {
        this.setUserGroup(details.userGroup);
        this.setUserName(details.userName);
        this.setUserId(details.userId);
        this.setUserGroupsIds(details.userGroupsIds);
        this.setUserAvatarPath(details.userAvatarFilePath);
        this.setPasswordComplexity(details.passwordComplexity);
        this.setAutoPauseRecordWhenComment(details.autoPauseRecordWhenComment);
        this.setAutoPlayAudioComments(details.autoPlayAudioComments);
        this.setNotificationSoundEnabled(details.notificationSound);
        this.setInGlobalContainer(details.inGlobalContainer);
        this.setTheme(details.theme);

        if (this.passwordComplexity?.enabled) {
            timerModule.startTimer(PASSWORD_EXPIRED_TIMER);
        } else {
            timerModule.destroyTimer(PASSWORD_EXPIRED_TIMER);
        }
    }
}
