import isType from '../../../../io-ts/isType';
import Converter from '../Converter';
import toDateRange from '../functions/toDateRange';
import { RawDateRange, RawDateRangeC, DateRangePartial } from '../types/DateRange';
import { RawField } from '../types/RawField';

export default class DateRangeConverter extends Converter<RawDateRange> {
    public canConvert(field: RawField): field is RawDateRange {
        return isType(field, RawDateRangeC);
    }

    protected convertPartially(field: RawDateRange): DateRangePartial | null {
        return toDateRange(field);
    }
}
