import * as t from 'io-ts';
import { RecordReadyC } from './RecordReady';
import { RecordEditedC } from './RecordEdited';
import { RecordDeletedC } from './RecordDeleted';
import { RecordCutC } from './RecordCut';
import { RecordSyncedC } from './RecordSynced';
import { RecordSyncAvailabilityC } from './RecordSyncAvailability';
import { RecordConvertingC } from './RecordConverting';
import { RecordConversionProgressC } from './RecordConversionProgress';

export const RecordMessageC = t.union([
    RecordReadyC,
    RecordConvertingC,
    RecordConversionProgressC,
    RecordEditedC,
    RecordDeletedC,
    RecordCutC,
    RecordSyncedC,
    RecordSyncAvailabilityC,
]);
export type RecordMessage = t.TypeOf<typeof RecordMessageC>;

export { Type } from './Type';
export type { RecordReady } from './RecordReady';
export type { RecordEdited } from './RecordEdited';
export type { RecordDeleted } from './RecordDeleted';
export type { RecordCut } from './RecordCut';
export type { RecordSynced } from './RecordSynced';
export type { RecordSyncAvailability } from './RecordSyncAvailability';
export type { RecordConverting } from './RecordConverting';
export type { RecordConversionProgress } from './RecordConversionProgress';

export {
    RecordReadyC,
    RecordConvertingC,
    RecordConversionProgressC,
    RecordEditedC,
    RecordDeletedC,
    RecordCutC,
    RecordSyncedC,
    RecordSyncAvailabilityC,
};
