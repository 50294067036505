import Converter from '../Converter';
import toRetentionRule, {
    RawRetentionRuleField,
    RawRetentionRuleFieldC,
    RetentionRuleFieldPartial,
} from '../functions/toRetentionRule';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';

export default class RetentionRuleFieldConverter extends Converter<RawRetentionRuleField> {
    public canConvert(field: RawField): field is RawRetentionRuleField {
        return isType(field, RawRetentionRuleFieldC);
    }

    protected convertPartially(field: RawRetentionRuleField): RetentionRuleFieldPartial {
        return toRetentionRule(field);
    }
}
