import * as t from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';

export const RawQuickButtonFieldC = t.type({
    title: t.union([t.string, t.null]),
    name: t.string,
    properties: t.type({
        color: t.type({
            title: t.union([t.string, t.null]),
            name: t.string,
            type: t.literal('string'),
            default: t.string,
        }),
    }),
    type: t.literal('object'),
    widget: t.literal('quick-button'),
});

export const QuickButtonFieldPartialC = t.type({
    value: t.string,
    renderAs: t.literal(FIELD_RENDER_TYPES.QUICK_BUTTON),
});

export const QuickButtonFieldC = t.intersection([QuickButtonFieldPartialC, FieldCommonPropsC]);

export type RawQuickButtonField = t.TypeOf<typeof RawQuickButtonFieldC>
export type QuickButtonFieldPartial = t.TypeOf<typeof QuickButtonFieldPartialC>
export type QuickButtonField = t.TypeOf<typeof QuickButtonFieldC>

export default (field: RawQuickButtonField): QuickButtonFieldPartial => ({
    value: field.properties.color.default,
    renderAs: FIELD_RENDER_TYPES.QUICK_BUTTON,
});
