import { onMounted, onUnmounted } from 'vue';
import * as t from 'io-ts';
import { socket } from '../../scripts/socket';
import { decodeAsTypeC } from '../../scripts/typeAssertions/isTypeC';

export enum Channel {
    RECORDING = 'recording:status',
    RECORDING_PUBLIC = 'recording:status:public',
    STREAM = 'stream:status',
    RECORD = 'record',
    RECORD_CUT = 'record:cut',
    SCHEDULE = 'schedule',
    REPORT = 'report',
    EXCEPTION = 'exception',
    MOSAIC_UPDATE = 'mosaic:update',
    ENTITY_UPDATE = 'entity:update',
    TRANSCRIPT = 'transcript:status',
    COMMENT_ADD = 'comment:add',
    COMMENT_EDIT = 'comment:edit',
    COMMENT_DELETE = 'comment:delete',
    NOTIFICATION = 'notification',
    SETTINGS = 'settings',
    ROOM_EDIT = 'room:edit',
    ROOM_ADD = 'room:add',
    ROOM_DELETE = 'room:delete',
    WOWZA_LOW_SPACE = 'wowza:low-space',
    USER_LOGOUT_SOON = 'user:logout-soon',
}

type Handler = (json: string) => void;

export const useSocket = (channel: Channel, handler: Handler) => {
    onMounted(() => {
        socket.on(channel, handler);
    });

    onUnmounted(() => {
        socket.off(channel, handler);
    });
};

type TypedHandler<T extends t.Any> = (message: t.TypeOf<T>) => void;

export const useTypedSocket = <T extends t.Any>(
    channel: Channel,
    type: T,
    handler: TypedHandler<T>,
) => {
    const typedHandler = (json: string): void => {
        const parsedJson = JSON.parse(json);
        const message = decodeAsTypeC(parsedJson, type);

        handler(message);
    };

    return useSocket(channel, typedHandler);
};
