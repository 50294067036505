import ListenerProvider, { Listener } from './ListenerProvider';

export default class ButtonKeyListener extends ListenerProvider {
    private started = false;

    public constructor(
        private readonly condition: (event: KeyboardEvent) => boolean,
        private readonly handler: Listener,
    ) {
        super();

        this.onStartEvent = this.onStartEvent.bind(this);
        this.onEndEvent = this.onEndEvent.bind(this);
    }

    public registerListener(): void {
        document.addEventListener('keydown', this.onStartEvent);
        document.addEventListener('keyup', this.onEndEvent);
    }

    public unregisterListener(): void {
        document.removeEventListener('keydown', this.onStartEvent);
        document.removeEventListener('keyup', this.onEndEvent);
    }

    protected onStartEvent(event: KeyboardEvent): void {
        if (this.condition(event)) {
            this.started = true;
        }
    }

    protected onEndEvent(event: KeyboardEvent): void {
        if (!this.started || !this.condition(event)) {
            return;
        }

        this.started = false;
        this.handler(event);
    }
}
