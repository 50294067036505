<template>
    <div
        class="user-info-pop-up"
    >
        <div class="user-name">
            {{ userName }}
        </div>
        <div class="buttons">
            <div
                v-for="(button, i) in userInfoPopupButtons"
                :key="i"
                class="buttons-wrapper"
                @click="hide"
            >
                <Tooltip
                    :id="`user-info-popup-button-${button.cyName}`"
                    :title="toCapitalize(button.cyName)"
                    no-min-width
                    placement="top"
                >
                    <Button
                        variant="light-on-primary"
                        has-only-icon
                        :aria-label="button.cyName"
                        :block="false"
                        :to="button.to"
                        :data-cy="`user-info-popup-button-${button.cyName}`"
                        size="sm"
                    >
                        <Component :is="button.inlinedSvg" />
                    </Button>
                </Tooltip>
                <div
                    v-if="button.isNotificationButton && unreadAlertsCount > 0"
                    class="buttons-wrapper__notifications-dot"
                    data-cy="user-info-notification-dot"
                />
            </div>
        </div>
        <div class="user-info-pop-up__tail" />
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { RouteLocationRaw } from 'vue-router';
import { VueElementConstructor } from 'vue';
import { capitalize } from 'lodash';
import PencilIcon from '../../../images/pencil.svg?component';
import WarningIcon from '../../../images/warning.svg?component';
import QuestionIcon from '../../../images/question-bubble.svg?component';
import ExitIcon from '../../../images/exit.svg?component';
import Button from '../Elements/Button.vue';
import hasPermissionForRoute from '../../../scripts/helpers/functions/permissions/hasPermissionForRoute';
import ROUTE_NAMES from '../../routes/routeNames';
import Tooltip from '../Elements/Tooltip.vue';

const userInfoPopupIcons: Record<string, VueElementConstructor> = {
    PencilIcon, WarningIcon, QuestionIcon, ExitIcon,
};

type UserInfoPopupButton = {
    inlinedSvg: VueElementConstructor,
    isNotificationButton?: true,
    to: RouteLocationRaw,
    cyName: string,
}

@Options({
    components: {
        Button,
        PencilIcon,
        WarningIcon,
        QuestionIcon,
        ExitIcon,
        Tooltip,
    },
    methods: {
        capitalize,
    },
})
export default class TheUserInfoPopup extends Vue {
    private readonly allUserInfoPopupButtons: UserInfoPopupButton[] = [
        {
            inlinedSvg: userInfoPopupIcons.PencilIcon,
            to: { name: ROUTE_NAMES.USER_SETTINGS },
            cyName: 'settings',
        },
        {
            inlinedSvg: userInfoPopupIcons.WarningIcon,
            isNotificationButton: true,
            to: { name: ROUTE_NAMES.ALERTS },
            cyName: 'alerts',
        },
        {
            inlinedSvg: userInfoPopupIcons.QuestionIcon,
            to: { name: ROUTE_NAMES.HELP },
            cyName: 'help',
        },
        {
            inlinedSvg: userInfoPopupIcons.ExitIcon,
            to: { name: ROUTE_NAMES.LOGOUT },
            cyName: 'logout',
        },
    ]

    @Prop({ type: String, required: true })
    public readonly userName!: string;

    @Prop({ type: Number, required: true })
    public readonly unreadAlertsCount!: number

    public get userInfoPopupButtons(): UserInfoPopupButton[] {
        return this.allUserInfoPopupButtons
            .filter(({ to }) => hasPermissionForRoute(to));
    }

    public hide(): void {
        this.$emit('hide');
    }

    public toCapitalize(text: string): string {
        return capitalize(text);
    }
}
</script>

<style lang="scss" scoped>
/* stylelint-disable selector-max-compound-selectors */
@import '../../../styles/vendors/breakpoints';
@import '../../../styles/abstracts/mixins';
@import '../../../styles/abstracts/spacings';
@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/z-indexes';
@import '../../../styles/abstracts/colors_old';

$padding-desktop: $spacing-l;
$margin-desktop: $spacing-xs;

:deep .custom-switch-on-primary .custom-control-label {
    &::before {
        background-color: var(--theme-color-button-icon-secondary);
    }

    &::after {
        background-color: var(--theme-color-text-inversed);
    }
}

:deep .custom-switch.b-custom-control-lg .custom-control-label {
    &::after {
        top: 0.05rem;
        left: -3.4rem;
    }

    &::before {
        border: 0;
    }
}

:deep .custom-switch-on-primary .custom-control-input {
    &:checked ~ .custom-control-label::before {
        background-color: var(--theme-color-surface-accent-primary);
    }

    &:focus ~ .custom-control-label::before {
        box-shadow: var(--shadow-button-focus) var(--theme-color-primary-shadow);
    }
}

.user-info-pop-up {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $user-info-popup-z-index;

    padding-top: $spacing-xl;
    padding-right: $spacing-xl;
    padding-left: $spacing-xl;

    color: var(--theme-color-text-primary);

    background-color: var(--theme-color-surface-primary-default);
    border-top-left-radius: $border-radius-lg;
    border-top-right-radius: $border-radius-lg;

    box-shadow: 0 6px 20px 6px rgba(40, 40, 45, 0.28);

    @include media-breakpoint-up(xs) {
        left: 3.75rem;

        align-self: flex-end;

        width: 11.75rem;
        height: fit-content;
        padding: $padding-desktop;

        margin: $margin-desktop;

        border-radius: $border-radius-lg;
    }
}

.user-name {
    padding-bottom: $spacing-xl;

    overflow: hidden;

    font-weight: bold;

    text-overflow: ellipsis;
    white-space: nowrap;

    @include media-breakpoint-up(xs) {
        padding-bottom: $spacing-m;
    }
}

.buttons {
    display: flex;

    justify-content: space-between;
}

.buttons-wrapper {
    position: relative;

    &__notifications-dot {
        @include notification-dot(1rem, var(--theme-color-surface-primary-default));
        border-color: var(--theme-color-surface-primary-default);
        @include media-breakpoint-up(xs) {
            @include notification-dot(0.625rem, var(--theme-color-surface-primary-default));
        }
    }
}

.night-mode-block {
    display: flex;

    justify-content: space-between;

    padding-top: $spacing-xl;
    padding-bottom: $spacing-xl;

    color: var(--theme-color-text-quatriary);

    border-top: 1px solid var(--theme-color-button-secondary);

    @include media-breakpoint-up(xs) {
        padding-top: $spacing-l;
        padding-bottom: 0;
    }
}

.user-info-pop-up__tail {
    /* see https://css-tricks.com/snippets/css/css-triangle/ */
    @include media-breakpoint-up(xs) {
        $height-to-width-ratio: 3.2;

        $tail-width: $margin-desktop/2;
        $tail-height: $tail-width * $height-to-width-ratio;

        position: absolute;
        bottom: $margin-desktop + $padding-desktop;
        left: -($tail-width * 2);

        border-top: $tail-height/2 solid transparent;
        border-bottom: $tail-height/2 solid transparent;
        border-left: $tail-width solid transparent;
    }
}
</style>
