import Converter from '../Converter';
import toQuickButton, {
    QuickButtonFieldPartial,
    RawQuickButtonField,
    RawQuickButtonFieldC,
} from '../functions/toQuickButton';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';

export default class QuickButtonFieldConverter extends Converter<RawQuickButtonField> {
    public canConvert(field: RawField): field is RawQuickButtonField {
        return isType(field, RawQuickButtonFieldC);
    }

    protected convertPartially(field: RawQuickButtonField): QuickButtonFieldPartial {
        return toQuickButton(field);
    }
}
