import Converter from '../Converter';
import toAudioFormat, {
    AudioFieldPartial,
    RawAudioField,
    RawAudioFieldC,
} from '../functions/toAudioFormat';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';

export default class AudioFieldConverter extends Converter<RawAudioField> {
    public canConvert(field: RawField): field is RawAudioField {
        return isType(field, RawAudioFieldC);
    }

    protected convertPartially(field: RawAudioField): AudioFieldPartial {
        return toAudioFormat(field);
    }
}
