import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import toMultipleChoice, {
    MultipleChoicePartialWithoutRendererC,
    RawMultipleChoiceWithoutRendererC,
} from './toMultipleChoice';
import {
    Choice, ParentChoice, ParentChoiceC, transformToParent,
} from '../types/Choice';
import isType from '../../../../io-ts/isType';

export const RawRightsChoiceC = t.intersection([
    RawMultipleChoiceWithoutRendererC,
    t.type({
        renderer: t.literal(FIELD_RENDER_TYPES.RIGHTS_CHOICE),
    }),
]);
export const RIGHTS_DELIMITER = '--';

export const RightsChoicePartialC = t.intersection([
    MultipleChoicePartialWithoutRendererC,
    t.type({
        renderAs: t.literal(FIELD_RENDER_TYPES.RIGHTS_CHOICE),
    }),
]);

export type RawRightsChoice = TypeOf<typeof RawRightsChoiceC>;

export const RightsChoiceC = t.intersection(
    [RightsChoicePartialC, FieldCommonPropsC],
);

export type RightsChoicePartial = TypeOf<typeof RightsChoicePartialC>;
export type RightsChoice = TypeOf<typeof RightsChoiceC>;

const calculateTreeLevel = (title: string): number => {
    const matches = title.match(new RegExp(RIGHTS_DELIMITER, 'g'));
    return matches ? matches.length : 0;
};

const findPreviousParentNode = (
    filteredChoice: Choice,
    filteredChoiceIndex: number,
    currentChoiceIndex:number,
    currentLevel: number,
): boolean => filteredChoiceIndex < currentChoiceIndex
    && calculateTreeLevel(filteredChoice.title) < currentLevel;

const trimTitles = (choicesToTrim: Choice[]) => choicesToTrim
    .map((choice: Choice | ParentChoice) => {
        choice.title = choice.title.replaceAll(RIGHTS_DELIMITER, '').trim();
        if (isType(choice, ParentChoiceC)) {
            choice.children = trimTitles(choice.children);
        }
        return choice;
    });

const transformByParent = (choices: Choice[]): ParentChoice[] => {
    const result: ParentChoice[] = [];
    const myMap = new Map(choices.map((item) => [item.value, transformToParent(item)]));
    choices.forEach((choice: Choice, index: number) => {
        const currentLevel = calculateTreeLevel(choice.title);
        const parentNodes = choices
            .filter((val, key) => findPreviousParentNode(val, key, index, currentLevel));
        const transformedChoice = transformToParent(choice);

        if (parentNodes.length) {
            const parentNodeValue = parentNodes[parentNodes.length - 1]?.value;
            const parentNode = myMap.get(parentNodeValue);

            if (parentNode) {
                parentNode.children.push(transformedChoice);
            }
        } else {
            result.push(transformedChoice);
        }
    });

    return trimTitles(result) as ParentChoice[];
};

export default (field: RawRightsChoice): RightsChoicePartial => {
    const multipleChoice = toMultipleChoice(field);

    return ({
        renderAs: FIELD_RENDER_TYPES.RIGHTS_CHOICE,
        choices: transformByParent(multipleChoice.choices),
    });
};
