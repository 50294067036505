import CameraIcon from '../../../../images/camera.svg?component';
import ROUTE_NAMES from '../../routeNames';
import SECTION_NAMES from '../../sectionNames';
import { permissionsModule as pM } from '../../../store';

const meta = {
    title: SECTION_NAMES.ROOMS_AND_CAMERAS,
    permissions: (): string[] => [pM.allPermissions.adminRooms],
    icon: CameraIcon,
};

const AdminStreamView = () => import('../../../views/admin/AdminCamera/AdminStreamView.vue');
export default [
    {
        path: 'stream/new',
        name: ROUTE_NAMES.ADMIN_STREAM_NEW,
        component: AdminStreamView,
        meta: { new: true, ...meta },
    },
    {
        path: 'stream/edit/:id',
        name: ROUTE_NAMES.ADMIN_STREAM_VIEW,
        component: AdminStreamView,
        meta: { new: false, ...meta },
    },
];
