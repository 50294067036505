import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import * as t from 'io-ts';

export const AllPermissionsC = t.record(t.string, t.string);

export const UserPermissionsC = t.array(t.string);

export const ResponsePermissionsC = t.type({
    all: AllPermissionsC,
    user: UserPermissionsC,
});

export type PermissionsResponse = t.TypeOf<typeof ResponsePermissionsC>;

export type AllPermissions = t.TypeOf<typeof AllPermissionsC>;

@Module({ name: 'PermissionsModule' })
export default class PermissionsModule extends VuexModule {
    public _allPermissions: AllPermissions = {};

    public get allPermissions(): AllPermissions {
        return this._allPermissions;
    }

    @Mutation
    public setAllPermissions(permissions: AllPermissions): void {
        this._allPermissions = permissions;
    }

    public get areAllPermissionsSet(): boolean {
        return Object.keys(this._allPermissions).length !== 0;
    }
}
