import CameraIcon from '../../../../images/camera.svg?component';
import ROUTE_NAMES from '../../routeNames';
import SECTION_NAMES from '../../sectionNames';
import { permissionsModule as pM } from '../../../store';

const meta = {
    title: SECTION_NAMES.ROOMS_AND_CAMERAS,
    permissions: (): string[] => [pM.allPermissions.adminRooms],
    icon: CameraIcon,
};

const AdminAcapCameraView = () => import('../../../views/admin/AdminCamera/AdminAcapCameraView.vue');

export default [
    {
        path: 'edge-camera/new',
        name: ROUTE_NAMES.ADMIN_ACAP_CAMERA_NEW,
        component: AdminAcapCameraView,
        meta: { new: true, ...meta },
    },
    {
        path: 'edge-camera/edit/:id',
        name: ROUTE_NAMES.ADMIN_ACAP_CAMERA_VIEW,
        component: AdminAcapCameraView,
        meta: { new: false, ...meta },
    },
];
