import ServerIcon from '../../../../images/server.svg?component';
import ROUTE_NAMES from '../../routeNames';
import SECTION_NAMES from '../../sectionNames';
import { permissionsModule } from '../../../store';

const permissions = permissionsModule.allPermissions;

const meta = {
    title: SECTION_NAMES.SERVERS_AND_SERVICES,
    icon: ServerIcon,
    permissions: (): string[] => [permissions.adminServersTranscriptionService],
};

const AdminTranscriptionServerForm = (): Promise<unknown> => import('../../../views/admin/Servers/Transcription/AdminTranscriptionServerForm.vue');
export default [
    {
        path: '',
        name: ROUTE_NAMES.ADMIN_TRANSCRIPTION_SERVICE_LIST,
        component: (): Promise<unknown> => import('../../../views/admin/Servers/Transcription/AdminTranscriptionServerTable.vue'),
        meta,
    },
    {
        path: 'edit/:id',
        name: ROUTE_NAMES.ADMIN_TRANSCRIPTION_SERVICE_VIEW,
        component: AdminTranscriptionServerForm,
        meta,
    },
    {
        path: 'new',
        name: ROUTE_NAMES.ADMIN_TRANSCRIPTION_SERVICE_NEW,
        component: AdminTranscriptionServerForm,
        meta,
    },
];
