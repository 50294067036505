import { Any } from 'io-ts';
import { AxiosRequestConfig, Method } from 'axios';
import Request from '../Request';
import { assertIsDefined } from '../../../typeAssertions/undefined';
import jsonToFormData from '../Converters/jsonToFormData';
import standardPlus400 from '../../requestStatusValidators';

export enum Significance {Main, Secondary, Invisible}

export enum ErrorHandler {Default, None}

type ParamValue = string|number|null;
type Params = Record<string, ParamValue | ParamValue[]>;

export default class RequestBuilder<T extends Any> {
    private url?: string;

    private method?: Method;

    private significance: Significance = Significance.Main;

    private errorHandler: ErrorHandler = ErrorHandler.Default;

    private sendAsForm = false;

    private params?: Params;

    private formData?: FormData;

    private sendAsJson = false;

    private json?: AxiosRequestConfig['data'];

    private headers?: Record<string, string>

    private is400Allowed = false;

    private is403Allowed = false;

    private signal: AbortSignal | null = null;

    constructor(
        private readonly responseType: T,
    ) {
    }

    public build(): Request<T> {
        assertIsDefined(this.url, 'Url');
        assertIsDefined(this.method, 'Method');

        const config: AxiosRequestConfig = {
            method: this.method,
            url: this.url,
        };

        if (this.sendAsForm) {
            assertIsDefined(this.formData);
            config.data = this.formData;
        }

        if (this.sendAsJson) {
            assertIsDefined(this.json);
            config.data = this.json;
        }

        if (this.params) {
            config.params = this.params;
        }

        if (this.is400Allowed) {
            config.validateStatus = standardPlus400;
        }

        config.headers = this.headers;

        if (this.signal) {
            config.signal = this.signal;
        }

        return new Request(
            config,
            this.significance,
            this.errorHandler,
            this.responseType,
            this.is403Allowed,
        );
    }

    public setUrl(url: string): this {
        this.url = url;
        return this;
    }

    public setMethod(method: Method): this {
        this.method = method;
        return this;
    }

    public setQuery(query: Record<string, string>): this {
        this.setSendAsForm();
        this.formData = jsonToFormData(query);
        return this;
    }

    public setParams(params: Params): this {
        this.params = params;
        return this;
    }

    public setSignificance(type: Significance): this {
        this.significance = type;
        return this;
    }

    public setErrorHandler(type: ErrorHandler): this {
        this.errorHandler = type;
        return this;
    }

    public setSignal(signal: AbortSignal|null): this {
        this.signal = signal;
        return this;
    }

    public setFormData(formData: FormData): this {
        this.setSendAsForm();
        this.formData = formData;
        return this;
    }

    public setJson(json: AxiosRequestConfig['data']): this {
        this.setSendAsJson();
        this.json = json;
        return this;
    }

    public allow400(): this {
        this.is400Allowed = true;
        return this;
    }

    public allow403(): this {
        this.is403Allowed = true;
        return this;
    }

    private setSendAsForm(): this {
        if (!this.method) {
            this.setMethod('post');
        }
        this.sendAsForm = true;
        this.headers = { 'Content-Type': 'multipart/form-data' };
        return this;
    }

    private setSendAsJson(): this {
        if (!this.method) {
            this.setMethod('post');
        }
        this.sendAsJson = true;
        this.headers = { 'Content-Type': 'application/json' };
        return this;
    }
}
