<template>
    <ContainerPlaceholder>
        <template #icon>
            <BellIcon
                width="42"
                height="42"
                class="icon"
            />
        </template>
        <template #title>
            <h3
                v-if="title"
                class="placeholder-title"
            >
                {{ title }}
            </h3>
        </template>
    </ContainerPlaceholder>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import BellIcon from '../../../images/bell.svg?component';
import ContainerPlaceholder from '../Elements/placeholders/ContainerPlaceholder.vue';

@Options({
    components: {
        ContainerPlaceholder,
        BellIcon,
    },
})
export default class NoNotificationsPlaceholder extends Vue {
    @Prop({
        type: String,
        default: 'No comments yet',
    })
    public readonly title!: string;
}
</script>

<style lang="scss" scoped>
@import '../../../styles/abstracts/spacings';
@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/font-sizes';

.placeholder-title {
    font-size: $font-size-lg;

    font-weight: 700;
}

.icon {
    padding: $spacing-xs;

    border-radius: 8px;
    box-shadow: 0 12px 24px -4px rgba(40, 40, 45, 0.1);

    fill: currentColor;
}
</style>
