import * as t from 'io-ts';
import { RoutingSymfony } from '../../player/mobile/helpers/routing';
import RequestBuilder, { Significance } from '../../helpers/Request/Builder/RequestBuilder';
import type { ColorOption } from '../../../vue/components/Elements/ColorPicker/types/ColorOption';

const COLORS_ROUTE = 'api_colors';

const ColorsResponseC = t.type({
    colors: t.record(t.string, t.string),
    defaultColor: t.string,
});

class ColorsApi {
    public async getColors(): Promise<{
        colors: ColorOption[],
        defaultColor: string,
    }> {
        const colorsUrl = RoutingSymfony.generate(COLORS_ROUTE);

        const response = await new RequestBuilder(ColorsResponseC)
            .setSignificance(Significance.Invisible)
            .setMethod('GET')
            .setUrl(colorsUrl)
            .build()
            .send();

        const { defaultColor } = response;
        const colors = Object.entries(response.colors)
            .map(([name, value]) => ({ name, value }));

        return {
            colors,
            defaultColor,
        };
    }
}

export default new ColorsApi();
