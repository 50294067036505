/**
 * @see src/CamBundle/Message/Websocket/Record/RecordReady.php
 */

import * as t from 'io-ts';
import { UUID } from 'io-ts-types/UUID';
import { Type } from './Type';

export const RecordReadyC = t.type({
    type: t.literal(Type.READY),
    id: UUID,
});

export type RecordReady = t.TypeOf<typeof RecordReadyC>;
