import { RoutingSymfony } from '../../player/mobile/helpers/routing';
import RequestBuilder, {
    ErrorHandler,
    Significance,
} from '../../helpers/Request/Builder/RequestBuilder';
import { PermissionsResponse, ResponsePermissionsC } from '../../../vue/store/modules/permissions';
import { UserDetails, UserDetailsC } from '../../../vue/store/modules/user';
import { GlobalSettings, GlobalSettingsC } from '../../../vue/store/modules/globalSettings';

const APP_ROUTE = {
    PERMISSIONS: 'api_get_all_rights',
    USER_DETAILS: 'api_user_details',
    GLOBAL_SETTINGS: 'api_global_settings',
} as const;

class AppApi {
    public async getPermissions(): Promise<PermissionsResponse> {
        const url = RoutingSymfony.generate(APP_ROUTE.PERMISSIONS);
        return new RequestBuilder(ResponsePermissionsC)
            .setUrl(url)
            .allow403()
            .setErrorHandler(ErrorHandler.None)
            .setSignificance(Significance.Invisible)
            .setMethod('GET')
            .build()
            .send();
    }

    public async getUserDetails(): Promise<UserDetails> {
        const url = RoutingSymfony.generate(APP_ROUTE.USER_DETAILS);
        return new RequestBuilder(UserDetailsC)
            .setUrl(url)
            .setMethod('GET')
            .setSignificance(Significance.Invisible)
            .setErrorHandler(ErrorHandler.None)
            .build()
            .send();
    }

    public async getGlobalSettings(): Promise<GlobalSettings> {
        const url = RoutingSymfony.generate(APP_ROUTE.GLOBAL_SETTINGS);
        return new RequestBuilder(GlobalSettingsC)
            .setUrl(url)
            .setSignificance(Significance.Invisible)
            .setMethod('GET')
            .build()
            .send();
    }
}

export default new AppApi();
