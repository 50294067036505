import { BvToast } from 'bootstrap-vue';
import { getCurrentInstance } from 'vue';

export type Toaster = ReturnType<typeof useToaster>;

export interface Toast {
    title?: string,
    message: string,
}

enum ToastVariant {
    ERROR = 'danger',
    WARNING = 'warning',
    SUCCESS = 'success',
}

export function useToaster() {
    const currentInstance = getCurrentInstance();
    const getBvToast = (): BvToast => (currentInstance as any).ctx._bv__toast;

    const makeToast = (
        { title, message, variant }: Toast & { variant: ToastVariant},
    ) => {
        getBvToast().toast(message, {
            solid: true,
            title,
            variant,
        });
    };

    const makeErrorToast = (props: Toast) => {
        makeToast({ ...props, title: props.title ?? 'Error!', variant: ToastVariant.ERROR });
    };

    const makeSuccessToast = (props: Toast) => {
        makeToast({ ...props, title: props.title ?? 'Success!', variant: ToastVariant.SUCCESS });
    };

    const makeWarningToast = (props: Toast) => {
        makeToast({ ...props, title: props.title ?? 'Warning!', variant: ToastVariant.WARNING });
    };

    return {
        makeErrorToast,
        makeSuccessToast,
        makeWarningToast,
    };
}
