import ListenerProvider, { Listener } from './ListenerProvider';
import SmartClickListener from './SmartClickListener';

export type Target = string | (() => string) | HTMLElement | (() => HTMLElement | undefined);

export default class ClickOutsideListener extends ListenerProvider {
    private clickListener!: SmartClickListener;

    public constructor(
        private readonly targets: Target[],
        private readonly handler: Listener,
    ) {
        super();

        this.onSimpleEvent = this.onSimpleEvent.bind(this);
        this.createClickListener();
    }

    protected createClickListener(): void {
        const condition = (event: Event) => (
            this.isClickedOutsideElement(event.target as HTMLElement)
        );

        this.clickListener = new SmartClickListener(condition, this.handler);
    }

    public registerListener(): void {
        this.clickListener.registerListener();
        document.addEventListener('touchstart', this.onSimpleEvent);
    }

    public unregisterListener(): void {
        this.clickListener.unregisterListener();
        document.removeEventListener('touchstart', this.onSimpleEvent);
    }

    protected onSimpleEvent(event: Event): void {
        const element = event.target as HTMLElement;

        if (!this.isClickedOutsideElement(element)) {
            return;
        }

        this.handler(event);
    }

    protected isClickedOutsideElement(element: HTMLElement): boolean {
        return this.targets
            .filter((target) => !!target)
            .every((target) => {
                if (target instanceof HTMLElement) {
                    return !target.contains(element);
                }

                const computedTarget = typeof target === 'function' ? target() : target;

                if (!computedTarget) {
                    return false;
                }

                if (computedTarget instanceof HTMLElement) {
                    return !computedTarget.contains(element);
                }

                const selector = `[id='${computedTarget}']`;

                return element.closest(selector) === null;
            });
    }
}
