<template>
    <a
        class="hamburger"
        @click.stop="showMenu"
    >
        <MenuIcon />
    </a>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { menuModule } from '../../store';
import MenuIcon from '../../../images/hamburger.svg?component';

@Options({
    components: { MenuIcon },
})
export default class Hamburger extends Vue {
    showMenu(): void {
        menuModule.showMenu();
    }
}
</script>

<style lang="scss" scoped>
.hamburger {
    display: block;

    width: 1.75rem;
    height: 1.75rem;

    cursor: pointer;
}
</style>
