import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import convertForm from './convertForm';
import { FormFieldPartialWithoutRenderAsC, RawFormFieldC } from '../types/FormField';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import { assertIsNonNullable } from '../../../../typeAssertions/undefined';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';
import { StaticTextFieldC } from './toStaticText';

export const RawEvaluationStaticTextFieldC = t.intersection([
    RawFormFieldC,
    t.type({
        attr: t.type({
            default_data: t.string,
        }),
    }),
]);

export const EvaluationStaticTextFieldPartialC = t.intersection([
    FormFieldPartialWithoutRenderAsC,
    t.type({
        fields: t.type({
            value: FieldCommonPropsC,
            description: StaticTextFieldC,
        }),
        renderAs: t.literal(FIELD_RENDER_TYPES.EVALUATION_STATIC_TEXT),
    }),
]);

export const EvaluationStaticTextFieldC = t.intersection(
    [EvaluationStaticTextFieldPartialC, FieldCommonPropsC],
);

export type RawEvaluationStaticTextField = TypeOf<typeof RawEvaluationStaticTextFieldC>
export type EvaluationStaticTextFieldPartial = TypeOf<typeof EvaluationStaticTextFieldPartialC>
export type EvaluationStaticTextField = TypeOf<typeof EvaluationStaticTextFieldC>

export default (field: RawEvaluationStaticTextField): EvaluationStaticTextFieldPartial => {
    const converted = convertForm(field);
    assertIsNonNullable(converted);
    if (!StaticTextFieldC.is(converted.fields.description)) {
        throw new Error();
    }
    assertIsNonNullable(converted.fields.value);

    const description = {
        ...converted.fields.description,
        value: converted.fields.description.value || field.attr.default_data,
        title: '',
    };

    const value = {
        ...converted.fields.value,
        title: 'Enter value:',
    };

    return {
        ...converted,
        fields: {
            ...converted.fields,
            description,
            value,
        },
        renderAs: FIELD_RENDER_TYPES.EVALUATION_STATIC_TEXT,
    };
};
