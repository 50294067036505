import Converter from '../Converter';
import toInputFormat, {
    RawInputField,
    RawInputFieldC,
    InputFieldPartial,
} from '../functions/toInputFormat';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';

export default class InputFieldConverter extends Converter<RawInputField> {
    public canConvert(field: RawField): field is RawInputField {
        return isType(field, RawInputFieldC);
    }

    protected convertPartially(field: RawInputField): InputFieldPartial {
        return toInputFormat(field);
    }
}
