import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';
import { FormFieldPartialWithoutRenderAsC, RawFormFieldC } from './FormField';
import { SectionType, SectionTypeC } from '../../../../types/sections';

export const RawSectionalFormC = t.intersection([
    RawFormFieldC,
    t.type({ renderer: t.literal(FIELD_RENDER_TYPES.SECTIONAL_FORM) }),
]);

export const SectionalFormPartialC = t.intersection([
    FormFieldPartialWithoutRenderAsC,
    t.type({
        renderAs: t.literal(FIELD_RENDER_TYPES.SECTIONAL_FORM),
        section: SectionTypeC,
    }),
]);

export const SectionalFormC = t.intersection([SectionalFormPartialC, FieldCommonPropsC]);

export type RawSectionalForm = TypeOf<typeof RawSectionalFormC>;
export type SectionalFormPartial = TypeOf<typeof SectionalFormPartialC>;
export type SectionalForm = TypeOf<typeof SectionalFormC>;

export const defaultSection = SectionType.INFO;
