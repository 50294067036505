import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import toSingleChoice, {
    RawSingleChoiceWithoutRendererC,
    SingleChoicePartialWithoutRendererC,
} from './toSingleChoice';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';

const RendererC = t.union([
    t.literal(FIELD_RENDER_TYPES.RADIO_CHOICE),
    t.literal(FIELD_RENDER_TYPES.RADIO_TREE),
    t.literal(FIELD_RENDER_TYPES.AUTHOR_CHOICE),
]);

export const RawRadioChoiceC = t.intersection([
    RawSingleChoiceWithoutRendererC,
    t.type({ renderer: RendererC }),
    t.partial({
        attr: t.partial({
            readonly: t.boolean,
        }),
    }),
]);

export type RawRadioChoice = TypeOf<typeof RawRadioChoiceC>;

const RadioChoicePartialC = t.intersection([
    SingleChoicePartialWithoutRendererC,
    t.type({
        renderAs: RendererC,
        readonly: t.boolean,
    }),
]);

export type RadioChoicePartial = TypeOf<typeof RadioChoicePartialC>;

export const RadioChoiceC = t.intersection([
    RadioChoicePartialC,
    FieldCommonPropsC,
]);

export type RadioChoice = TypeOf<typeof RadioChoiceC>;

export default (field: RawRadioChoice): RadioChoicePartial => {
    const singleChoices = toSingleChoice(field);

    return {
        renderAs: field.renderer,
        choices: singleChoices.choices,
        readonly: Boolean(field.attr?.readonly),
    };
};
