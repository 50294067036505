/**
 * @see src/CamBundle/Message/Websocket/Record/RecordEdited.php
 */

import * as t from 'io-ts';
import { UUID } from 'io-ts-types/UUID';
import { Type } from './Type';

export const RecordEditedC = t.type({
    type: t.literal(Type.EDIT),
    id: UUID,
});

export type RecordEdited = t.TypeOf<typeof RecordEditedC>;
