import * as t from 'io-ts';
import {
    Module, Action, Mutation, VuexModule,
} from 'vuex-module-decorators';
import { socket } from '../../../scripts/socket';
import { decodeAsTypeC } from '../../../scripts/typeAssertions/isTypeC';
import { SettingsC } from '../../../scripts/types/sockets/settings';
import { Type } from '../../../scripts/types/sockets/settings/Type';

export const SyncSettingsC = t.type({
    enabled: t.boolean,
});

type SyncSettings = t.TypeOf<typeof SyncSettingsC>;

const SETTINGS_CHANNEL = 'settings';

@Module({ name: 'SyncModule' })
export default class SyncModule extends VuexModule {
    private syncEnabled = false;

    public get isSyncEnabled(): boolean {
        return this.syncEnabled;
    }

    @Mutation
    private setSyncEnabled(enabled: boolean): void {
        this.syncEnabled = enabled;
    }

    @Action
    public configureSync({ enabled }: SyncSettings): void {
        this.setSyncEnabled(enabled);
        this.subscribe();
    }

    @Mutation
    private subscribe(): void {
        socket.on(SETTINGS_CHANNEL, (json: string) => {
            const message = JSON.parse(json);
            const settings = decodeAsTypeC(message, SettingsC);

            if (settings.type === Type.SYNC_SWITCHED) {
                this.syncEnabled = settings.enabled;
            }
        });
    }

    @Action
    public unconfigureSync(): void {
        this.setSyncEnabled(false);
        this.unsubscribe();
    }

    @Mutation
    private unsubscribe(): void {
        socket.off(SETTINGS_CHANNEL);
    }
}
