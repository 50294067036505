<template>
    <Popover
        :id="id"
        v-model:show="showSync"
        :target="target"
        :title="name"
        :position="placement"
        :boundary-padding="boundaryPadding"
        :auto-fit="autoFit"
        :offset="offset"
        :styles="styles"
        @shown="emit('shown', $event)"
        @hidden="emit('hidden', $event)"
    >
        <slot />
    </Popover>
</template>

<script lang="ts" setup>
import {
    computed,
} from 'vue';
import Popover from './Popover.vue';
import { useClickOutsideListener } from '../../composables/clickOutsideListener';
import { Position } from '../../store/modules/globalSettings';

type Styles = {
    top: string;
    left: string | undefined;
};

type Props = {
    id: string,
    cypressId: string | null,
    target: string,
    name: string|null,
    placement: Position,
    noOpacity: boolean,
    boundaryPadding: number,
    show?: boolean,
    autoFit?: boolean,
    offset?: number,
    styles?: Styles | undefined
};

const props = withDefaults(defineProps<Props>(), {
    name: null,
    cypressId: null,
    placement: Position.BOTTOM_RIGHT,
    noOpacity: false,
    boundaryPadding: 50,
    show: false,
});

type Emits = {
    (event: 'shown', value: Event): void,
    (event: 'hidden', value: Event): void,
    (event: 'update:show', value: Props['show']): void,
};
const emit = defineEmits<Emits>();

const showSync = computed<Props['show']>({
    get: () => props.show,
    set: (value: Props['show']) => {
        emit('update:show', value);
    },
});

const hideDropdown = () => {
    showSync.value = false;
};

useClickOutsideListener(
    [props.id],
    () => {
        showSync.value && hideDropdown();
    },
);
</script>
