import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import optional from '../../../../io-ts/optional';
import { isDefined } from '../../../../typeAssertions/undefined';

export const EvaluationPropsC = t.type({
    maxPoints: optional(t.number),
    allowComments: t.boolean,
    commentId: t.union([t.number, t.null]),
});

export const RawEvaluationFieldC = t.partial({
    attr: t.partial({
        'data-total-value': t.union([t.string, t.number]),
        'allow-comments': t.boolean,
        commentId: t.union([t.null, t.Int]),
    }),
});

export type RawEvaluationField = TypeOf<typeof RawEvaluationFieldC>
export type EvaluationProps = TypeOf<typeof EvaluationPropsC>

export default (field: RawEvaluationField): EvaluationProps => {
    const rawMaxPoints = field.attr?.['data-total-value']?.toString();
    const maxPoints = isDefined(rawMaxPoints) ? parseInt(rawMaxPoints, 10) : 0;

    const allowComments = field.attr?.['allow-comments'] || false;
    const commentId = field.attr?.commentId || null;
    return { maxPoints, allowComments, commentId };
};
