import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import { FormFieldPartialWithoutRenderAsC, RawFormFieldC } from './FormField';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';

export const RawRestrictAccessFieldC = t.intersection([
    RawFormFieldC,
    t.type({
        renderer: t.literal(FIELD_RENDER_TYPES.RESTRICT_ACCESS),
    }),
]);

export const RestrictAccessFieldPartialC = t.intersection([
    FormFieldPartialWithoutRenderAsC,
    t.type({
        renderAs: t.literal(FIELD_RENDER_TYPES.RESTRICT_ACCESS),
    }),
]);

export const RestrictAccessFieldC = t.intersection([
    RestrictAccessFieldPartialC,
    FieldCommonPropsC,
]);

export type RawRestrictAccessField = TypeOf<typeof RawRestrictAccessFieldC>;
export type RestrictAccessFieldPartial = TypeOf<typeof RestrictAccessFieldPartialC>;
export type RestrictAccessField = TypeOf<typeof RestrictAccessFieldC>;
