enum Variant {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    LIGHT = 'light',
    LIGHT_ON_PRIMARY = 'light-on-primary',
    WHITE = 'white',
    DARK = 'dark',
    RED = 'red',
    GRAY = 'gray',
    BLUE = 'blue',
    DARK_GRAY = 'dark-gray',
    LIGHT_CONTRAST = 'light-contrast',
    TRANSPARENT = 'transparent',
    BORDERED = 'bordered',
}

const variants: string[] = Object.values(Variant);
const validator = (value: string): boolean => variants.includes(value);

export { Variant, validator };
