import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import convertForm from './convertForm';
import { assertIsNonNullable } from '../../../../typeAssertions/undefined';
import { RawRestrictAccessField, RestrictAccessFieldPartial } from '../types/RestrictAccess';

export default (field: RawRestrictAccessField): RestrictAccessFieldPartial => {
    const convertedForm = convertForm(field);
    assertIsNonNullable(convertedForm);

    return ({
        ...convertedForm,
        renderAs: FIELD_RENDER_TYPES.RESTRICT_ACCESS,
    });
};
