import fromEnum from '../../../io-ts/fromEnum';

export enum Type {
    READY = 'ready',
    CONVERTING = 'converting',
    CONVERSION_PROGRESS = 'conversion_progress',
    EDIT = 'edit',
    DELETE = 'delete',
    CUT = 'cut',
    SYNC = 'sync',
    SYNC_AVAILABILITY = 'syncAvailability',
}

export const TypeC = fromEnum('Type', Type);
