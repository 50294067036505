import Converter from '../Converter';
import toMultiSelectFormat, {
    MultiSelectFieldPartial,
    RawMultiSelectField,
    RawMultiSelectFieldC,
} from '../functions/toMultiSelectFormat';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';

export default class MultiSelectFieldConverter extends Converter<RawMultiSelectField> {
    public canConvert(field: RawField): field is RawMultiSelectField {
        return isType(field, RawMultiSelectFieldC);
    }

    protected convertPartially(field: RawMultiSelectField): MultiSelectFieldPartial {
        return toMultiSelectFormat(field);
    }
}
