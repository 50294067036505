import * as t from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import { FieldCommonProps } from '../../types/FieldCommonProps';

export const AsyncTypeaheadResponseC = t.type({
    title: t.string,
    value: t.string,
});

export const AsyncTypeaheadQueryDataC = t.union([t.record(t.string, t.string), t.null]);

export const RawAsyncTypeaheadFieldC = t.intersection([
    t.type({
        type: t.literal('string'),
        title: t.union([t.string, t.null]),
        name: t.string,
        url: t.string,
        responseInfo: AsyncTypeaheadResponseC,
        renderer: t.literal(FIELD_RENDER_TYPES.ASYNC_TYPEAHEAD),
        initOnCreated: t.boolean,
        searchFieldName: t.string,
        queryData: AsyncTypeaheadQueryDataC,
    }),
    t.partial({
        default: t.union([t.string, t.null]),
    }),
]);

const AsyncTypeaheadFieldPartialC = t.type({
    renderAs: t.literal(FIELD_RENDER_TYPES.ASYNC_TYPEAHEAD),
    url: t.string,
    responseInfo: AsyncTypeaheadResponseC,
    initOnCreated: t.boolean,
    searchFieldName: t.string,
    queryData: AsyncTypeaheadQueryDataC,
    value: t.union([t.string, t.null]),
});

export type AsyncTypeaheadFieldPartial = t.TypeOf<typeof AsyncTypeaheadFieldPartialC>;
export type RawAsyncTypeaheadField = t.TypeOf<typeof RawAsyncTypeaheadFieldC>;

export type AsyncTypeaheadResponse = t.TypeOf<typeof AsyncTypeaheadResponseC>;

export type AsyncTypeaheadField = FieldCommonProps & AsyncTypeaheadFieldPartial;

function toAsyncTypeahead(field: RawAsyncTypeaheadField): AsyncTypeaheadFieldPartial {
    return {
        ...field,
        renderAs: FIELD_RENDER_TYPES.ASYNC_TYPEAHEAD,
        value: field.default ?? null,
    };
}

export default toAsyncTypeahead;
