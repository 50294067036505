import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import toArrayFormat, {
    ArrayFieldCommonPropsC,
    RawArrayFieldC,
} from './toArrayFormat';
import { FormFieldC } from '../types/FormField';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';

export const RawEvaluationCollapseC = t.intersection([
    RawArrayFieldC,
    t.type({
        attr: t.type({
            'data-render-as-evaluation': t.literal(true),
        }),
    }),
]);

export const RawEvaluationCollapsePartialC = t.intersection([
    ArrayFieldCommonPropsC,
    t.type({
        renderAs: t.literal(FIELD_RENDER_TYPES.EVALUATION_COLLAPSE),
        itemPrototype: FormFieldC,
        items: t.array(FieldCommonPropsC),
    }),

]);

export type RawEvaluationCollapse = TypeOf<typeof RawEvaluationCollapseC>;
export type RawEvaluationCollapsePartial = TypeOf<typeof RawEvaluationCollapsePartialC>;

export default (field: RawEvaluationCollapse): RawEvaluationCollapsePartial => {
    const converted = toArrayFormat(field);
    if (converted.renderAs !== FIELD_RENDER_TYPES.ARRAY_WITH_PROTOTYPE) {
        throw new Error('Incorrect field provided');
    }
    return {
        ...converted,
        renderAs: FIELD_RENDER_TYPES.EVALUATION_COLLAPSE,
    };
};
