import { RouteRecordRaw } from 'vue-router';
import ROUTE_NAMES from './routeNames';
import { permissionsModule as pM } from '../store';

const scheduleRoutes: RouteRecordRaw[] = [
    {
        path: '/schedule',
        name: ROUTE_NAMES.SCHEDULE_MAIN,
        component: () => import('../views/Schedule/Schedule.vue'),
        meta: {
            permissions: (): string[] => [pM.allPermissions.schedule],
        },
        redirect: { name: ROUTE_NAMES.SCHEDULE_LIST },
        children: [
            {
                path: 'list',
                name: ROUTE_NAMES.SCHEDULE_LIST,
                component: () => import('../views/Schedule/ScheduleList.vue'),
                meta: {
                    title: 'Schedule',
                    permissions: (): string[] => [pM.allPermissions.schedule],
                },
            },
            {
                path: 'calendar',
                name: ROUTE_NAMES.SCHEDULE_CALENDAR,
                component: () => import('../views/Schedule/ScheduleCalendar.vue'),
                meta: {
                    title: 'Schedule',
                    permissions: (): string[] => [pM.allPermissions.schedule],
                },
            },
        ],
    },
];

export default scheduleRoutes;
