/**
 * @see src/CamBundle/Message/Websocket/Record/RecordSyncAvailability.php
 */

import * as t from 'io-ts';
import { UUID } from 'io-ts-types/UUID';
import { Type } from './Type';

export const RecordSyncAvailabilityC = t.type({
    type: t.literal(Type.SYNC_AVAILABILITY),
    id: UUID,
    available: t.boolean,
});

export type RecordSyncAvailability = t.TypeOf<typeof RecordSyncAvailabilityC>;
