import Converter from '../Converter';
import toReportDate, {
    RawReportDateField,
    RawReportDateFieldC,
    ReportDateFieldPartial,
} from '../functions/toReportDate';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';

export default class ReportDateFieldConverter extends Converter<RawReportDateField> {
    public canConvert(field: RawField): field is RawReportDateField {
        return isType(field, RawReportDateFieldC);
    }

    protected convertPartially(field: RawReportDateField): ReportDateFieldPartial {
        return toReportDate(field);
    }
}
