import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import optional from '../../../../io-ts/optional';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';

const renderers = t.union([
    t.literal(FIELD_RENDER_TYPES.CHECKBOX),
    t.literal(FIELD_RENDER_TYPES.TUMBLER),
]);

export const RawCheckboxFieldC = t.type({
    title: t.union([t.string, t.null]),
    name: t.string,
    default: optional(t.boolean),
    type: t.literal('boolean'),
    renderer: optional(renderers),
});

export const CheckboxFieldPartialC = t.type({
    value: t.boolean,
    renderAs: renderers,
});

export const CheckboxFieldC = t.intersection([CheckboxFieldPartialC, FieldCommonPropsC]);

export type RawCheckboxField = TypeOf<typeof RawCheckboxFieldC>
export type CheckboxFieldPartial = TypeOf<typeof CheckboxFieldPartialC>
export type CheckboxField = TypeOf<typeof CheckboxFieldC>

export default (field: RawCheckboxField): CheckboxFieldPartial => {
    const renderAs = field.renderer === FIELD_RENDER_TYPES.TUMBLER
        ? FIELD_RENDER_TYPES.TUMBLER
        : FIELD_RENDER_TYPES.CHECKBOX;

    return ({
        value: !!field.default,
        renderAs,
    });
};
