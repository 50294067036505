import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';
import optional from '../../../../io-ts/optional';

export const RawStaticTextFieldC = t.type({
    title: t.union([t.string, t.null]),
    name: t.string,
    type: t.literal('string'),
    renderer: t.literal(FIELD_RENDER_TYPES.STATIC_TEXT),
    default: optional(t.union([t.string, t.null])),
});

export const StaticTextFieldPartialC = t.type({
    value: t.string,
    renderAs: t.literal(FIELD_RENDER_TYPES.STATIC_TEXT),
    name: t.string,
});

export const StaticTextFieldC = t.intersection([StaticTextFieldPartialC, FieldCommonPropsC]);

export type RawStaticTextField = TypeOf<typeof RawStaticTextFieldC>
export type StaticTextFieldPartial = TypeOf<typeof StaticTextFieldPartialC>
export type StaticTextField = TypeOf<typeof StaticTextFieldC>

export default (field: RawStaticTextField): StaticTextFieldPartial => ({
    renderAs: FIELD_RENDER_TYPES.STATIC_TEXT,
    value: field.default ?? '',
    name: field.name,
});
