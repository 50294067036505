import { RawField } from './converters/types/RawField';
import Converter from './converters/Converter';
import { Field } from './converters/types/Field';
import converters from './converters/all';

export default class SchemaConverter<T extends RawField> {
    constructor(private readonly rawField: T) {}

    public convert(): Field | null {
        const canConvert = (converter: Converter<RawField>) => converter.canConvert(this.rawField);
        const converter = converters.find(canConvert);

        if (!converter) {
            throw new Error(`Can't find converter for field: ${JSON.stringify(this.rawField)}`);
        }

        return converter.convert(this.rawField);
    }
}
