import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import convertForm from './convertForm';
import { FormFieldPartialWithoutRenderAsC, RawFormField, RawFormFieldC } from '../types/FormField';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import { assertIsNonNullable } from '../../../../typeAssertions/undefined';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';

export const RawExpirationFieldC = t.intersection([
    RawFormFieldC,
    t.type({
        attr: t.type({
            'data-render-as-expiration': t.literal(true),
        }),
    }),
]);

export const ExpirationFieldPartialC = t.intersection([
    FormFieldPartialWithoutRenderAsC,
    t.type({
        renderAs: t.literal(FIELD_RENDER_TYPES.EXPIRATION),
    }),
]);

export const ExpirationFieldC = t.intersection([ExpirationFieldPartialC, FieldCommonPropsC]);

export type RawExpirationField = TypeOf<typeof RawExpirationFieldC>
export type ExpirationFieldPartial = TypeOf<typeof ExpirationFieldPartialC>
export type ExpirationField = TypeOf<typeof ExpirationFieldC>

export default (field: RawFormField): ExpirationFieldPartial => {
    const convertedForm = convertForm(field);
    assertIsNonNullable(convertedForm);
    return ({
        ...convertedForm,
        renderAs: FIELD_RENDER_TYPES.EXPIRATION,
    });
};
