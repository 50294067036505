import LinesIcon from '../../../images/lines.svg?component';
import ROUTE_NAMES from '../routeNames';
import SECTION_NAMES from '../sectionNames';
import { permissionsModule as pM } from '../../store';

const meta = {
    title: SECTION_NAMES.TEMPLATES,
    icon: LinesIcon,
    permissions: (): string[] => [pM.allPermissions.adminTemplates],
};

export default [
    {
        path: '',
        name: ROUTE_NAMES.ADMIN_TEMPLATE_LIST,
        component: (): Promise<unknown> => import('../../views/admin/AdminTemplate/AdminTemplateList.vue'),
        meta,
    },
    {
        path: 'view/:id',
        name: ROUTE_NAMES.ADMIN_TEMPLATE_VIEW,
        component: (): Promise<unknown> => import('../../views/admin/AdminTemplate/AdminTemplateView.vue'),
        meta,
    },
    {
        path: 'new',
        name: ROUTE_NAMES.ADMIN_TEMPLATE_NEW,
        component: (): Promise<unknown> => import('../../views/admin/AdminTemplate/AdminTemplateView.vue'),
        meta,
    },
];
