import { RouteLocationRaw, RouteLocationNormalized } from 'vue-router';
import { getPermittedHomeRoute } from '../../functions/permissions/getPermittedHomeRoute';
import ROUTE_NAMES from '../../../../vue/routes/routeNames';
import { RouterListener } from './RouterListener';
import appApi from '../../../api/app/AppApi';
import store, { userModule, permissionsModule, isAnonymousModule } from '../../../../vue/store';

const loadPermissions = async () => {
    const { all, user } = await appApi.getPermissions();

    permissionsModule.setAllPermissions(all);
    userModule.setPermissions(user);
};

export default class HomeRouteListener implements RouterListener {
    public async onBeforeEach(to: RouteLocationNormalized): Promise<boolean | RouteLocationRaw> {
        const permissionsLoaded = Boolean(store.getters.permissions.length);

        if (!isAnonymousModule.isAnonymous && !permissionsLoaded) {
            await loadPermissions();
        }

        const isHomeRoute = to.name === ROUTE_NAMES.HOME;

        const hasHomePagePermission = userModule.hasPermission(
            permissionsModule.allPermissions.generalHome,
        );

        if (!isHomeRoute || hasHomePagePermission) {
            return true;
        }

        const nextRoute = getPermittedHomeRoute();
        if (nextRoute === null) {
            return true;
        }

        return ({ name: nextRoute });
    }
}
