import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import convertForm from './convertForm';
import { FormFieldPartialWithoutRenderAsC, RawFormField, RawFormFieldC } from '../types/FormField';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import { assertIsNonNullable } from '../../../../typeAssertions/undefined';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';

export const RawCommentReviewFieldC = t.intersection([
    RawFormFieldC,
    t.type({
        attr: t.type({
            'data-render-as-comment-review': t.literal(true),
        }),
    }),
]);

export const CommentReviewFieldPartialC = t.intersection([
    FormFieldPartialWithoutRenderAsC,
    t.type({
        renderAs: t.literal(FIELD_RENDER_TYPES.COMMENT_REVIEW),
    }),
]);

export const CommentReviewFieldC = t.intersection([CommentReviewFieldPartialC, FieldCommonPropsC]);

export type RawCommentReviewField = TypeOf<typeof RawCommentReviewFieldC>
export type CommentReviewFieldPartial = TypeOf<typeof CommentReviewFieldPartialC>
export type CommentReviewField = TypeOf<typeof CommentReviewFieldC>

export default (field: RawFormField): CommentReviewFieldPartial => {
    const convertedForm = convertForm(field);
    assertIsNonNullable(convertedForm);
    return ({
        ...convertedForm,
        renderAs: FIELD_RENDER_TYPES.COMMENT_REVIEW,
    });
};
