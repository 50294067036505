import PeopleIcon from '../../../images/people.svg?component';
import ROUTE_NAMES from '../routeNames';
import SECTION_NAMES from '../sectionNames';
import { permissionsModule as pM } from '../../store';

const meta = {
    title: SECTION_NAMES.USERS_AND_GROUPS,
    icon: PeopleIcon,
};

export default [
    {
        path: 'user',
        name: ROUTE_NAMES.ADMIN_USER_LIST,
        component: (): unknown => import('../../views/admin/AdminUser/AdminUserList.vue'),
        meta: {
            ...meta,
            permissions: (): string[] => [pM.allPermissions.adminUsersAndGroups],
        },
    },
    {
        path: 'user-group',
        name: ROUTE_NAMES.ADMIN_GROUP_LIST,
        component: (): unknown => import('../../views/admin/AdminUser/AdminUserList.vue'),
        meta: {
            ...meta,
            permissions: (): string[] => [pM.allPermissions.adminUsersAndGroups],
        },
    },
    {
        path: 'user/edit/:id',
        name: ROUTE_NAMES.ADMIN_USER_VIEW,
        component: (): unknown => import('../../views/admin/AdminUser/AdminUserView.vue'),
        meta: {
            ...meta,
            permissions: (): string[] => [pM.allPermissions.adminUsersAndGroups],
        },
    },
    {
        path: 'user/new',
        name: ROUTE_NAMES.ADMIN_USER_NEW,
        component: (): unknown => import('../../views/admin/AdminUser/AdminUserView.vue'),
        meta: {
            ...meta,
            permissions: (): string[] => [pM.allPermissions.adminUsersAndGroups],
        },
    },
    {
        path: 'user-group/edit/:id',
        name: ROUTE_NAMES.ADMIN_GROUP_VIEW,
        component: (): unknown => import('../../views/admin/AdminUser/AdminGroupView.vue'),
        meta: {
            ...meta,
            permissions: (): string[] => [pM.allPermissions.adminUsersAndGroups],
        },
    },
    {
        path: 'user-group/new',
        name: ROUTE_NAMES.ADMIN_GROUP_NEW,
        component: (): unknown => import('../../views/admin/AdminUser/AdminGroupView.vue'),
        meta: {
            ...meta,
            permissions: (): string[] => [pM.allPermissions.adminUsersAndGroups],
        },
    },
    {
        path: 'user-sso',
        name: ROUTE_NAMES.ADMIN_SSO,
        component: (): unknown => import('../../views/admin/AdminUser/AdminSso.vue'),
        meta: {
            ...meta,
            permissions: (): string[] => [pM.allPermissions.adminSaml],
        },
    },
    {
        path: 'user-ldap',
        name: ROUTE_NAMES.ADMIN_LDAP,
        component: (): unknown => import('../../views/admin/AdminUser/AdminLdap.vue'),
        meta: {
            ...meta,
            permissions: (): string[] => [pM.allPermissions.adminLdap],
        },
    },
];
