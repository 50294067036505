import ListenerProvider, { Listener } from './ListenerProvider';

/**
 * This listener replaces default click event.
 * Unlike click event, this listener validates that mouse down
 * and mouse up events satisfy the condition.
 */
export default class SmartClickListener extends ListenerProvider {
    private started = false;

    public constructor(
        private readonly condition: (event: Event) => boolean,
        private readonly handler: Listener,
    ) {
        super();
        this.onStartEvent = this.onStartEvent.bind(this);
        this.onEndEvent = this.onEndEvent.bind(this);
    }

    public registerListener(): void {
        document.addEventListener('mousedown', this.onStartEvent);
        document.addEventListener('mouseup', this.onEndEvent);
    }

    public unregisterListener(): void {
        document.removeEventListener('mousedown', this.onStartEvent);
        document.removeEventListener('mouseup', this.onEndEvent);
    }

    protected onStartEvent(event: Event): void {
        if (this.condition(event)) {
            this.started = true;
        }
    }

    protected onEndEvent(event: Event): void {
        if (!this.started || !this.condition(event)) {
            return;
        }

        this.started = false;
        this.handler(event);
    }
}
