/**
 * @see src/CamBundle/Message/Websocket/Settings/SyncSwitched.php
 */

import * as t from 'io-ts';
import { Type } from './Type';

export const SyncSwitchedC = t.type({
    type: t.literal(Type.SYNC_SWITCHED),
    enabled: t.boolean,
});

export type SyncSwitched = t.TypeOf<typeof SyncSwitchedC>;
