<template>
    <NotificationCard
        :location="location"
        :notification="notification"
        :viewed="notification.isViewed"
        @reload-notifications="$emit('reloadNotifications')"
    >
        <template #notification-icon>
            <MailIcon class="icon" />
        </template>
        <template #notification-header>
            {{ notificationMessageTitle }}
        </template>
        <template #notification-text>
            <div class="message-item">
                <span class="text-truncate">
                    <CommentMessage
                        :value="notification.message || ''"
                    />
                </span>
            </div>
        </template>
    </NotificationCard>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import NotificationCard from '../NotificationCard.vue';
import type { NotificationItem } from '../../../../scripts/types/Notification';
import MailIcon from '../../../../images/mail-black.svg?component';
import ROUTE_NAMES from '../../../routes/routeNames';
import CommentMessage from '../../Comments/CommentPreview/CommentMessage.vue';

type Props = {
    id: string,
    cypressId: string | null,
    notification: NotificationItem,
};

type Emits = {
    (e: 'reloadNotifications'): void,
};

defineEmits<Emits>();

const props = withDefaults(defineProps<Props>(), {
    cypressId: null,
});

const notificationMessageTitle = computed<string>(() => {
    if (!props.notification.groupName) {
        return `${props.notification.senderName} tagged you in ${props.notification.recordingName}:`;
    }
    return `${props.notification.senderName} tagged your group in ${props.notification.recordingName}:`;
});

const location = computed<RouteLocationRaw>(() => {
    const commentId = props.notification.commentId?.toString() ?? '';

    return {
        name: ROUTE_NAMES.REVIEW_RECORD_SHOW_COMMENT,
        params: { recordId: props.notification.recordingId ?? '', commentId },
    };
});

</script>

<style scoped>

.icon {
    fill: currentColor;
}

</style>
