import isType from '../../../../io-ts/isType';
import Converter from '../Converter';
import { RawField } from '../types/RawField';
import toSingleChoice, {
    RawSingleChoice,
    SingleChoicePartial,
    RawSingleChoiceC,
} from '../functions/toSingleChoice';

export default class SingleChoiceConverter extends Converter<RawSingleChoice> {
    public canConvert(field: RawField): field is RawSingleChoice {
        return isType(field, RawSingleChoiceC);
    }

    protected convertPartially(field: RawSingleChoice): SingleChoicePartial | null {
        return toSingleChoice(field);
    }
}
