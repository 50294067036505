import * as t from 'io-ts';
import { KeyofC } from 'io-ts';

/**
 * @see https://stackoverflow.com/a/65838169/11907070
 * */
function keyObject<T extends readonly string[]>(arr: T): { [K in T[number]]: null } {
    return Object.fromEntries(arr.map((v) => [v, null])) as { [K in T[number]]: null };
}

export default function OneOf<T extends readonly string[]>(arr: T)
    : KeyofC<{ [key in T[number]]: null }> {
    return t.keyof(keyObject(arr));
}
