import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import getEvaluationProps, {
    EvaluationPropsC,
    RawEvaluationFieldC,
} from '../parts/getEvaluationProps';
import { MultiSelectChoice, MultiSelectChoiceC } from './toMultiSelectFormat';
import optional from '../../../../io-ts/optional';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';

export const RawRadioFieldC = t.intersection([
    RawEvaluationFieldC,
    t.type({
        title: t.union([t.string, t.null]),
        name: t.string,
        enum: t.array(t.string),
        enum_titles: t.array(t.string),
        enum_additional_info: t.array(t.number),
        default: t.union([t.string, t.null]),
        type: t.literal('string'),
        widget: t.literal('choice-expanded'),
    }),
]);

export const RadioFieldPartialC = t.intersection([
    EvaluationPropsC,
    t.type({
        renderAs: t.literal(FIELD_RENDER_TYPES.RADIO),
        choices: t.array(MultiSelectChoiceC),
        selectedChoice: optional(MultiSelectChoiceC),
    }),
]);

export const RadioFieldC = t.intersection([RadioFieldPartialC, FieldCommonPropsC]);

export type RawRadioField = TypeOf<typeof RawRadioFieldC>
export type RadioFieldPartial = TypeOf<typeof RadioFieldPartialC>
export type RadioField = TypeOf<typeof RadioFieldC>

export default (field: RawRadioField): RadioFieldPartial => {
    const renderAs = FIELD_RENDER_TYPES.RADIO;

    const choices: MultiSelectChoice[] = field.enum.map((val, i) => ({
        value: val,
        title: field.enum_titles[i],
        evaluationPoints: field.enum_additional_info?.[i] || null,
    }));

    const selectedChoice = choices.find((choice) => choice.value === field.default);

    return {
        choices, selectedChoice, renderAs, ...getEvaluationProps(field),
    };
};
