import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import isType from '../../../../io-ts/isType';

export const ChoiceValueC = t.union([t.string, t.number, t.null]);

export type ChoiceValue = TypeOf<typeof ChoiceValueC>;

export const ChoiceC = t.intersection([
    t.type({
        value: ChoiceValueC,
        title: t.string,
        selected: t.boolean,
        disabled: t.boolean,
    }),
    t.partial({
        color: t.string,
        tooltip: t.string,
    }),
]);

export type Choice = TypeOf<typeof ChoiceC>;

export const ParentChoiceC = t.intersection([
    ChoiceC,
    t.type({ children: t.array(ChoiceC) }),
]);

export const ParentChoiceCheckboxTreeC = t.intersection([
    ParentChoiceC,
    t.partial({ label: t.string }),
]);

export type ParentChoiceCheckboxTree = TypeOf<typeof ParentChoiceCheckboxTreeC>

export type ParentChoice = TypeOf<typeof ParentChoiceC>;

export const ChoiceWithFilterFieldC = t.intersection([
    t.type({
        value: ChoiceValueC,
        title: t.string,
        selected: t.boolean,
        disabled: t.boolean,
    }),
    t.partial({
        shown: t.boolean,
    }),
]);

export const ParentChoiceWithFilterFieldC = t.intersection([
    ChoiceWithFilterFieldC,
    t.type({ children: t.array(ChoiceWithFilterFieldC) }),
    t.partial({ shown: t.boolean }),
]);

export type ChoiceWithFilterField = TypeOf<typeof ChoiceWithFilterFieldC>
export type ParentChoiceWithFilterField = TypeOf<typeof ParentChoiceWithFilterFieldC>;

export const transformToParent = (choice: Choice): ParentChoice => Object.assign(
    choice,
    { children: [] },
);

export const flat = (choices: Choice[]): Choice[] => choices.flatMap(
    (choice: Choice) => {
        if (!isType(choice, ParentChoiceC)) {
            return [choice];
        }

        return [choice, ...choice.children, ...flat(choice.children)];
    },
);

export const OptionC = t.intersection([
    t.type({
        text: t.string,
        value: ChoiceValueC,
        disabled: t.boolean,

    }),
    t.partial({
        evaluationPoints: t.union([t.number, t.null, t.undefined]),
    }),
]);

export type Option = t.TypeOf<typeof OptionC>;

export const choiceToOption = (
    { title, value, disabled }: Choice,
): Option => (
    { text: title, value, disabled }
);
