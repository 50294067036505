import Converter from '../Converter';
import toAsyncTypeahead, { AsyncTypeaheadFieldPartial, RawAsyncTypeaheadField, RawAsyncTypeaheadFieldC } from '../functions/toAsyncTypeahead';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';

export default class AsyncTypeaheadConverter extends Converter<RawAsyncTypeaheadField> {
    public canConvert(field: RawField): field is RawAsyncTypeaheadField {
        return isType(field, RawAsyncTypeaheadFieldC);
    }

    protected convertPartially(field: RawAsyncTypeaheadField): AsyncTypeaheadFieldPartial {
        return toAsyncTypeahead(field);
    }
}
