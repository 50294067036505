import { defineAsyncComponent } from 'vue';
import ServerIcon from '../../../../images/server.svg?component';
import ROUTE_NAMES from '../../routeNames';
import SECTION_NAMES from '../../sectionNames';
import { permissionsModule as pM } from '../../../store';

const meta = {
    title: SECTION_NAMES.SERVERS_AND_SERVICES,
    icon: ServerIcon,
    permissions: (): string[] => [pM.allPermissions.adminServersWowza],
};

const AdminMediaServerForm = defineAsyncComponent(() => (
    import('../../../views/admin/Servers/Media/AdminMediaServerForm.vue')
));

const AdminMediaServerTable = defineAsyncComponent(() => (
    import('../../../views/admin/Servers/Media/AdminMediaServerTable.vue')
));

export default [
    {
        path: '',
        name: ROUTE_NAMES.ADMIN_MEDIA_SERVER_LIST,
        component: AdminMediaServerTable,
        meta,
    },
    {
        path: 'edit/:id',
        name: ROUTE_NAMES.ADMIN_MEDIA_SERVER_VIEW,
        component: AdminMediaServerForm,
        meta,
    },
    {
        path: 'new',
        name: ROUTE_NAMES.ADMIN_MEDIA_SERVER_NEW,
        component: AdminMediaServerForm,
        meta,
    },
];
