import { RouteLocationNormalized } from 'vue-router';
import { RouterListener } from './RouterListener';
import { isAnonymousModule } from '../../../../vue/store';

export default class AnonymousRouteListener implements RouterListener {
    public onBeforeEach(to: RouteLocationNormalized): boolean {
        isAnonymousModule.updateRouteState(to);

        return true;
    }
}
