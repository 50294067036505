import Converter from '../Converter';
import toCheckboxFormat, {
    RawCheckboxField,
    RawCheckboxFieldC,
    CheckboxFieldPartial,
} from '../functions/toCheckboxFormat';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';

export default class CheckboxFieldConverter extends Converter<RawCheckboxField> {
    public canConvert(field: RawField): field is RawCheckboxField {
        return isType(field, RawCheckboxFieldC);
    }

    protected convertPartially(field: RawCheckboxField): CheckboxFieldPartial {
        return toCheckboxFormat(field);
    }
}
