import { RouteLocationRaw } from 'vue-router';
import { isEmpty } from 'fp-ts/Array';
import router from '../../../../vue/router';
import { CustomRoute } from '../../../../vue/routes';
import { userModule } from '../../../../vue/store';

export default (location: RouteLocationRaw): boolean => {
    const { permissions } = router.resolve(location).meta as CustomRoute['meta'];
    if (!permissions) {
        return false;
    }

    return isEmpty(permissions()) || permissions()
        .some((permission) => userModule.hasPermission(permission));
};
