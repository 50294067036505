import _get from 'lodash/get';
import isString from 'lodash/isString';
import * as t from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import optional from '../../../../io-ts/optional';
import { EmptyRecordFromArray } from '../../../../io-ts/emptyArray';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';

const AttrC = t.partial({
    'data-header': t.string,
    'data-preserve-updated-data': t.boolean,
});

export const RawSelectFieldC = t.type({
    title: t.union([t.string, t.null]),
    name: t.string,
    enum: t.array(t.string),
    enum_titles: t.array(t.string),
    enum_disabled: optional(t.array(t.string)),
    default: optional(t.string),
    disabled: optional(t.boolean),
    attr: t.union([AttrC, EmptyRecordFromArray, t.undefined]),
    type: t.literal('string'),
});

export const ChoiceValueC = t.union([t.string, t.number, t.null]);

export const SelectChoiceC = t.intersection([
    t.type({
        value: ChoiceValueC,
        title: t.string,
    }),
    t.partial({
        disabled: t.boolean,
        isNullChoice: t.boolean,
        isSpecial: t.boolean,
    }),
]);

export const SelectFieldPartialC = t.type({
    renderAs: t.literal(FIELD_RENDER_TYPES.SELECT),
    choices: t.array(SelectChoiceC),
    selectedChoice: SelectChoiceC,
    header: t.string,
    preserveUpdatedData: t.boolean,
    disabled: t.boolean,
});

export const SelectFieldC = t.intersection([SelectFieldPartialC, FieldCommonPropsC]);

export type RawSelectField = t.TypeOf<typeof RawSelectFieldC>
export type ChoiceValue = t.TypeOf<typeof ChoiceValueC>
export type SelectChoice = t.TypeOf<typeof SelectChoiceC>
export type SelectFieldPartial = t.TypeOf<typeof SelectFieldPartialC>
export type SelectField = t.TypeOf<typeof SelectFieldC>

export const SelectOptionC = t.type({
    value: t.string,
    title: t.string,
});

export type SelectOption = t.TypeOf<typeof SelectOptionC>;

export default (field: RawSelectField): SelectFieldPartial => {
    const renderAs = FIELD_RENDER_TYPES.SELECT;
    const choices: SelectChoice[] = field.enum.map((val, i) => ({
        value: val,
        title: field.enum_titles[i],
        disabled: !!field?.enum_disabled?.includes(val),
        isNullChoice: false,
        isSpecial: false,
    }));

    const nullChoiceTitle = _get(field, 'attr["data-null-choice"]');
    if (isString(nullChoiceTitle)) {
        choices.unshift({
            value: null,
            title: nullChoiceTitle,
            disabled: false,
            isNullChoice: true,
            isSpecial: true,
        });
    }

    const selectedChoice = choices.find((choice) => choice.value === field.default) || choices[0];
    const header = field?.attr?.['data-header'] || '';
    const preserveUpdatedData = field?.attr?.['data-preserve-updated-data'] ?? true;
    const disabled = !!field.disabled;

    return {
        choices, selectedChoice, renderAs, header, disabled, preserveUpdatedData,
    };
};
