import * as t from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import optional from '../../../../io-ts/optional';
import instanceOf from '../../../../io-ts/instanceOf';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';
import { emptyArray } from '../../../../io-ts/emptyArray';

const RendererC = t.union([
    t.literal(FIELD_RENDER_TYPES.FILE_UPLOAD),
    t.literal(FIELD_RENDER_TYPES.VIDEO_UPLOAD),
]);

const RawFileFieldBaseC = t.type({
    type: t.literal('file'),
    renderer: RendererC,
    title: t.union([t.null, t.string]),
    name: t.string,
    maxFileSize: optional(t.number),
    attr: t.type({
        accept: t.string,
        no_header: optional(t.boolean),
    }),
});

const RawSingleFileFieldC = t.intersection([
    RawFileFieldBaseC,
    t.type({
        multiple: t.literal(false),
        value: t.union([t.string, t.null]),
    }),
]);

const RawMultipleFileFieldC = t.intersection([
    RawFileFieldBaseC,
    t.type({
        multiple: t.literal(true),
        value: t.union([t.array(t.string), emptyArray]),
    }),
]);

export const RawFileFieldC = t.union([RawSingleFileFieldC, RawMultipleFileFieldC]);

const FileC = instanceOf(File);

const FileFieldPartialBaseC = t.type({
    renderAs: RendererC,
    accept: optional(t.string),
    maxFileSize: optional(t.number),
    noHeader: optional(t.boolean),
});

const SingleFileC = t.union([FileC, t.null]);
const SingleFileFieldPartialC = t.intersection([
    FileFieldPartialBaseC,
    t.type({
        multiple: t.literal(false),
        file: SingleFileC,
    }),
]);

const MultipleFileC = t.union([t.array(FileC), emptyArray]);
const MultipleFileFieldPartialC = t.intersection([
    FileFieldPartialBaseC,
    t.type({
        multiple: t.literal(true),
        files: MultipleFileC,
    }),
]);

export const FileFieldPartialC = t.union([SingleFileFieldPartialC, MultipleFileFieldPartialC]);

export const FileFieldC = t.intersection([FileFieldPartialC, FieldCommonPropsC]);

export type RawFileField = t.TypeOf<typeof RawFileFieldC>;
export type FileFieldPartial = t.TypeOf<typeof FileFieldPartialC>;
export type FileField = t.TypeOf<typeof FileFieldC>;

const toFile = (file: string): File => new File([], file);
const tryToFile = (file: string|null): File|null => (file ? toFile(file) : null);

export default (field: RawFileField): FileFieldPartial => {
    const base = {
        renderAs: field.renderer,
        accept: field.attr.accept,
        maxFileSize: field.maxFileSize,
        noHeader: field.attr.no_header,
    };

    if (field.multiple) {
        return {
            ...base,
            multiple: true,
            files: field.value.map(toFile),
        };
    }

    return {
        ...base,
        multiple: false,
        file: tryToFile(field.value),
    };
};
