import { formatInTimeZone, toDate } from 'date-fns-tz';
import {
    format, intervalToDuration, formatDuration, parse,
} from 'date-fns';
import { timezoneModule, globalSettingsModule } from '../../vue/store';
import { DateFormat as DateFormatSetting } from '../../vue/store/modules/globalSettings';

export enum DateTimeFormat {
    UK = 'HH:mm · dd/MM/yyyy',
    US = 'HH:mm · MM/dd/yyyy'
}

export enum DateFormat {
    UK = 'dd/MM/yyyy',
    US = 'MM/dd/yyyy'
}

export enum DateFormFormat {
    UK = 'dd/MM/yyyy',
    US = 'MM/dd/yyyy'
}

const COMPARE_DATE_FORMAT = DateFormFormat.UK;
export const TIME_FORMAT = 'HH:mm';

export default class DateHelper {
    public static toDateFormat(date: Date, withTimezone = true): string {
        return DateHelper.toFormat(date, DateHelper.dateFormat, withTimezone);
    }

    public static toDateFormFormat(date: Date, withTimezone = true): string {
        return DateHelper.toFormat(date, DateHelper.dateFormFormat, withTimezone);
    }

    public static toDateTimeFormat(date: Date, withTimezone = true): string {
        return DateHelper.toFormat(date, DateHelper.dateTimeFormat, withTimezone);
    }

    public static toTimeFormat(date: Date, withTimezone = true): string {
        return DateHelper.toFormat(date, TIME_FORMAT, withTimezone);
    }

    public static toFormat(date: Date, dateFormat: string, withTimezone = true): string {
        if (!withTimezone) {
            return format(date, dateFormat);
        }

        return formatInTimeZone(date, timezoneModule.serverTimezone, dateFormat);
    }

    public static setServerTimezone(date: Date): Date {
        const dateFormatted = format(date, 'yyyy-MM-dd HH:mm:ss');

        return toDate(dateFormatted, { timeZone: timezoneModule.serverTimezone });
    }

    public static getServerTimezoneOffset(): number {
        return timezoneModule.timezoneOffset;
    }

    public static equalsDate(first: Date, second: Date, withTimezone = true): boolean {
        const firstFormatted = this.toFormat(first, COMPARE_DATE_FORMAT, withTimezone);
        const secondFormatted = this.toFormat(second, COMPARE_DATE_FORMAT, withTimezone);

        return firstFormatted === secondFormatted;
    }

    public static toSecondsOffset(date: Date): number {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        return (hours * 60 + minutes) * 60 + seconds;
    }

    /**
     * This method does not support a duration of more than 24 hours (hours will be reset).
     */
    public static toDurationTimeFormat(duration: number): string {
        const durationObject = intervalToDuration({ start: 0, end: duration * 1000 });

        return formatDuration(durationObject, {
            format: ['hours', 'minutes', 'seconds'],
            zero: true,
            delimiter: ':',
            locale: {
                formatDistance: (_token, count) => String(count).padStart(2, '0'),
            },
        });
    }

    /**
     *
     * @param date string in  DateHelper.dateFormFormat
     * @returns
     */
    public static toDate(date: string): Date {
        const dateObject = parse(date, DateHelper.dateFormFormat, new Date());
        return toDate(dateObject, { timeZone: timezoneModule.serverTimezone });
    }

    protected static get dateFormat(): DateFormat {
        const { dateFormat } = globalSettingsModule;

        if (!dateFormat) {
            return DateFormat.UK;
        }

        const dateFormats = {
            [DateFormatSetting.UK]: DateFormat.UK,
            [DateFormatSetting.US]: DateFormat.US,
        };

        return dateFormats[dateFormat];
    }

    protected static get dateFormFormat(): DateFormFormat {
        const { dateFormat } = globalSettingsModule;

        if (!dateFormat) {
            return DateFormFormat.UK;
        }

        const dateFormats = {
            [DateFormatSetting.UK]: DateFormFormat.UK,
            [DateFormatSetting.US]: DateFormFormat.US,
        };

        return dateFormats[dateFormat];
    }

    protected static get dateTimeFormat(): DateTimeFormat {
        const { dateFormat } = globalSettingsModule;

        if (!dateFormat) {
            return DateTimeFormat.UK;
        }

        const dateFormats = {
            [DateFormatSetting.UK]: DateTimeFormat.UK,
            [DateFormatSetting.US]: DateTimeFormat.US,
        };

        return dateFormats[dateFormat];
    }
}
