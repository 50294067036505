enum SECTION_NAMES {
    GENERAL = 'General',
    USERS_AND_GROUPS = 'Users & Groups',
    TEMPLATES = 'Templates',
    ROOMS_AND_CAMERAS = 'Rooms & Cameras',
    CONTAINERS = 'Containers',
    SERVERS_AND_SERVICES = 'Servers & Services',
    LOGS = 'Logs',
    ALERTS = 'Alerts',
    HELP = 'Help',
}

export default SECTION_NAMES;
