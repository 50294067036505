<!-- eslint-disable vue/no-v-html vue/no-v-text-v-html-on-component -->
<template>
    <Component
        :is="tag"
        v-html="valueHTML"
    />
</template>

<script lang="ts" setup>
import { escape } from 'lodash';
import { withDefaults, defineProps, computed } from 'vue';
import type { SearchableTextProps } from './SearchableTextProps';

type Props = {
    tag?: SearchableTextProps['tag'],
    searchValue: SearchableTextProps['searchValue'],
    value: SearchableTextProps['value'],
    replacer?: SearchableTextProps['replacer'],
}

const props = withDefaults(defineProps<Props>(), {
    tag: 'span',
    searchValue: null,
    replacer: undefined,
});

const valueHTML = computed(() => {
    let text = escape(props.value);

    if (props.replacer) {
        text = props.replacer(text);
    }

    if (!props.searchValue) {
        return text;
    }

    const regex = new RegExp(props.searchValue, 'gi');

    return text.replace(regex, '<span class="search-marker">$&</span>');
});
</script>

<style>

.search-marker {
    color: var(--theme-color-text-primary-light);

    background-color: var(--theme-color-surface-accent-primary);
}

</style>
