<template>
    <Component
        :is="component"
        :data-cy="cypressId"
        class="context-menu__option"
        :to="to"
        @click="$emit('click', $event)"
    >
        <slot />
    </Component>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { RouteLocation } from 'vue-router';
import Link from './Link.vue';

@Options({
    components: {
        Link,
    },
})
export default class ContextMenuOption extends Vue {
    @Prop({ type: [String, Object], default: null })
    public readonly to!: string | RouteLocation | null;

    @Prop({ type: String, default: null })
    public readonly cypressId!: string | null;

    public get component(): typeof Link | string {
        return this.to !== null ? Link : 'div';
    }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/abstracts/spacings';
@import '../../../styles/abstracts/font-sizes';
@import '../../../styles/abstracts/mixins';

.context-menu__option {
    display: block;

    width: 100%;
    padding: $spacing-m 0;

    font-size: $font-size-lg;
    color: var(--theme-color-text-primary);
    text-align: center;
    text-decoration: none;

    cursor: pointer;

    background-color: var(--theme-color-surface-inversed-primary);

    &:hover {
        color: var(--theme-color-text-inversed);

        background-color: var(--theme-color-surface-inversed-secondary-hover);
    }

    &:not(:hover) + &:not(:hover) {
        @include has-horizontal-rule($spacing-s, 'top');
    }
}
</style>
