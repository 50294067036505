import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';
import { FormFieldPartialWithoutRenderAsC, RawFormFieldC } from './FormField';

export const RawPresetsControlC = t.intersection([
    RawFormFieldC,
    t.type({ renderer: t.literal(FIELD_RENDER_TYPES.PRESETS_CONTROL) }),
]);

export const PresetsControlPartialC = t.intersection([
    FormFieldPartialWithoutRenderAsC,
    t.type({ renderAs: t.literal(FIELD_RENDER_TYPES.PRESETS_CONTROL) }),
]);

export const PresetsControlC = t.intersection([PresetsControlPartialC, FieldCommonPropsC]);

export type RawPresetsControl = TypeOf<typeof RawPresetsControlC>;
export type PresetsControlPartial = TypeOf<typeof PresetsControlPartialC>;
export type PresetsControl = TypeOf<typeof PresetsControlC>;
