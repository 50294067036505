import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';

export const CHOICE_MULTIPLE_RENDER_TYPE = [
    FIELD_RENDER_TYPES.CHECKBOXES,
    FIELD_RENDER_TYPES.RADIO_TREE,
    FIELD_RENDER_TYPES.CHECKBOX_TREE,
] as const;

export const ChoiceMultipleExpandedFieldC = t.intersection([
    t.type({
        items: t.partial({
            maxItems: t.Int,
        }),
        widget: t.literal('choice-multiple-expanded'),
    }),
    t.partial({
        attr: t.partial({
            'data-render-as-tree': t.literal(true),
        }),
    }),
]);

export type ChoiceMultipleExpandedField = TypeOf<typeof ChoiceMultipleExpandedFieldC>
export type ChoiceMultipleRenderType = typeof CHOICE_MULTIPLE_RENDER_TYPE[number]

export default (field: ChoiceMultipleExpandedField): ChoiceMultipleRenderType => {
    const isTree = field?.attr?.['data-render-as-tree'] || false;
    if (!isTree) {
        return FIELD_RENDER_TYPES.CHECKBOXES;
    }
    return field.items.maxItems === 1
        ? FIELD_RENDER_TYPES.RADIO_TREE
        : FIELD_RENDER_TYPES.CHECKBOX_TREE;
};
