import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import * as t from 'io-ts';
import fromEnum from '../../../scripts/io-ts/fromEnum';
import { TimeSettingsC } from './timezone';
import { SyncSettingsC } from './sync';

export enum DateFormatName {
    US = 'US',
    UK = 'UK',
}

export const DateFormatNameC = fromEnum('DateFormatName', DateFormatName);

export enum DateFormat {
    US = 'MM/dd/yyyy',
    UK = 'dd/MM/yyyy',
}

enum DateTimeFormat {
    US = 'hh:mm:ss a MM/dd/yyyy',
    UK = 'hh:mm:ss a dd/MM/yyyy',
}

export enum StreamingMethod {
    ULL = 'ull',
    HLS = 'hls',
}

export enum StreamingMethodNumeric {
    ULL = '0',
    HLS = '1',
}

export const StreamingMethodNumericC = fromEnum('StreamingMethodNumeric', StreamingMethodNumeric);

export enum CommentOverlayFontSize {
    SMALL = 'small',
    MEDIUM = 'medium',
    BIG = 'big',
}

export enum Position {
    BOTTOM = 'bottom',
    BOTTOM_LEFT = 'bottom-left',
    BOTTOM_RIGHT = 'bottom-right',
    RIGHT = 'right',
    TOP = 'top',
    TOP_LEFT = 'top-left',
    TOP_RIGHT = 'top-right',
    LEFT = 'left',
}

export const CommentOverlayFontSizeC = fromEnum('CommentOverlayFontSize', CommentOverlayFontSize);

export const GlobalSettingsC = t.type({
    dateFormat: fromEnum('DateFormatName', DateFormatName),
    streamingMethod: fromEnum('StreamingMethod', StreamingMethod),
    commentOverlaySize: fromEnum('CommentOverlayFontSize', CommentOverlayFontSize),
    time: TimeSettingsC,
    sync: SyncSettingsC,
});

export type GlobalSettings = t.TypeOf<typeof GlobalSettingsC>;

@Module({ name: 'GlobalSettingsModule' })
export default class GlobalSettingsModule extends VuexModule {
    private _dateFormat: DateFormat | null = null;

    private _dateTimeFormat: DateTimeFormat | null = null;

    private _streamingMethod: StreamingMethod | null = null;

    private _commentOverlayFontSize: CommentOverlayFontSize | null = null;

    private _notifications: boolean | null = null;

    private _alerts: boolean | null = null;

    public get dateFormat(): DateFormat | null {
        return this._dateFormat;
    }

    public get dateTimeFormat(): DateTimeFormat | null {
        return this._dateTimeFormat;
    }

    public get streamingMethod(): StreamingMethod | null {
        return this._streamingMethod;
    }

    public get commentOverlayFontSize(): CommentOverlayFontSize | null {
        return this._commentOverlayFontSize;
    }

    @Mutation
    public setDateFormatByName(dateFormatName: DateFormatName): void {
        this._dateFormat = (<never>DateFormat)[dateFormatName];
        this._dateTimeFormat = (<never>DateTimeFormat)[dateFormatName];
    }

    @Mutation
    public setStreamingMethod(streamingMethod: StreamingMethod): void {
        this._streamingMethod = streamingMethod;
    }

    @Mutation
    public setCommentOverlayFontSize(commentOverlayFontSize: CommentOverlayFontSize): void {
        this._commentOverlayFontSize = commentOverlayFontSize;
    }

    @Mutation
    public setNotifications(notifications: boolean): void {
        this._notifications = notifications;
    }

    @Mutation
    public setAlerts(alerts: boolean): void {
        this._alerts = alerts;
    }
}
