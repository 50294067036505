import { RouteRecordRaw } from 'vue-router';
import ROUTE_NAMES from './routeNames';
import { permissionsModule as pM } from '../store';

const reportsRoutes: RouteRecordRaw[] = [
    {
        path: '/report',
        name: ROUTE_NAMES.REPORT_ROOT,
        component: () => import('../views/Report/Report.vue'),
        meta: {
            permissions: (): string[] => [pM.allPermissions.reports],
        },
        redirect: { name: ROUTE_NAMES.REPORT_LIST },
        children: [
            {
                path: 'list',
                name: ROUTE_NAMES.REPORT_LIST,
                component: () => import('../views/Report/ReportList.vue'),
                meta: {
                    title: 'Reports',
                    permissions: (): string[] => [pM.allPermissions.reports],
                },
            },
            {
                path: 'tiles',
                name: ROUTE_NAMES.REPORT_TILES,
                component: () => import('../views/Report/ReportTiles.vue'),
                meta: {
                    title: 'Reports',
                    permissions: (): string[] => [pM.allPermissions.reports],
                },
            },
        ],
    },
    {
        path: '/report/:id',
        name: ROUTE_NAMES.REPORT_VIEW,
        component: () => import('../views/Report/ReportView.vue'),
        meta: {
            permissions: (): string[] => [pM.allPermissions.reports],
            title: 'Reports',
        },
        props: true,
    },
];

export default reportsRoutes;
