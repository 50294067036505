import {
    Action, Module, Mutation, VuexModule,
} from 'vuex-module-decorators';

@Module({ name: 'TimerModule' })
export default class TimerModule extends VuexModule {
    private _now: Date = new Date()

    private _timers: string[] = [];

    private _intervalId: number|null = null;

    @Mutation
    private updateTime(): void {
        this._now = new Date();
    }

    @Mutation
    private addTimer(name: string): void {
        this._timers.push(name);
    }

    @Mutation
    private removeTimer(name: string): void {
        if (!this._timers.includes(name)) {
            return;
        }

        this._timers = this._timers.filter((timer) => timer !== name);
    }

    @Mutation
    private startInterval(callable: () => void): void {
        if (this._intervalId !== null) {
            return;
        }

        this._intervalId = window.setInterval(callable, 1000);
    }

    @Mutation
    private clearInterval(): void {
        if (this._intervalId === null) {
            return;
        }

        clearInterval(this._intervalId);
        this._intervalId = null;
    }

    @Action
    public destroyTimer(name: string): void {
        this.context.commit('removeTimer', name);
        if (!this.hasTimers) {
            this.context.commit('clearInterval');
        }
    }

    @Action
    public startTimer(name: string): void {
        this.context.commit('addTimer', name);

        const updateTime = () => this.context.commit('updateTime');
        this.context.commit('startInterval', updateTime);
    }

    public get now(): Date {
        return this._now;
    }

    private get hasTimers(): boolean {
        return this._timers.length !== 0;
    }
}
