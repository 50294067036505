<template>
    <div class="container main-container error-page-wrapper">
        <div
            v-if="userModule.theme === 'default'"
            class="error-logo"
        >
            <FourIcon
                width="49"
                height="65"
            />
            <img
                class="error-cat"
                src="../../../images/cat.gif"
                alt="cat"
            >
            <FourIcon
                width="49"
                height="65"
            />
        </div>
        <span
            v-else
            class="logo-text"
        >404</span>

        <h1 class="error-title">
            Page not found
        </h1>
        <p class="error-text">
            Sorry, the page you are looking for doesn’t exist or has been moved.
            Here are some helpful links:
        </p>

        <div class="error-action">
            <Button
                variant="light"
                size="md"
                @click="router.go(-1)"
            >
                <BackIcon />
                <span>Go back</span>
            </Button>
            <Button
                variant="dark"
                :to="{name: 'home'}"
                size="md"
                active-class="active-test-me-class"
            >
                Take me home
            </Button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router';
import Button from '../../components/Elements/Button.vue';
import BackIcon from '../../../images/chevron-left.svg?component';
import FourIcon from '../../../images/404.svg?component';
import { userModule } from '../../store';

const router = useRouter();
</script>

<style lang="scss" scoped>
@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/spacings';
@import '../../../styles/abstracts/font-sizes';

.error-page-wrapper {
    display: flex;

    flex-direction: column;

    justify-content: center;

    max-width: 30rem;

    padding: $spacing-xxl-5;

    margin: auto;

    text-align: center;

    background-color: var(--theme-color-surface-primary-default);

    border-radius: $border-radius-xl;
}

.error-action {
    display: flex;

    gap: $spacing-s;

    justify-content: center;
}

.error-logo {
    display: flex;

    gap: $spacing-m;

    align-items: center;

    width: max-content;
    height: 5.25rem;

    margin: 0 auto;

    svg {
        width: 100%;

        fill: currentColor;
    }

    .error-cat {
        max-width: 6.25rem;
    }
}

.error-text {
    margin-bottom: $spacing-xxl-2;

    font-size: $font-size-m;

    color: var(--theme-color-text-secondary);
}

.error-title {
    margin-top: $spacing-xxl-2;
    margin-bottom: $spacing-m;
}

.logo-text {
    font-size: 100px;
    font-weight: bold;
    line-height: 84px;
    letter-spacing: 10px;
}
</style>
