import { FieldsRecord } from '../types/Field';

import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import SchemaConverter from '../../SchemaConverter';
import { FormFieldPartial, RawFormField } from '../types/FormField';

export default (form: RawFormField): null | FormFieldPartial => {
    // item with title 'prototype' is a legacy hack to send form prototype, it's not a real field
    if (form.title === 'prototype') {
        return null;
    }

    const fields = Object.entries(form.properties)
        .reduce<FieldsRecord>((acc, [name, field]) => {
            const convertedField = (new SchemaConverter(field)).convert();

            if (!convertedField) {
                return acc;
            }

            convertedField.isRequired = form.required?.includes(name);
            acc[name] = convertedField;

            return acc;
        }, {});

    return {
        title: form.title,
        name: form.name,
        fields,
        renderAs: FIELD_RENDER_TYPES.FORM,
        labelAttr: form.label_attr || null,
    };
};
