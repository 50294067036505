import * as t from 'io-ts';
import convertForm from './convertForm';
import { FormFieldPartialWithoutRenderAsC, RawFormFieldC } from '../types/FormField';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import { assertIsNonNullable } from '../../../../typeAssertions/undefined';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';

export const RawDateFilterC = t.intersection([
    RawFormFieldC,
    t.type({
        renderer: t.literal(FIELD_RENDER_TYPES.DATE_FILTER),
    }),
]);

export const RawDateFilterPartialC = t.intersection([
    FormFieldPartialWithoutRenderAsC,
    t.type({
        renderAs: t.literal(FIELD_RENDER_TYPES.DATE_FILTER),
    }),
]);

export const DateFilterC = t.intersection([RawDateFilterPartialC, FieldCommonPropsC]);

export type RawDateFilter = t.TypeOf<typeof RawDateFilterC>;
export type RawDateFilterPartial = t.TypeOf<typeof RawDateFilterPartialC>;
export type DateFilter = t.TypeOf<typeof DateFilterC>;

export default (field: RawDateFilter): RawDateFilterPartial => {
    const convertedForm = convertForm(field);
    assertIsNonNullable(convertedForm);

    return {
        ...convertedForm,
        renderAs: FIELD_RENDER_TYPES.DATE_FILTER,
    };
};
