import {
    Action, Module, Mutation, VuexModule,
} from 'vuex-module-decorators';
import breakpoints from '../../../styles/vendors/_breakpoints.module.scss?module';
import assertIsString from '../../../scripts/typeAssertions/string';

assertIsString(breakpoints?.lg);
const isLgOrMoreQueryString = `(min-width: ${breakpoints.lg})`;
const isLgOrMoreQuery = window.matchMedia(isLgOrMoreQueryString);

@Module({ name: 'MediaQueriesModule' })
export default class MediaQueriesModule extends VuexModule {
    private _isInitialized = false;

    private _isLgOrMore = isLgOrMoreQuery.matches;

    @Action
    public init(): void {
        if (this.isInitialized) {
            throw new Error('This module must be initialized only once');
        }
        this.context.commit('setIsInitialized');

        const setIsLgOrMore = (event: MediaQueryListEvent) => {
            this.context.commit('setIsLgOrMore', event.matches);
        };

        try {
            // Chrome & Firefox
            isLgOrMoreQuery.addEventListener('change', setIsLgOrMore);
        } catch {
            // Old Safari
            isLgOrMoreQuery.addListener(setIsLgOrMore);
        }
    }

    @Mutation
    private setIsLgOrMore(isLgOrMore: boolean): void {
        this._isLgOrMore = isLgOrMore;
    }

    @Mutation
    private setIsInitialized(): void {
        this._isInitialized = true;
    }

    public get isLgOrMore(): boolean {
        return true;
    }

    public get isMobile(): boolean {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    private get isInitialized(): boolean {
        return this._isInitialized;
    }
}
