<template>
    <div class="notification-popup-wrapper">
        <div
            class="notification-popup"
            :data-cy="cypressId"
        >
            <div
                class="notifications-popup-header"
                :class="{'exception-header--list': false}"
            >
                <span class="notifications-popup-header__text">
                    {{ $options.texts.title }}
                    <VolumeOffIcon
                        v-if="!notificationSoundEnabled"
                        class="icon"
                    />
                </span>
                <div class="notifications-popup-header__actions">
                    <Tooltip
                        id="notification-action-settings"
                        title="Settings"
                        no-min-width
                    >
                        <DropdownButton
                            id="notification-actions"
                            circle
                            has-only-icon
                            :shadowed="false"
                            :show-dropdown="showDropdown"
                            variant="light"
                            size="sm"
                            button-class="dots"
                            aria-label="Notification Settings"
                            @dropdown-show="dropdownShown"
                            @dropdown-hidden="dropdownHidden"
                            @click.stop.prevent="dropdownShown"
                        >
                            <MoreIcon />
                            <template #dropdown>
                                <DropdownButtonOption
                                    @click.stop="muteToggle"
                                >
                                    <Component
                                        :is="muteIcon"
                                        width="24"
                                        height="24"
                                    />
                                    <span>{{ muteText }}</span>
                                </DropdownButtonOption>
                                <DropdownButtonOption
                                    @click.stop="readAll"
                                >
                                    <OpenEyeIcon
                                        width="24"
                                        height="24"
                                    />
                                    <span>Read all</span>
                                </DropdownButtonOption>
                            </template>
                        </DropdownButton>
                    </Tooltip>
                    <Tooltip
                        id="notification-action-close"
                        title="Close"
                        no-min-width
                    >
                        <Button
                            circle
                            has-only-icon
                            variant="light"
                            size="sm"
                            aria-label="Hide Notifications Center"
                            @click="$emit('hideNotifications')"
                        >
                            <CloseIcon
                                width="16"
                                height="16"
                            />
                        </Button>
                    </Tooltip>
                </div>
            </div>
            <Scrollable
                class="scrollable-notifications-list"
                @scroll-reach-end="$emit('scroll-reach-end', $event)"
            >
                <ul
                    v-if="notifications.length > 0"
                    class="notifications-list"
                >
                    <li
                        v-for="(notification, index) in sortedNotifications"
                        :key="index"
                    >
                        <Component
                            :is="notificationByType(notification.objectType)"
                            :notification="notification"
                            @reload-notifications="$emit('reloadNotifications')"
                        />
                    </li>
                </ul>
                <NoNotificationsPlaceholder
                    v-else
                    title="No unread notifications"
                />
            </Scrollable>
            <div class="notifications-footer">
                <Link @click="viewAll">
                    <span class="notifications-footer-view-all-button">
                        View all
                    </span>
                </Link>
            </div>
            <div class="notification-popup__arrow" />
        </div>
    </div>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { cloneDeep } from 'lodash';
import Element from '../../../scripts/mixins/Element';
import Link from '../Elements/Link.vue';
import Button from '../Elements/Button.vue';
import BackIcon from '../../../images/chevron-left.svg?component';
import CloseIcon from '../../../images/x.svg?component';
import OpenEyeIcon from '../../../images/open-eye.svg?component';
import MoreIcon from '../../../images/dots-bold.svg?component';
import VolumeIcon from '../../../images/volume.svg?component';
import VolumeOffIcon from '../../../images/volume-off.svg?component';
import ChevronDownIcon from '../../../images/chevron-down.svg?component';
import ChevronUpIcon from '../../../images/chevron-up.svg?component';
import DropdownButton from '../Elements/DropdownButton.vue';
import Scrollable from '../Elements/Scrollable.vue';
import NotificationCard from './NotificationCard.vue';
import SharingNotificationMessage from './Messages/SharingNotificationMessage.vue';
import ReplyNotificationMessage from './Messages/ReplyNotificationMessage.vue';
import TagNotificationMessage from './Messages/TagNotificationMessage.vue';
import { NotificationItem } from '../../../scripts/types/Notification';
import { userModule } from '../../store';
import NoNotificationsPlaceholder from './NoNotificationsPlaceholder.vue';
import { muteNotifications, readAll } from '../../../scripts/api/notification/NotificationApi';
import DropdownButtonOption from '../Elements/DropdownButtonOption.vue';
import Tooltip from '../Elements/Tooltip.vue';

const notificationTypes = {
    Sharing: SharingNotificationMessage,
    Reply: ReplyNotificationMessage,
    Tag: TagNotificationMessage,
};

@Options({
    components: {
        DropdownButtonOption,
        NoNotificationsPlaceholder,
        SharingNotificationMessage,
        ReplyNotificationMessage,
        TagNotificationMessage,
        NotificationCard,
        Scrollable,
        DropdownButton,
        Element,
        Link,
        Button,
        VolumeOffIcon,
        VolumeIcon,
        BackIcon,
        OpenEyeIcon,
        ChevronDownIcon,
        ChevronUpIcon,
        CloseIcon,
        MoreIcon,
        Tooltip,
    },
    texts: {
        title: 'Notifications',
        viewAllButton: ' View all',
    },
})
export default class NotificationsPopup extends Vue {
    @Prop({ type: String })
    public readonly id!: string;

    @Prop({ type: String, default: null })
    public readonly cypressId!: string | null;

    @Prop({ type: Array, required: true })
    public readonly notifications!: NotificationItem[];

    public showDropdown = false;

    public get muteText(): string {
        return !this.notificationSoundEnabled ? 'Unmute' : 'Mute';
    }

    public get muteIcon(): string {
        return !this.notificationSoundEnabled ? 'VolumeIcon' : 'VolumeOffIcon';
    }

    public notificationByType(
        type: keyof typeof notificationTypes,
    ): typeof notificationTypes[keyof typeof notificationTypes] | string {
        if (Object.keys(notificationTypes).includes(type)) {
            return notificationTypes[type];
        }

        return 'div';
    }

    public async muteToggle(): Promise<void> {
        await muteNotifications();

        userModule.setNotificationSoundEnabled(!userModule.isNotificationSoundEnabled);
    }

    public async viewAll(): Promise<void> {
        await this.readAll();
        await this.$router.push({ name: 'notifications' });
    }

    public async readAll(): Promise<void> {
        await readAll();
        this.$emit('reloadNotifications');
    }

    public get notificationSoundEnabled(): boolean {
        return userModule.isNotificationSoundEnabled;
    }

    public get sortedNotifications(): NotificationItem[] {
        const notifications = cloneDeep(this.notifications) as NotificationItem[];
        return notifications.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
    }

    public dropdownShown() {
        this.showDropdown = true;
    }

    public dropdownHidden() {
        this.showDropdown = false;
    }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/vendors/breakpoints';
@import '../../../styles/abstracts/mixins';
@import '../../../styles/abstracts/spacings';
@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/font-sizes';
@import '../../../styles/abstracts/z-indexes';
@import '../../../styles/abstracts/colors_old';

$padding-desktop: $spacing-l;
$margin-desktop: $spacing-xs;

.dropdown-button-option {
    display: flex;

    gap: $spacing-s;

    align-items: center;

    font-size: $font-size-m;

    svg {
        fill: var(--theme-color-icon-primary);
    }

    &:hover {
        border-radius: $border-radius-xs;
    }
}

.notification-popup-wrapper {
    position: absolute;

    height: 100%;
}

.notification-popup {
    position: relative;

    z-index: $user-notification-popup-z-index;

    display: flex;

    flex-flow: column;

    justify-content: space-between;

    width: 20rem;
    height: 32rem;

    padding: $spacing-xs $spacing-xs 0 $spacing-xs;
    margin-top: calc(100vh - 36rem);
    margin-left: 4.5rem;

    color: var(--theme-color-text-primary);

    background-color: var(--theme-color-surface-primary-default);
    border: 1px solid var(--theme-color-border-primary-grey);

    border-radius: $border-radius-lg;

    box-shadow: var(--shadow-4);

    &__arrow {
        /* see https://css-tricks.com/snippets/css/css-triangle/ */
        @include media-breakpoint-up(xs) {
            $height-to-width-ratio: 3.2;

            $tail-width: $margin-desktop/2;
            $tail-height: $tail-width * $height-to-width-ratio;

            position: absolute;
            bottom: $margin-desktop + $padding-desktop;
            left: -($tail-width * 2);

            color: var(--theme-color-surface-primary-default);

            border-top: $tail-height/2 solid transparent;
            border-right: $tail-width solid;
            border-bottom: $tail-height/2 solid transparent;
            border-left: $tail-width solid transparent;
        }
    }
}

.notifications-popup-header {
    display: flex;

    align-items: center;
    justify-content: space-between;

    padding: $spacing-xs $spacing-s;

    font-size: $font-size-lg;

    font-weight: 600;

    &__text {
        display: flex;

        gap: $spacing-xxs;

        align-items: center;

        svg {
            width: 1.25rem;
            height: 1.25rem;

            fill: var(--theme-color-light-shadow);
        }
    }

    &__actions {
        display: flex;

        gap: $spacing-xs;

        align-items: center;
    }
}

.scrollable-notifications-list {
    height: calc(100% - 6rem);
}

.notifications-footer {
    padding: $spacing-sm $spacing-xxl-10;

    margin: 0 $spacing-sm;

    text-align: center;

    border-top: 1px solid var(--theme-color-border-divider-secondary);

    .notifications-footer-view-all-button {
        color: var(--theme-color-text-link) !important;

        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.notifications-list {
    padding: 0;
    margin: 0;

    list-style: none;

    > li {
        padding: $spacing-s;

        cursor: pointer;
    }

    li:hover {
        background-color: var(--theme-color-surface-secondary-default);

        border-radius: $border-radius;
    }
}

</style>
