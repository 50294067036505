// eslint-disable-next-line import/prefer-default-export
export const allPermissions = {
    admin: 'admin',
    adminContainers: 'admin--containers',
    adminContainerItemsWithoutContainer: 'admin--container--items-without-container',
    adminCustomization: 'admin--customization',
    adminEmailSettings: 'admin--email-settings',
    adminGlobalSettings: 'admin--global-settings',
    adminGeneral: 'admin--general',
    adminHelp: 'admin--help',
    adminLdap: 'admin--ldap',
    adminServers: 'admin--servers',
    adminServersSsoConfigs: 'admin--servers--sso-configs',
    adminServersLdapServers: 'admin--servers--ldap-servers',
    adminLdapSyncSchedules: 'admin--ldap--sync-schedules',
    adminLdapUserImport: 'admin--ldap--user-import',
    adminLogs: 'admin--logs',
    adminMediaServers: 'admin--media-servers',
    adminMultiDelete: 'admin--multi-delete',
    adminRooms: 'admin--rooms',
    adminSaml: 'admin--saml',
    adminSoftwareInformation: 'admin--software-information',
    adminTemplates: 'admin--templates',
    adminServersTranscriptionService: 'admin--servers--transcription-service',
    adminUsersAndGroups: 'admin--users-and-groups',
    adminUsersAndGroupsImport: 'admin--users-and-groups--import',
    adminUsersAndGroupsFileImport: 'admin--users-and-groups--file-import',
    adminServersWowza: 'admin--servers--wowza',
    general: 'general',
    generalAlert: 'general--alert',
    generalChangePassword: 'general--change-password',
    generalEditProfile: 'general--edit-profile',
    generalHome: 'general--home',
    generalPasswordComplexity: 'general--password-complexity',
    monitoring: 'monitoring',
    monitoringGenerateLink: 'monitoring--generate-link',
    monitoringLockRooms: 'monitoring--lock-rooms',
    monitoringMultiSelect: 'monitoring--multi-select',
    monitoringPtz: 'monitoring--ptz',
    monitoringPtzCanPtzAllAuthors: 'monitoring--ptz--can-ptz-all-authors',
    monitoringPtzEditPresets: 'monitoring--ptz--edit-presets',
    monitoringPtzPtzControl: 'monitoring--ptz--ptz-control',
    monitoringPtzUsePresets: 'monitoring--ptz--use-presets',
    monitoringRecording: 'monitoring--recording',
    monitoringRecordingAddMarkers: 'monitoring--recording--add-markers',
    monitoringRecordingComments: 'monitoring--recording--comments',
    monitoringRecordingCommentsAdd: 'monitoring--recording--comments--add',
    monitoringRecordingCommentsDelete: 'monitoring--recording--comments--delete',
    monitoringRecordingCommentsDeleteAll: 'monitoring--recording--comments--delete-all',
    monitoringRecordingCommentsEdit: 'monitoring--recording--comments--edit',
    monitoringRecordingCommentsEditAll: 'monitoring--recording--comments--edit-all',
    monitoringRecordingCommentsRecordAudio: 'monitoring--recording--comments--record-audio',
    monitoringRecordingEvaluation: 'monitoring--recording--evaluation',
    monitoringRecordingEvaluationViewScores: 'monitoring--recording--evaluation--view-scores',
    monitoringRecordingOptions: 'monitoring--recording--options',
    monitoringRecordingOptionsAllAuthors: 'monitoring--recording--options-all-authors',
    monitoringRecordingPauseAllAuthors: 'monitoring--recording--pause-all-authors',
    monitoringRecordingPauseRecording: 'monitoring--recording--pause-recording',
    monitoringRecordingPrepareRecording: 'monitoring--recording--prepare-recording',
    monitoringRecordingReassignAuthor: 'monitoring--recording--reassign-author',
    monitoringRecordingResumeAllAuthors: 'monitoring--recording--resume-all-authors',
    monitoringRecordingRetention: 'monitoring--recording--retention',
    monitoringRecordingSharing: 'monitoring--recording--sharing',
    monitoringRecordingStartRecording: 'monitoring--recording--start-recording',
    monitoringRecordingStopAllAuthors: 'monitoring--recording--stop-all-authors',
    monitoringRecordingStopConfirmation: 'monitoring--recording--stop-confirmation',
    monitoringSearch: 'monitoring--search',
    monitoringTalkback: 'monitoring--talkback',
    monitoringUnlockAllUsers: 'monitoring--unlock-all-users',
    monitoringViews: 'monitoring--views',
    reports: 'reports',
    reportsAddReport: 'reports--add-report',
    reportsDeleteReport: 'reports--delete-report',
    reportsEditReport: 'reports--edit-report',
    review: 'review',
    reviewAddTranscript: 'review--add-transcript',
    reviewComments: 'review--comments',
    reviewCommentsAdd: 'review--comments--add',
    reviewCommentsDelete: 'review--comments--delete',
    reviewCommentsDeleteAll: 'review--comments--delete-all',
    reviewCommentsEdit: 'review--comments--edit',
    reviewCommentsEditAll: 'review--comments--edit-all',
    reviewCommentsCommentOverlay: 'review--comments-comment-overlay',
    reviewCommentsRecordAudio: 'review--comments--record-audio',
    reviewDeleteRecordings: 'review--delete-recordings',
    reviewDownloadRecordings: 'review--download-recordings',
    reviewDownloadRecordingsAudioOnly: 'review--download-recordings--audio-only',
    reviewDownloadRecordingsMultiview: 'review--download-recordings--multiview',
    reviewEditInformation: 'review--edit-information',
    reviewEditRetention: 'review--edit-retention',
    reviewEditSharing: 'review--edit-sharing',
    reviewEvaluation: 'review--evaluation',
    reviewEvaluationEdit: 'review--evaluation--edit',
    reviewEvaluationViewScores: 'review--evaluation--view-scores',
    reviewGenerateLink: 'review--generate-link',
    reviewMarkers: 'review--markers',
    reviewMarkersAddMarkers: 'review--markers--add-markers',
    reviewMarkersDeleteMarkers: 'review--markers--delete-markers',
    reviewMarkersEditMarkers: 'review--markers--edit-markers',
    reviewMarkersMarkerOverlay: 'review--markers--marker-overlay',
    reviewMarkersSeeAll: 'review--markers--see-all',
    reviewReassignAuthor: 'review--reassign-author',
    reviewTools: 'review--tools',
    reviewToolsClip: 'review--tools--clip',
    reviewToolsRedact: 'review--tools--redact',
    reviewTranscriptionsEdit: 'review--transcriptions--edit',
    reviewZoom: 'review--zoom',
    schedule: 'schedule',
    scheduleAddSchedule: 'schedule--add-schedule',
    scheduleChangeAuthor: 'schedule--change-author',
    scheduleControl: 'schedule--control',
    scheduleDeleteRemoveSchedules: 'schedule--delete-and-remove-schedules',
    scheduleDeleteRemoveAllSchedules: 'schedule--delete-and-remove-all-schedules',
    scheduleEditAllSchedules: 'schedule--edit-all-schedules',
    scheduleEditSchedules: 'schedule--edit-schedules',
    scheduleExceptions: 'schedule--exceptions',
    scheduleRetention: 'schedule--retention',
    scheduleSharing: 'schedule--sharing',
    upload: 'upload',
    uploadAuthor: 'upload--author',
    uploadRetention: 'upload--retention',
    uploadSharing: 'upload--sharing',
};
