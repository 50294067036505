import * as t from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import { FormFieldPartialWithoutRenderAsC, RawFormFieldC } from '../types/FormField';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';
import convertForm from './convertForm';
import { assertIsNonNullable } from '../../../../typeAssertions/undefined';

export const RawScheduleRulesC = t.intersection([
    RawFormFieldC,
    t.type({
        renderer: t.literal(FIELD_RENDER_TYPES.SCHEDULE_RULES),
    }),
]);

export const ScheduleRulesPartialC = t.intersection([
    FormFieldPartialWithoutRenderAsC,
    t.type({
        renderAs: t.literal(FIELD_RENDER_TYPES.SCHEDULE_RULES),
    }),
]);

export const ScheduleRulesC = t.intersection([ScheduleRulesPartialC, FieldCommonPropsC]);

export type RawScheduleRules = t.TypeOf<typeof RawScheduleRulesC>;
export type ScheduleRulesPartial = t.TypeOf<typeof ScheduleRulesPartialC>;
export type ScheduleRules = t.TypeOf<typeof ScheduleRulesC>;

export default (field: RawScheduleRules): ScheduleRulesPartial => {
    const convertedForm = convertForm(field);
    assertIsNonNullable(convertedForm);

    return {
        ...convertedForm,
        renderAs: FIELD_RENDER_TYPES.SCHEDULE_RULES,
    };
};
