import * as t from 'io-ts';
import RequestBuilder, { Significance } from '../../helpers/Request/Builder/RequestBuilder';
import { RoutingSymfony } from '../../player/mobile/helpers/routing';
import { socket } from '../../socket';
import { Channel } from '../../../vue/composables/socket';
import { userModule } from '../../../vue/store';

const routes = {
    checkUserLogoutSoon: 'check_user_logout_soon',
} as const;

const CheckUserLogoutSoonResponseC = t.union([
    t.type({
        should_notify: t.literal(false),
    }),
    t.type({
        should_notify: t.literal(true),
        text: t.string,
        title: t.string,
    }),
]);

const UserLogoutSoonMessageC = t.type({
    id: t.number,
});

type Handler = (data: { text: string, title: string }) => void;

let handlers: Handler[] = [];

type MessageResponse = false|{title: string, message: string};

export const checkIfUserLogoutSoon = async (): Promise<MessageResponse> => {
    const url = RoutingSymfony.generate(routes.checkUserLogoutSoon);

    const response = await new RequestBuilder(CheckUserLogoutSoonResponseC)
        .setUrl(url)
        .setSignificance(Significance.Invisible)
        .setMethod('GET')
        .build()
        .send();

    if (!response.should_notify) {
        return false;
    }

    return {
        title: response.title,
        message: response.text,
    };
};

const onUserLogoutSoon = async (json: string) => {
    if (!userModule.isUserLoggedIn) {
        return;
    }

    const message = JSON.parse(json);

    if (!UserLogoutSoonMessageC.is(message)) {
        return;
    }

    if (userModule.userId !== message.id) {
        return;
    }

    const response = await checkIfUserLogoutSoon();

    if (!response) {
        return;
    }

    handlers.forEach((handler) => handler({ title: response.title, text: response.message }));
};

const subscribeToUserLogoutSoon = (): void => {
    socket.on(Channel.USER_LOGOUT_SOON, onUserLogoutSoon);
};

const unsubscribeFromUserLogoutSoon = (): void => {
    socket.removeListener(Channel.USER_LOGOUT_SOON, onUserLogoutSoon);
};

export const addOnUserLogoutSoonHandler = (handler: Handler): void => {
    if (handlers.length === 0) {
        subscribeToUserLogoutSoon();
    }

    handlers.push(handler);
};

export const removeOnUserLogoutSoonHandler = (handler: Handler): void => {
    handlers = handlers.filter((h) => h !== handler);

    if (handlers.length === 0) {
        unsubscribeFromUserLogoutSoon();
    }
};
