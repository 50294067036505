// Used only in Observe and RecordShow for comments
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ name: 'RecordModule' })
export default class RecordModule extends VuexModule {
    private _recordId: number | null = null;

    private _recordingStartedTimestamp: number | null = null;

    private _markTime: number | null = null;

    @Mutation
    public setRecordId(recordId: number | null): void {
        this._recordId = recordId;
    }

    @Mutation
    public setRecordingStartedTimestamp(timestamp: number | null): void {
        this._recordingStartedTimestamp = timestamp;
    }

    @Mutation
    public setMarkTime(markTime: number): void {
        this._markTime = markTime;
    }

    public get recordId(): number | null {
        return this._recordId;
    }

    public get recordingStartedTimestamp(): number | null {
        return this._recordingStartedTimestamp;
    }

    public get markTime(): number | null {
        return this._markTime;
    }
}
