import * as t from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import optional from '../../../../io-ts/optional';
import instanceOf from '../../../../io-ts/instanceOf';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';

export const RawFileFieldC = t.intersection([
    t.type({
        type: t.literal('file'),
        renderer: t.literal(FIELD_RENDER_TYPES.FILE),
        title: t.union([t.string, t.null]),
        name: t.string,
        attr: t.intersection([
            t.partial({
                publicFileUrl: t.union([t.string, t.null]),
                maxSize: t.number,
            }),
            t.type({
                accept: t.string,
            }),
        ]),
    }),
    t.partial({
        value: t.union([t.string, t.null]),
    }),
]);

const FileC = t.intersection([
    instanceOf(File),
    t.partial({
        isFake: t.boolean,
    }),
]);

export const FileFieldPartialC = t.type({
    renderAs: t.literal(FIELD_RENDER_TYPES.FILE),
    accept: optional(t.string),
    file: t.union([FileC, t.null]),
    publicFileUrl: t.union([t.string, t.null]),
    maxSize: t.union([t.number, t.null]),
});

export const FileFieldC = t.intersection([FileFieldPartialC, FieldCommonPropsC]);

export type RawFileField = t.TypeOf<typeof RawFileFieldC>
export type FileFieldPartial = t.TypeOf<typeof FileFieldPartialC>
export type FileField = t.TypeOf<typeof FileFieldC>

const getFakeFile = (field: RawFileField): File & {isFake?: boolean} | null => {
    let file: File & {isFake?: boolean} | null = null;

    if (field.value) {
        file = new File([], field.value);
    } else if (field.attr.publicFileUrl) {
        file = new File([], 'fake');
    }

    if (file) {
        file.isFake = true;
    }

    return file;
};

export default (field: RawFileField): FileFieldPartial => {
    const file = getFakeFile(field);

    return {
        renderAs: field.renderer,
        accept: field.attr.accept,
        file,
        publicFileUrl: field.attr.publicFileUrl ?? null,
        maxSize: field.attr.maxSize ?? null,
    };
};
