import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import toSelectFormat, { SelectChoice, RawSelectField, RawSelectFieldC } from './toSelectFormat';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';

export const RawUserFilterFieldC = t.intersection([
    RawSelectFieldC,
    t.type({
        attr: t.type({
            'data-render-as-user-filter': t.literal(true),
        }),
    }),
]);

export const FilterChoiceC = t.type({
    title: t.string,
    value: t.string,
});

export const UserFilterFieldPartialC = t.type({
    renderAs: t.literal(FIELD_RENDER_TYPES.FILTER),
    choices: t.array(FilterChoiceC),
});

export const UserFilterFieldC = t.intersection([UserFilterFieldPartialC, FieldCommonPropsC]);

export type RawUserFilterField = TypeOf<typeof RawUserFilterFieldC>
export type UserFilterFieldPartial = TypeOf<typeof UserFilterFieldPartialC>;
export type UserFilterField = TypeOf<typeof UserFilterFieldC>;
export type FilterChoice = TypeOf<typeof FilterChoiceC>

export default (field: RawSelectField): UserFilterFieldPartial => {
    const convertedAsSelect = toSelectFormat(field);
    const parseFilterChoice = (acc: FilterChoice[], choice: SelectChoice) => {
        const { value, title } = choice;
        if (typeof value === 'string' && value.length > 0) {
            acc.push({
                title,
                value,
            });
        }
        return acc;
    };

    const choices = convertedAsSelect.choices.reduce(parseFilterChoice, [] as FilterChoice[]);

    return {
        renderAs: FIELD_RENDER_TYPES.FILTER,
        choices,
    };
};
