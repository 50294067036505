import * as t from 'io-ts';
import { RoutingSymfony } from '../../player/mobile/helpers/routing';
import RequestBuilder, { Significance } from '../../helpers/Request/Builder/RequestBuilder';
import { NotificationsPopupResponse, NotificationsPopupResponseC } from '../../types/Notification';

export async function getPopupNotifications(formData: FormData):
    Promise<NotificationsPopupResponse> {
    const url = RoutingSymfony.generate('api_get_notifications');

    const paginator = await new RequestBuilder(NotificationsPopupResponseC)
        .setUrl(url)
        .setMethod('POST')
        .setSignificance(Significance.Invisible)
        .setFormData(formData)
        .build()
        .send();

    return paginator;
}

export async function readAll(): Promise<void> {
    const url = RoutingSymfony.generate('api_make_read_all_notifications');
    const request = new RequestBuilder(t.unknown)
        .setMethod('POST')
        .setUrl(url)
        .setSignificance(Significance.Invisible)
        .build();

    await request.send();
}

export async function muteNotifications(): Promise<void> {
    const url = RoutingSymfony.generate('api_mute_notifications');
    const request = new RequestBuilder(t.unknown)
        .setMethod('POST')
        .setUrl(url)
        .setSignificance(Significance.Invisible)
        .build();

    await request.send();
}

export async function readNotification(id: string): Promise<void> {
    const url = RoutingSymfony.generate('api_make_read_notification', { notification_id: id });

    await new RequestBuilder(t.unknown)
        .setUrl(url)
        .setMethod('GET')
        .setSignificance(Significance.Invisible)
        .build()
        .send();
}
