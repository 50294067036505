<template>
    <div
        class="scrollable"
        :class="{
            'scrollable--vertical': !horizontal,
            'scrollable--horizontal': horizontal,
            'scrollable--external': external,
        }"
        :style="{
            '--offset-top': offsetTopInPx,
        }"
    >
        <div
            ref="content"
            class="scrollable__content"
            :class="contentClass"
            @scroll.passive="onScroll"
        >
            <slot />
        </div>

        <div
            v-show="hasScrollbar"
            class="scrollable__scrollbar scrollbar"
            :class="[{
                'scrollable__scrollbar--active': isActive,
                'scrollbar--active': isActive,
            }, scrollbarClass]"
        >
            <div
                class="scrollbar__track"
                :style="trackStyles"
                @mousedown="startTrackMove"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import _ from 'lodash';

const SCROLL_REEACH_END_DEBOUNCE = 200;

@Options({

})
export default class Scrollable extends Vue {
    @Prop({ type: Boolean, default: false })
    public readonly horizontal!: boolean;

    @Prop({ type: String, default: null })
    public readonly contentClass!: string|null;

    @Prop({ type: String })
    public readonly scrollbarClass!: string;

    @Prop({ type: Number, default: 0 })
    public readonly offsetTop!: number;

    @Prop({ type: Boolean, default: false })
    public readonly external!: boolean;

    @Ref('content')
    public readonly contentElement!: HTMLElement;

    private trackSize: number|null = null;

    private trackOffset = 0;

    private observer: MutationObserver|null = null;

    public hasScrollbar = true;

    private trackMoveLocation: { x: number, y: number } | null = null;

    private debouncedEmitScrollReachEnd: _.DebouncedFunc<(event: Event) => void> | null = null;

    public mounted(): void {
        this.refreshTrackStyles();
        this.observer = this.createRefreshObserver();
        this.debouncedEmitScrollReachEnd = _.debounce(
            this.emitScrollReachEnd,
            SCROLL_REEACH_END_DEBOUNCE,
        );

        document.addEventListener('mousemove', this.onTrackMove);
        document.addEventListener('mouseup', this.stopTrackMove);
    }

    public destroyed(): void {
        if (this.observer) {
            this.observer.disconnect();
        }

        this.debouncedEmitScrollReachEnd?.cancel();
        document.removeEventListener('mousemove', this.onTrackMove);
        document.removeEventListener('mouseup', this.stopTrackMove);
    }

    public scrollToEnd(): void {
        const contentHeight = this.contentElement.scrollHeight - this.offsetTop;

        this.scrollTo(0, contentHeight);
    }

    public scrollTo(x: number, y: number): void {
        this.contentElement.scrollTo({
            behavior: 'smooth',
            top: y,
            left: x,
        });
    }

    private createRefreshObserver(): MutationObserver {
        const observer = new MutationObserver(this.refreshTrackStyles);

        observer.observe(
            this.contentElement,
            {
                childList: true,
                characterData: true,
                subtree: true,
            },
        );

        return observer;
    }

    private refreshTrackStyles(): void {
        if (!this.contentElement) {
            return;
        }

        const size = this.getSize();
        const scrollableSize = this.getScrollableSize();

        const trackPercentHeight = size / scrollableSize;
        this.trackSize = trackPercentHeight * size;

        const scroll = this.getScroll();

        const maxOffset = size - this.trackSize;
        const maxScroll = scrollableSize - size;
        const scrollToTrackOffsetPercent = maxOffset / maxScroll || 0;
        this.trackOffset = scroll * scrollToTrackOffsetPercent;

        this.hasScrollbar = scrollableSize > size;
    }

    public onScroll(event: Event): void {
        this.refreshTrackStyles();

        this.$emit('scroll', event);
        this.debouncedEmitScrollReachEnd?.(event);
    }

    public startTrackMove(event: MouseEvent): void {
        const { screenX, screenY } = event;
        this.trackMoveLocation = { x: screenX, y: screenY };
    }

    public onTrackMove(event: MouseEvent): void {
        const { screenX, screenY } = event;
        if (this.trackMoveLocation === null || this.trackSize === null) {
            return;
        }

        // avoid element selection
        event.preventDefault();
        event.stopPropagation();

        const { x, y } = this.trackMoveLocation;
        this.trackMoveLocation = { x: screenX, y: screenY };

        const offsetY = screenY - y;
        const offsetX = screenX - x;
        const offset = this.horizontal ? offsetX : offsetY;

        const size = this.getSize();
        const scrollableSize = this.getScrollableSize();
        const scrollPercent = this.calcScrollPercent(size, scrollableSize, this.trackSize);

        const scroll = offset * scrollPercent;
        const scrollRounded = Math.round(scroll);

        const scrollByX = this.horizontal ? scrollRounded : 0;
        const scrollByY = this.horizontal ? 0 : scrollRounded;
        this.contentElement.scrollBy(scrollByX, scrollByY);
    }

    protected calcScrollPercent(size: number, scrollable: number, trackSize: number): number {
        const maxOffset = size - trackSize;
        const maxScroll = scrollable - size;

        return maxScroll / maxOffset;
    }

    private stopTrackMove(): void {
        if (this.trackMoveLocation === null) {
            return;
        }

        this.trackMoveLocation = null;
    }

    private emitScrollReachEnd(event: Event) {
        const target = event.target as HTMLElement;
        const size = this.horizontal ? target.offsetWidth : target.offsetHeight - this.offsetTop;
        const scrollableSize = this.horizontal
            ? target.scrollWidth
            : target.scrollHeight - this.offsetTop;
        const scroll = this.horizontal ? target.scrollLeft : target.scrollTop;

        if (size + scroll >= scrollableSize) {
            this.$emit('scroll-reach-end', event);
        }
    }

    public get trackStyles(): Record<string, string> {
        const sizeProperty = this.horizontal ? 'width' : 'height';
        const offsetProperty = this.horizontal ? 'margin-left' : 'margin-top';

        return {
            [sizeProperty]: `${this.trackSize}px`,
            [offsetProperty]: `${this.trackOffset}px`,
        };
    }

    public get isActive(): boolean {
        return this.trackMoveLocation !== null;
    }

    public get offsetTopInPx(): string {
        return `${this.offsetTop}px`;
    }

    public getScroll(): number {
        return this.horizontal
            ? this.contentElement.scrollLeft
            : this.contentElement.scrollTop;
    }

    private getSize(): number {
        return this.horizontal
            ? this.contentElement.offsetWidth
            : this.contentElement.offsetHeight - this.offsetTop;
    }

    private getScrollableSize(): number {
        return this.horizontal
            ? this.contentElement.scrollWidth
            : this.contentElement.scrollHeight - this.offsetTop;
    }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/abstracts/spacings';
@import '../../../styles/abstracts/z-indexes';

$scrollbar-track-size: 0.1875rem;
$scrollbar-track-size-on-hover: $scrollbar-track-size * 2;
$scrollbar-padding: 0.125rem;

@mixin hide-default-scrollbar() {
    -ms-overflow-style: none; // IE
    scrollbar-width: none; // Firefox

    &::-webkit-scrollbar {
        display: none; // Chrome, Safari
    }
}

.scrollable {
    position: relative;

    &--vertical {
        height: 100%;
    }

    &--horizontal {
        width: 100%;
    }

    &--external {
        $scrollbar-external-offset: $scrollbar-track-size-on-hover + $spacing-lm;
        flex-grow: 1;

        width: calc(100% + #{$scrollbar-external-offset});
        padding-right: $scrollbar-external-offset;
    }

    &__content {
        @include hide-default-scrollbar;

        height: 100%;
    }

    &--vertical &__content {
        overflow-y: auto;
    }

    &--horizontal &__content {
        overflow-x: auto;
    }

    &__scrollbar {
        opacity: 0;

        transition: opacity 0.5s;
    }

    &__scrollbar--active,
    &:hover > &__scrollbar {
        opacity: 1;
    }
}

.scrollbar {
    position: absolute;
    z-index: $scroll-bar-z-index;

    .scrollable--vertical & {
        top: var(--offset-top);
        right: 0;

        width: min-content;
        height: calc(100% - var(--offset-top));
        padding: 0 $scrollbar-padding;
    }

    .scrollable--horizontal & {
        bottom: 0;
        left: 0;

        width: 100%;
        height: min-content;
        padding: $scrollbar-padding 0;
    }

    &__track {
        background-color: var(--theme-color-surface-scroll);
        border-radius: 2px;

        transition: width 0.5s, height 0.5s, background-color 0.5s;

        &:hover {
            cursor: pointer;

        }
    }

    .scrollable--vertical &__track {
        width: $scrollbar-track-size;
    }

    .scrollable--horizontal &__track {
        height: $scrollbar-track-size;
    }

    &--active &__track,
    &:hover &__track {
        width: $scrollbar-track-size-on-hover;

        border-radius: 3px;
    }
}
</style>
