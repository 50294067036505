import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { mediaQueriesModule } from '../store-accessor';

@Module({ name: 'MenuModule' })
export default class MenuModule extends VuexModule {
    private _isMenuShown = false;

    private _isPopupShown = false;

    private _doesMenuHasAnimation = false;

    @Mutation
    public showMenu(): void {
        this._isMenuShown = true;
        this._doesMenuHasAnimation = true;
    }

    @Mutation
    public hideMenu(): void {
        this._isPopupShown = false;
        this._isMenuShown = false;
    }

    @Mutation
    public switchToPopup(): void {
        this._isMenuShown = false;
        this._isPopupShown = !this._isPopupShown;
    }

    @Mutation
    public disableAnimationUntilShown(): void {
        this._doesMenuHasAnimation = false;
    }

    public get isMenuShown(): boolean {
        return this._isMenuShown;
    }

    public get isPopupShown(): boolean {
        return this._isPopupShown;
    }

    public get doesMenuHasAnimation(): boolean {
        return this._doesMenuHasAnimation;
    }

    public get doesRequestOverlay(): boolean {
        if (mediaQueriesModule.isLgOrMore) {
            return false;
        }

        return this.isMenuShown || this.isPopupShown;
    }
}
