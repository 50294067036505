import Converter from '../Converter';
import toSelectFormat, {
    SelectFieldPartial,
    RawSelectField,
    RawSelectFieldC,
} from '../functions/toSelectFormat';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';

export default class SelectFieldConverter extends Converter<RawSelectField> {
    public canConvert(field: RawField): field is RawSelectField {
        return isType(field, RawSelectFieldC);
    }

    protected convertPartially(field: RawSelectField): SelectFieldPartial {
        return toSelectFormat(field);
    }
}
