import { RouterListener } from './RouterListener';
import { sidebarModule } from '../../../../vue/store';

export default class SidebarClosingListener implements RouterListener {
    public onBeforeEach(): boolean {
        if (sidebarModule.hasSidebar) {
            sidebarModule.clearSidebar();
        }

        return true;
    }
}
