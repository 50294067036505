import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import optional from '../io-ts/optional';
import { PaginationDataC } from './Table/PaginationData';
import { FormFieldC, RawFormFieldC } from '../helpers/schemaConverter/converters/types/FormField';

export const NotificationItemC = t.type({
    id: t.string,
    receiverId: t.number,
    senderId: optional(t.union([t.null, t.number])),
    senderName: optional(t.union([t.null, t.string])),
    objectType: t.union([
        t.literal('Sharing'),
        t.literal('Reply'),
        t.literal('Tag'),
    ]),
    message: t.union([t.undefined, t.null, t.string]),
    createdAt: t.string,
    isViewed: t.boolean,
    isCleared: t.boolean,
    commentId: t.union([t.undefined, t.number]),
    recordingId: t.union([t.undefined, t.string]),
    recordingName: t.union([t.undefined, t.string]),
    groupName: t.union([t.undefined, t.null, t.string]),
});

export const NotificationsPopupResponseC = t.type({
    newNotificationsCount: t.number,
    form: RawFormFieldC,
    paginator: t.type({
        paginationData: PaginationDataC,
        items: t.array(NotificationItemC),
    }),
});

export type NotificationItem = t.TypeOf<typeof NotificationItemC>;

export type NotificationsPopupResponse = t.TypeOf<typeof NotificationsPopupResponseC>;

const NotificationFormC = FormFieldC;
export default NotificationFormC;

export type NotificationForm = TypeOf<typeof NotificationFormC>;
