import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import convertForm from './convertForm';
import { assertIsNonNullable } from '../../../../typeAssertions/undefined';
import { defaultSection, RawSectionalForm, SectionalFormPartial } from '../types/SectionalForm';

export default (field: RawSectionalForm): SectionalFormPartial => {
    const convertedForm = convertForm(field);
    assertIsNonNullable(convertedForm);

    return ({
        ...convertedForm,
        renderAs: FIELD_RENDER_TYPES.SECTIONAL_FORM,
        section: defaultSection,
    });
};
