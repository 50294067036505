import room from './room';
import camera from './camera';
import acapCamera from './acapCamera';
import device from './device';
import stream from './stream';
import CameraIcon from '../../../../images/camera.svg?component';
import ROUTE_NAMES from '../../routeNames';
import SECTION_NAMES from '../../sectionNames';
import { permissionsModule as pM } from '../../../store';

export default [
    {
        path: 'camera',
        name: ROUTE_NAMES.ADMIN_CAMERA_LIST,
        component: () => import('../../../views/admin/AdminCamera/AdminCameraList.vue'),
        meta: {
            title: SECTION_NAMES.ROOMS_AND_CAMERAS,
            icon: CameraIcon,
            permissions: (): string[] => [pM.allPermissions.adminRooms],
        },
    },
    ...room,
    ...camera,
    ...acapCamera,
    ...device,
    ...stream,
];
