import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import OneOf from '../../../../io-ts/OneOf';
import optional from '../../../../io-ts/optional';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';

export const RAW_INPUT_TYPES = ['string', 'integer', 'hidden'] as const;
export const INPUT_WIDGETS = ['textarea', 'date', 'password', 'email', 'url'] as const;
const INPUT_RENDER_AS = [
    FIELD_RENDER_TYPES.INPUT,
    FIELD_RENDER_TYPES.SEARCH,
    FIELD_RENDER_TYPES.PIN_CODE,
    FIELD_RENDER_TYPES.COLOR_PICKER,
    FIELD_RENDER_TYPES.INPUT_INTEGER,
    FIELD_RENDER_TYPES.NONE,
    FIELD_RENDER_TYPES.TEXTAREA,
    FIELD_RENDER_TYPES.CALENDAR,
    FIELD_RENDER_TYPES.INPUT_PASSWORD,
] as const;
const INPUT_TYPES = ['password', 'email'] as const;

export const RawInputFieldC = t.type({
    title: t.union([t.string, t.null]),
    name: t.string,
    type: OneOf(RAW_INPUT_TYPES),
    widget: optional(OneOf(INPUT_WIDGETS)),
    default: optional(t.string),
    attr: optional(
        t.partial({
            role: t.literal('search'),
            'data-render-as-pin-code': t.literal(true),
            'data-mobile-placeholder': t.string,
            placeholder: t.union([t.string, t.null, t.number]),
            rows: optional(t.union([t.string, t.number])),
            readonly: optional(t.boolean),
            maxLength: t.union([t.string, t.number, t.null]),
            max: t.union([t.number, t.null]),
            min: t.union([t.number, t.null]),
        }),
    ),
    disabled: optional(t.boolean),
});

const CommonInputFieldPartialC = t.type({
    value: t.string,
    renderAs: OneOf(INPUT_RENDER_AS),
    placeholder: t.union([t.string, t.null, t.number]),
    inputType: optional(OneOf(INPUT_TYPES)),
    rows: optional(t.union([t.number, t.string])),
    disabled: t.boolean,
    readonly: optional(t.boolean),
    maxLength: t.union([t.string, t.number, t.null]),
});

const IntegerInputFieldPartialC = t.intersection([
    CommonInputFieldPartialC,
    t.type({
        renderAs: t.literal(FIELD_RENDER_TYPES.INPUT_INTEGER),
        max: t.union([t.number, t.null]),
        min: t.union([t.number, t.null]),
    }),
]);

export const InputFieldPartialC = t.union([
    IntegerInputFieldPartialC,
    CommonInputFieldPartialC,
]);

export const InputFieldC = t.intersection([InputFieldPartialC, FieldCommonPropsC]);

export type RawInputField = TypeOf<typeof RawInputFieldC>
export type InputFieldPartial = TypeOf<typeof InputFieldPartialC>
export type InputField = TypeOf<typeof InputFieldC>

export default (field: RawInputField): InputFieldPartial => {
    let renderAs = FIELD_RENDER_TYPES.INPUT;
    const isSearch = field.attr?.role === 'search';
    const isPinCode = field.attr?.['data-render-as-pin-code'] || false;

    if (isSearch) renderAs = FIELD_RENDER_TYPES.SEARCH;
    if (isPinCode) renderAs = FIELD_RENDER_TYPES.PIN_CODE;
    if (field.type === 'integer') renderAs = FIELD_RENDER_TYPES.INPUT_INTEGER;
    if (field.type === 'hidden') renderAs = FIELD_RENDER_TYPES.NONE;
    if (field.widget === 'textarea') renderAs = FIELD_RENDER_TYPES.TEXTAREA;
    if (field.widget === 'date') renderAs = FIELD_RENDER_TYPES.CALENDAR;
    if (field.widget === 'password') renderAs = FIELD_RENDER_TYPES.INPUT_PASSWORD;
    if (field.widget === 'url') renderAs = FIELD_RENDER_TYPES.INPUT;

    const placeholder = field.attr?.['data-mobile-placeholder'] || field.attr?.placeholder || null;
    const inputType = (field.widget === 'password' || field.widget === 'email')
        ? field.widget
        : undefined;

    const maxLength = field.attr?.maxLength ?? null;

    const commonData = {
        value: field.default || '',
        renderAs,
        placeholder,
        inputType,
        rows: field.attr?.rows,
        readonly: !!field.attr?.readonly,
        disabled: !!field.disabled,
        maxLength,
        max: field.attr?.max ?? null,
        min: field.attr?.min ?? null,
    };

    if (renderAs === FIELD_RENDER_TYPES.INPUT_INTEGER) {
        return {
            ...commonData,
            renderAs: FIELD_RENDER_TYPES.INPUT_INTEGER,
            max: field.attr?.max ?? null,
            min: field.attr?.min ?? null,
        };
    }

    return commonData;
};
